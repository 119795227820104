/* eslint-disable import/prefer-default-export */
import {
  MainApps,
  TestCalculator,
  TestMath,
  TestReading,
  TestReasoning,
  TestWriting,
} from '@front/icon';
import { isNumber } from '@front/ui';
import { ClubCategoryType } from '@lib/web/apis';

import { isValidUrl } from './url';

// Keep the sub name short to fit the logo width
const SUB_NAME_MAX_LEN = 8;

// TODO: replace displayName with club's slug
export const getClubAppIcon = (displayName: string) => {
  switch (displayName) {
    case 'ACT Math':
    case 'CBSE 10 Math Section A':
    case 'CBSE 10 Math Section B':
    case 'CBSE 10 Math Section C':
    case 'CBSE 10 Math Section D':
    case 'CBSE 12 Math Section A':
    case 'CBSE 12 Math Section B':
    case 'CBSE 12 Math Section C':
    case 'CBSE 12 Math Section D':
    case 'ISEE Mathematics Achievement':
    case 'JEE Math':
    case 'SAT Math no Calc':
    case 'PSAT Math no Calculator':
      return TestMath;
    case 'CBSE 10 Reading':
    case 'CBSE 12 Reading':
    case 'PSAT Reading':
    case 'SAT Reading':
      return TestReading;
    case 'CBSE 10 Writing':
    case 'SAT Writing':
      return TestWriting;
    case 'PSAT Math Calculator':
    case 'SAT Math Calculator':
    case 'SAT Math no Calculator':
      return TestCalculator;
    case 'ISEE Quantitative Reasoning':
    case 'SSAT Upper Quantitative':
      return TestReasoning;
    default:
      return MainApps;
  }
};
export const getCategoryName = (category: ClubCategoryType) => {
  switch (category) {
    case ClubCategoryType.Education:
      return 'Education';
    default:
      return 'Unknown';
  }
};

const getFontSize = (size: number) => {
  if (size <= 3) return 48;
  if (size === 4) return 44;
  if (size === 5) return 40;
  return 30;
};

const getBgolor = (clubName: string) => {
  if (clubName.includes('ACT-')) return '#00D1FF';
  if (clubName.includes('CBSE-10')) return '#0072DD';
  if (clubName.includes('CBSE-12')) return '#00C398';
  if (clubName.includes('GRE-')) return '#9747FF';
  if (clubName.includes('IELTS-')) return '#6113E0';
  if (clubName.includes('ISEE-')) return '#009E2F';
  if (clubName.includes('JEE-')) return '#E62937';
  if (clubName.includes('NEET-')) return '#FFCB00';
  if (clubName.includes('PSAT-')) return '#FF4E8E';
  if (clubName.includes('SAT-')) return '#FF7401';
  if (clubName.includes('TOEFL-')) return '#00D1FF';
  if (clubName.includes('TOEIC-')) return '#0072DD';
  if (clubName.includes('AP-')) return '#A32857';
  return '';
};

export const getClubMainColor = (path: string) => {
  if (!isValidUrl(path)) {
    return '#181818';
  }

  const urlParams = new URLSearchParams(path);

  return urlParams.get('bgcolor') || '#181818';
};

export const getClubIconPath = (displayName: string, disabled = false) => {
  const nameArr = displayName.split(' ');

  let examName = '';
  let subName = '';

  nameArr.forEach((name, i) => {
    // exam name
    if (i === 0) examName = name.toUpperCase();
    // grade
    else if (isNumber(name)) subName += name;
    else if (/^[0-9]+(st|nd|rd|th)/i.test(name))
      subName += name.replace(/[^\d.-]/g, '');
    // special case
    else if (name === 'No') subName += 'n';
    else if (name === 'Section') subName += '.';
    // If line 2 is a single word (single letter acronym), select the first 3 characters of the word.
    else if (nameArr.length === 2) subName += name.slice(0, 3).toUpperCase();
    else if (/^[a-zA-Z]+$/i.test(name))
      subName += name.slice(0, 1).toUpperCase();

    // 最後剩下的是特殊符號，不處理
  });

  subName = subName.substring(0, SUB_NAME_MAX_LEN);

  const maxTextLength = Math.max(examName.length, subName.length);
  const clubName = `${examName}-${subName}`;
  const fontSize = getFontSize(maxTextLength);
  const bgColor = disabled ? '#808080' : getBgolor(clubName);

  return `${
    process.env.APP_URL
  }/api/image/club/${clubName}?size=160&fontSize=${fontSize}&bgcolor=${encodeURIComponent(
    bgColor
  )}`;
};
