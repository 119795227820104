import React from 'react';
import { useCurrentQuestion } from '@lib/web/editor';

import Unscramble from './components/Unscramble';
import { useData } from './hooks/useData';
import { useEventHandle } from './hooks/useEventHandle';

export default function EditorAnswerUnscramble() {
  const { questionId } = useCurrentQuestion();
  const { unscrambleValues, enableAddRemoveOptions, answerComponents } =
    useData(questionId);
  const {
    handleBlocksChange,
    handleDragOverOtherItem,
    handleAddNewChoice,
    handleRemoveChoice,
  } = useEventHandle(questionId);

  return (
    <Unscramble
      enableAddRemoveOptions={enableAddRemoveOptions}
      unscrambleValues={unscrambleValues}
      onBlocksChange={(id, blocks) => {
        const component = answerComponents.find((c) => c.id === id);
        if (component) {
          void handleBlocksChange(component, blocks);
        }
      }}
      onDragOverOtherItem={handleDragOverOtherItem}
      onAddNewChoice={handleAddNewChoice}
      onRemoveChoice={handleRemoveChoice}
    />
  );
}
