import { forwardRef } from 'react';
import { alpha, Box, Theme } from '@mui/material';

export type NumberInputProps = {
  placeholder?: string;
  inputRef?: React.Ref<HTMLInputElement>;
} & React.InputHTMLAttributes<HTMLInputElement>;

const styles = {
  input: {
    typography: 'body2',
    fontSize: 30,
    lineHeight: '39px',
    minWidth: 51,
    textAlign: 'center',
    p: 0,
    border: 'none',
    outline: 'none',
    color: 'text.primary',
    bgcolor: 'transparent',
    borderBottom: '1px solid',
    borderColor: 'transparent',
    '&:focus': {
      borderColor: 'text.primary',
    },
    '&:disabled': {
      opacity: 0.5,
    },
    '&:not(:disabled):hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    },
  },
};
const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  ({ placeholder, inputRef: inputRefProp, ...rest }, ref) => {
    return (
      <Box
        ref={ref}
        sx={styles.input}
        type="text"
        component="input"
        placeholder={placeholder}
        inputMode="numeric"
        size={2}
        {...rest}
      />
    );
  }
);

NumberInput.displayName = 'NumberInput';
export default NumberInput;
