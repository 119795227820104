import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Icon } from '@front/ui';

export type BubbleStatsTooltipProps = {
  icon: string;
  title: string;
  content: string;
};

const styles = {
  root: {
    p: 1,
    typography: 'caption',
    bgcolor: 'text.primary',
    color: 'background.darker',
    borderRadius: 1,
    maxWidth: 200,
    boxShadow: 9,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  title: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 0.5,
  },
  icon: {
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
};

export default function BubbleStatsTooltip({
  icon,
  title,
  content,
}: BubbleStatsTooltipProps) {
  return (
    <Box sx={styles.root}>
      <Typography variant="subtitle2" fontWeight={500} sx={styles.title}>
        {icon && (
          <Box sx={styles.icon}>
            <Icon name={icon} />
          </Box>
        )}
        {title}
      </Typography>
      <Typography variant="caption" display="block">
        {content}
      </Typography>
    </Box>
  );
}
