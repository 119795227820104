import {
  composerHeadingBlockStyle,
  generatedHtmlHeadingBlockStyle,
} from '@lib/web/composer/TextComposer/components/blocks/HeadingBlock';
import {
  composerParagraphBlockStyle,
  generatedHtmlParagraphBlockStyle,
} from '@lib/web/composer/TextComposer/components/blocks/ParagraphBlock';
import {
  composerStepBlockStyle,
  generatedHtmlStepBlockStyle,
} from '@lib/web/composer/TextComposer/components/blocks/StepBlock';
import {
  composerSubtitleBlockStyle,
  generatedHtmlSubtitleBlockStyle,
} from '@lib/web/composer/TextComposer/components/blocks/SubtitleBlock';
import { generatedHtmlInlineHighlightAnchorStyle } from '@lib/web/editor/EditorTextComposer/components/blocks/InlineAnchor/InlineHighlightAnchor';
import { generatedHtmlInlineHighlightStyle } from '@lib/web/editor/EditorTextComposer/components/blocks/InlineHighlight';
import { generatedHtmlFileBlockStyle } from '@lib/web/thread/ThreadTextComposer/components/blocks/FileBlock';
import { generatedHtmlImageBlockStyle } from '@lib/web/thread/ThreadTextComposer/components/blocks/ImageBlock';

export const editorThreadBlockEditingStyles = [
  composerHeadingBlockStyle,
  composerSubtitleBlockStyle('chatBody'),
  composerParagraphBlockStyle('chatBody'),
  composerStepBlockStyle('chatBody'),
];

export const editorThreadBlockHtmlStyles = [
  generatedHtmlHeadingBlockStyle,
  generatedHtmlSubtitleBlockStyle({
    typography: 'chatBody',
    oneLineTypography: 'chatBody',
  }),
  generatedHtmlParagraphBlockStyle({
    typography: 'chatBody',
    oneLineTypography: 'chatBody',
  }),
  generatedHtmlStepBlockStyle({
    typography: 'chatBody',
    oneLineTypography: 'chatBody',
  }),
  generatedHtmlImageBlockStyle(),
  generatedHtmlFileBlockStyle(),
  generatedHtmlInlineHighlightAnchorStyle(),
  generatedHtmlInlineHighlightStyle(),
];
