const leagueNotifications: GetNotificationRes[] = [
  {
    id: '108101a3-7a56-434b-bda8-3cb141297cc1',
    tag: 'league.enter.promotion.zone.club',
    title:
      'Congratulations! You’re in the Alpha League promotion zone. Keep up the good work by practicing 💪',
    body: 'Congratulations! You’re in the Alpha League promotion zone. Keep up the good work by practicing 💪',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        body: {
          leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          leagueName: 'Alpha',
          rank: 2,
          clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
          clubSlug: 'act-math',
          clubIcon: '',
          clubName: 'ACT Math',
        },
        cta: [
          {
            type: 'cta.league.rank',
            rank: 2,
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            zone: 1,
          },
          {
            type: 'cta.league.current',
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            iconPath: null,
          },
        ],
      },
    },
    scheduledAt: '2024-01-09T02:03:07.351Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
  {
    id: '0b8ed77f-55c1-4dca-af2c-c96740c7715f',
    tag: 'league.round.finish.stay.tier.club',
    title:
      'You achieved Rank 14 and kept your position in Alpha League. Keep practicing to get promoted! 💪',
    body: 'You achieved Rank 14 and kept your position in Alpha League. Keep practicing to get promoted! 💪',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        body: {
          prevLeagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          prevLeagueName: 'Alpha',
          leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          leagueName: 'Alpha',
          rank: 14,
          clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
          clubSlug: 'act-math',
          clubIcon: '',
          clubName: 'ACT Math',
        },
        cta: [
          {
            type: 'cta.league.final.rank',
            rank: 14,
            prevLeagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            prevLeagueName: 'Alpha',
            clubSlug: 'act-math',
            zone: 2,
          },
          {
            type: 'cta.league.current',
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            iconPath: null,
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
];

const challengeNotification: GetNotificationRes[] = [
  {
    id: 'dc824b4c-aa52-461d-bab1-96febb6c5b4a',
    tag: 'challenge.ending.scheduler.winner.club',
    title:
      "mitch.wu+test-editor's ACT Math Challenge #1 has ended. The final result is out, you are the final winner!🏆",
    body: "<a href='/club/act-math/challenge/37e3c785-0a15-4478-862f-bdef8d7bb606'><img class='avatar-challenge' src='' />mitch.wu+test-editor's ACT Math Challenge #1</a> has ended. The final result is out, you are the final winner!🏆",
    data: {
      icon: 'challenge',
      isBot: true,
      payload: {
        body: {
          challengeId: 'd7d6e9a3-d72c-4f80-98e5-9ea5e667ed61',
          challengeName: "mitch.wu+test-editor's ACT Math Challenge #1",
          quizId: '37e3c785-0a15-4478-862f-bdef8d7bb606',
          clubSlug: 'act-math',
        },
        cta: [
          {
            type: 'cta.challenge.ranking',
            rank: 1,
            quizId: '37e3c785-0a15-4478-862f-bdef8d7bb606',
            clubSlug: 'act-math',
            challengeRankLink:
              '/club/act-math/challenge/37e3c785-0a15-4478-862f-bdef8d7bb606?panel=playlist-challengers',
          },
        ],
      },
    },
    scheduledAt: '2023-11-21T00:48:48.406Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },

  // in dev environment
  {
    id: '66b312bc-5063-4431-a344-42c6c64e38ce',
    tag: 'challenge.good.result.member.club',
    title:
      "Impressive score on Mitch Wu Editor 2aa's Playlist #16! Feeling competitive? Challenge another competitor.",
    body: "Impressive score on <a href='/club/m2/playlist/441eb8b3-9a81-49c6-9af6-97f7049ffdf5'>Mitch Wu Editor 2aa's Playlist #16</a>! Feeling competitive? Challenge another competitor.",
    data: {
      icon: 'challenge',
      isBot: true,
      payload: {
        body: {
          clubId: '22fbdec1-7f05-4622-a67a-606b11b2b68b',
          clubSlug: 'm2',
          quizId: '441eb8b3-9a81-49c6-9af6-97f7049ffdf5',
          quizName: "Mitch Wu Editor 2aa's Playlist #16",
          quizOwnerAvatar:
            'https://aha-image.s3.ap-northeast-1.amazonaws.com/aha-dev/profile/773a0ae5-9f36-4021-8a73-8505daa47c77/5eb93ec0-5b29-11ef-98e9-838bae61e7ce.png?AWSAccessKeyId=AKIAWXNJ4LEYUVXI7JVF&Expires=1726562425&Signature=k2KWsQ1bEgVStR1bS7SlBeKGdMs%3D',
        },
        cta: [
          {
            type: 'cta.challenge.recommend.list',
            users: [
              {
                userId: '765a04ba-53b6-4813-a04f-0066ddb491a8',
                distinctName: 'mitchwueditor2aa',
                displayName: 'Mitch Wu Editor 2aa',
                avatar:
                  'https://aha-image.s3.ap-northeast-1.amazonaws.com/aha-dev/profile/773a0ae5-9f36-4021-8a73-8505daa47c77/5eb93ec0-5b29-11ef-98e9-838bae61e7ce.png?AWSAccessKeyId=AKIAWXNJ4LEYUVXI7JVF&Expires=1726562425&Signature=k2KWsQ1bEgVStR1bS7SlBeKGdMs%3D',
                clubId: '22fbdec1-7f05-4622-a67a-606b11b2b68b',
                clubSlug: 'm2',
                clubName: 'M2',
                clubIcon:
                  'https://aha-image.s3.ap-northeast-1.amazonaws.com/aha-dev/club/2f32e596-2952-4051-b796-2dcda2fedc5e.png?AWSAccessKeyId=AKIAWXNJ4LEYUVXI7JVF&Expires=1726562425&Signature=o%2Fi9csZ9%2BxqsXYXZ0d0cqd0AO9M%3D',
                leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
                leagueTier: 1,
                leagueName: 'Alpha',
                leagueRank: 1,
              },
              {
                userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
                distinctName: 'kent.chen',
                displayName: 'Kent Chen',
                avatar:
                  'https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c',
                clubId: '22fbdec1-7f05-4622-a67a-606b11b2b68b',
                clubSlug: 'm2',
                clubName: 'M2',
                clubIcon:
                  'https://aha-image.s3.ap-northeast-1.amazonaws.com/aha-dev/club/2f32e596-2952-4051-b796-2dcda2fedc5e.png?AWSAccessKeyId=AKIAWXNJ4LEYUVXI7JVF&Expires=1726562425&Signature=o%2Fi9csZ9%2BxqsXYXZ0d0cqd0AO9M%3D',
                leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
                leagueTier: 1,
                leagueName: 'Alpha',
                leagueRank: 1,
              },
            ],
          },
        ],
      },
    },
    scheduledAt: '2023-09-03T08:34:58.938Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },

  // in dev environment
  {
    id: 'a128ef3f-c7b7-471e-b2e0-abb238994a6f',
    tag: 'challenge.good.result.member.club',
    title:
      "Impressive score on Mitch Wu Editor 2aa's Playlist #2! Feeling competitive? Challenge another competitor.",
    body: "Impressive score on <a href='/club/myunlisted2/playlist/300aaa8c-c4b3-4cbe-a87b-3c19358db027'>Mitch Wu Editor 2aa's Playlist #2</a>! Feeling competitive? Challenge another competitor.",
    data: {
      icon: 'challenge',
      isBot: true,
      payload: {
        body: {
          clubId: '0fb92ff3-33bf-425b-80d0-58af05350192',
          clubSlug: 'myunlisted2',
          quizId: '300aaa8c-c4b3-4cbe-a87b-3c19358db027',
          quizName: "Mitch Wu Editor 2aa's Playlist #2",
          quizOwnerAvatar:
            'https://aha-image.s3.ap-northeast-1.amazonaws.com/aha-dev/profile/773a0ae5-9f36-4021-8a73-8505daa47c77/5eb93ec0-5b29-11ef-98e9-838bae61e7ce.png?AWSAccessKeyId=AKIAWXNJ4LEYUVXI7JVF&Expires=1726563034&Signature=9A8Q4KNP5vTdXTnUbh54gCDN%2BCc%3D',
        },
        cta: [
          {
            type: 'cta.challenge.recommend.list',
            users: [
              {
                userId: '5da0e321-f045-4954-a1d5-2dd9249427fd',
                distinctName: 'hsin.yang',
                displayName: 'Hsin Yang',
                avatar: null,
                clubId: '0927132d-702b-4524-b7a2-6cecbb6ba385',
                clubSlug: 'sat-writing',
                clubName: 'SAT Writing',
                clubIcon: '',
                leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
                leagueTier: 1,
                leagueName: 'Alpha',
                leagueRank: 1,
              },
              {
                userId: 'e32b7956-9b48-4161-bdb3-9d1bf3be7724',
                distinctName: 'kent.chen',
                displayName: 'Kent Chen',
                avatar:
                  'https://lh3.googleusercontent.com/a-/AOh14GisVPdwsZn6r0aijLnksR6igBt4J5QQKq3AgbCi=s96-c',
                clubId: '22fbdec1-7f05-4622-a67a-606b11b2b68b',
                clubSlug: 'm2',
                clubName: 'M2',
                clubIcon:
                  'https://aha-image.s3.ap-northeast-1.amazonaws.com/aha-dev/club/2f32e596-2952-4051-b796-2dcda2fedc5e.png?AWSAccessKeyId=AKIAWXNJ4LEYUVXI7JVF&Expires=1726563034&Signature=sU%2B5ORWe89rKKWxoGamfDWNz6WI%3D',
                leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
                leagueTier: 1,
                leagueName: 'Alpha',
                leagueRank: 1,
              },
              {
                userId: '67d674e0-181b-419e-9dce-e6c36cabc458',
                distinctName: '123123bb',
                displayName: 'mitch.wu+editorBB',
                avatar:
                  'https://aha-image.s3.ap-northeast-1.amazonaws.com/aha-dev/profile/db24d51e-f9e9-4734-bf4b-37a228a1c6ae/cf576e20-e597-11ee-ac8c-31ca96dc1443.png?AWSAccessKeyId=AKIAWXNJ4LEYUVXI7JVF&Expires=1726563034&Signature=9KUoTg6fufaGGJs0Kn7juetLOJw%3D',
                clubId: '279e5290-b448-4e27-805f-e0bb9b92bb33',
                clubSlug: 'all-question-formats',
                clubName: 'All Question formats',
                clubIcon: '',
                leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
                leagueTier: 1,
                leagueName: 'Alpha',
                leagueRank: 1,
              },
            ],
          },
        ],
      },
    },
    scheduledAt: '2023-09-03T08:50:16.334Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
];

const streakNotification: GetNotificationRes[] = [
  {
    id: '91c5a178-eda6-4330-b5ea-fd783b1032a3',
    tag: 'streak.reminder.daily.member.club',
    title: 'Complete at least 1 question to achieve your streak today 🔥',
    body: 'Complete at least 1 question to achieve your streak today 🔥',
    data: {
      icon: 'streak',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
      },
    },
    scheduledAt: '2024-01-11T10:00:26.937Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
  {
    id: '50bc7bb5-3126-4575-bf9c-59dd20905291',
    tag: 'streak.reminder.broken.member.club',
    title: 'Missed your practice yesterday? Streak resets to 0 🥶',
    body: 'Missed your practice yesterday? Streak resets to 0 🥶',
    data: {
      icon: 'streak',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
        cta: [
          {
            type: 'cta.streak.reminder',
            streakCount: 1,
            streakDateStart: null,
            streakDateEnd: '2024-01-09T23:59:59.999Z',
          },
        ],
      },
    },
    scheduledAt: '2024-01-10T01:00:30.265Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
  {
    id: 'c083849b-7714-4e14-a6b8-02db0336efc3',
    tag: 'streak.achieved.single.day.member.club',
    title:
      'Streak achieved today! ✅ Keep it up, set records, and own your learning journey! 🚀✨',
    body: 'Streak achieved today! ✅ Keep it up, set records, and own your learning journey! 🚀✨',
    data: {
      icon: 'streak',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
        cta: [
          {
            type: 'cta.streak.achieved',
            streakCount: 1,
            streakDateStart: '2024-01-08T00:00:00.000Z',
            streakDateEnd: '2024-01-08T23:59:59.999Z',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T03:09:16.073Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
  {
    id: '4c60714a-acaf-44a9-ab6d-ab5c266e4e02',
    tag: 'streak.achieved.multiple.day.member.club',
    title:
      'Congratulations on 7 streaks! Keep the streak alive and set new records! 🎉',
    body: 'Congratulations on 7 streaks! Keep the streak alive and set new records! 🎉',
    data: {
      icon: 'streak',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {
          streakCount: 7,
        },
        cta: [
          {
            type: 'cta.streak.reminder',
            streakCount: 7,
            streakDateStart: '2024-01-05T00:00:00.000Z',
            streakDateEnd: '2024-01-12T23:59:59.999Z',
          },
        ],
      },
    },
    scheduledAt: '2024-01-12T07:14:07.779Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
];

const emailNotification: GetNotificationRes[] = [
  {
    id: '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed',
    tag: 'notification.email.verification.reminder.global',
    title: 'You have not verified your email. Please verify your email.',
    body: 'You have not verified your email. Please verify your email.',
    data: {
      icon: 'email',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
        cta: [
          {
            type: 'cta.email.verification.remind',
          },
        ],
      },
    },
    scheduledAt: '2024-01-11T10:00:26.937Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
  {
    id: '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d',
    tag: 'notification.email.verification.success.global',
    title: 'Your email is successfully verified ✅',
    body: 'Your email is successfully verified ✅',
    data: {
      icon: 'email',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
      },
    },
    scheduledAt: '2024-01-10T01:00:30.265Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
];

export const mockNotifications: GetNotificationRes[] = [
  ...emailNotification,
  ...challengeNotification,
  ...leagueNotifications,
  ...streakNotification,
];
