import { useMemo } from 'react';
import { apis, useAuth } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import { useTokenBalanceControl } from '@lib/web/thread/hooks/channel/useTokenBalanceControl';
import { getNewMentionMemberIdsFromBlocks } from '@lib/web/thread/ThreadTextComposer';
import { streamDateToString } from '@lib/web/thread/utils/streamUtils';
import { call } from '@lib/web/utils';
import { uniq } from 'lodash';
import { ChannelData } from 'stream-chat';
import { v4 } from 'uuid';

import { AgentMaterial } from '../../types';
import { useThread } from '../core/useThread';
import { useBuildMessageData } from '../message/useBuildMessageData';

import { useChannelSystemMessage } from './useChannelSystemMessage';

export const useCreateNewChannelWithMessage = () => {
  const { chatClient } = useThread();
  const { member } = useAuth();
  const { maybeSendUpgradePlanMessage } = useTokenBalanceControl();
  const { buildMessageData, buildChannelFirstMessageData } =
    useBuildMessageData();
  const { maybeSendAgentStatusErrorMessage } = useChannelSystemMessage();

  return useMemo(() => {
    return {
      createNewChannelWithMessage: async ({
        message,
        blocks,
        channelData,
        sendPublicly,
        agentId,
        agentMaterials,
      }: {
        message: string;
        blocks: ComposerBlock[];
        channelData: ChannelData;
        sendPublicly: boolean;
        agentId?: string;
        agentMaterials?: AgentMaterial[];
      }) => {
        if (!chatClient || !member) return;

        const channelType = sendPublicly ? 'public' : 'team';
        const newMentionMemberIds = getNewMentionMemberIdsFromBlocks(blocks);
        const newChannelMemberIds = uniq([
          ...(channelData.members || []),
          ...newMentionMemberIds,
          ...(agentId ? [`agent_${agentId}`] : []),
        ]);
        const id = v4();

        try {
          const newChannel = chatClient.channel(channelType, id, {
            ...channelData,
            /**
             * instead of using channelData.members, we only include self member id
             * because some user may not be created yet, so we omit them, leave the invite task for backend
             */
            members: [member.memberId],
          });

          await newChannel.create();

          /**
           * we must follow this order :
           * 1. invite member
           * 2. send first message
           * 3. send system message
           *
           * to ensure
           * 1. the first message is 'unread' for invited members
           * 2. the system message is followed by the first message
           */
          const [invitedMemberResult] = await call(
            apis.thread.inviteMember({
              channelType,
              channelId: id,
              memberIds: newChannelMemberIds,
            })
          );

          const newMessageResult = await newChannel.sendMessage(
            buildMessageData({
              text: message,
              blocks,
              agentMaterials,
            })
          );

          void newChannel.updatePartial({
            set: {
              ...buildChannelFirstMessageData({
                text: message,
                blocks,
                agentMaterials,
              }),
              firstMessageCreatedById: member.memberId,
              firstMessageCreatedAt: streamDateToString(
                newMessageResult.message.created_at
              ),
            },
          });

          await call(
            apis.thread.systemMessageMemberInvited({
              channelType,
              channelId: id,
              memberIds: newChannelMemberIds,
            })
          );

          maybeSendAgentStatusErrorMessage(newChannel);

          await newChannel.watch();
          void newChannel.markRead();

          void maybeSendUpgradePlanMessage(newChannel);

          return {
            newChannel,
            newChannelMemberIds,
            viewAfterId: invitedMemberResult?.data.afterInvitedDmViewId || null,
          };
        } catch (e) {
          console.error('createNewChannelWithMessage failed', e);
        }
      },
    };
  }, [
    buildChannelFirstMessageData,
    buildMessageData,
    chatClient,
    maybeSendAgentStatusErrorMessage,
    maybeSendUpgradePlanMessage,
    member,
  ]);
};
