import { Box, BoxProps, Typography } from '@mui/material';
import {
  EditorDifficultyEasy as EditorDifficultyEasyIcon,
  EditorDifficultyHard as EditorDifficultyHardIcon,
  EditorDifficultyMedium as EditorDifficultyMediumIcon,
  EditorDifficultySuper as EditorDifficultySuperIcon,
} from '@front/icon';
import { animated, useSpring } from '@react-spring/web';

import { BubbleChartItemNode } from './types';

// from Figma
const FONT_RATE = 0.15; // 18 / 160

const styles = {
  bubble: {
    position: 'absolute',
    display: 'grid',
    gridColumnTemplates: 'repeat(2, 1fr)',
    gap: '2%',
    userSelect: 'none',
    '&:hover .bubble-background': {
      borderColor: 'text.primary',
      borderWidth: '2px',
    },
  },
  display: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    minWidth: 0,
    '& .MuiTypography-root': {
      textAlign: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    svg: {
      verticalAlign: 'middle',
    },
  },
  value: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  difficultyBubble: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    border: '4px solid',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: -1,
  },
  layout: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '50%',
    zIndex: -1,
    border: '0px solid transparent',
    overflow: 'hidden',
    '& svg': {
      width: '90%',
      height: '90%',
      position: 'absolute',
      top: '50%',
      left: '30%',
      transform: 'translateY(-50%)',
      opacity: 0.4,
    },
  },
  selected: {
    borderWidth: '4px',
    borderColor: 'text.primary',
  },
};

const getIcon = (level: string) => {
  if (level === 'easy') return EditorDifficultyEasyIcon;
  if (level === 'medium') return EditorDifficultyMediumIcon;
  if (level === 'hard') return EditorDifficultyHardIcon;

  return EditorDifficultySuperIcon;
};

const AnimatedBox = animated(Box);

export default function BubbleChartItem({
  data,
  colors,
  borderColor,
  selected,
  opacity,
  centerX,
  centerY,
  onClick,
  onMouseMove,
  onMouseLeave,
}: {
  data: BubbleChartItemNode;
  colors: [string, string];
  borderColor: string;
  selected: boolean;
  opacity?: number;
  centerX: number;
  centerY: number;
  onClick: BoxProps['onClick'];
  onMouseMove: BoxProps['onMouseMove'];
  onMouseLeave: BoxProps['onMouseLeave'];
}) {
  const size = data.r * 2;
  const BubbleIcon = data.data.isDifficultyBubble
    ? getIcon(data.data.code)
    : undefined;

  const props = useSpring({
    from: { opacity: 0, y: centerY, x: centerX },
    opacity: opacity ?? 1,
    x: data.x - data.r,
    y: data.y - data.r,
  });

  const fontSize = size * FONT_RATE;
  return (
    <AnimatedBox
      style={props}
      sx={[styles.bubble, { opacity, width: size, height: size }]}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <Box sx={[styles.layout, { opacity: data.data.size / 5 }]}>
        <Box
          sx={[
            styles.background,
            selected && styles.selected,
            {
              background: `linear-gradient(90deg, ${colors[0]} 0.01%, ${colors[1]} 99.99%)`,
            },
          ]}
          className="bubble-background"
        >
          {!!BubbleIcon && <BubbleIcon />}
        </Box>
        {data.data.isDifficultyBubble && (
          <Box
            sx={[
              styles.difficultyBubble,
              {
                borderColor,
              },
            ]}
            width={size + 4 + 8}
            height={size + 4 + 8}
          />
        )}
      </Box>
      <Box sx={[styles.display, { px: `${fontSize / 2}px` }]}>
        <Typography fontSize={fontSize} fontWeight={700}>
          {!!BubbleIcon && (
            <BubbleIcon width={size * FONT_RATE} height={size * FONT_RATE} />
          )}
          {data.data.display}
        </Typography>
      </Box>
      <Box sx={styles.value}>
        <Typography
          fontSize={size * FONT_RATE * 2}
          fontWeight={700}
          lineHeight={1}
        >
          {data.data.value.toFixed(0)}%
        </Typography>
      </Box>
    </AnimatedBox>
  );
}
