import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Box } from '@mui/material';
import useClaimSignUpUrl from '@app/web/src/hooks/utils/useClaimSignUpUrl';
import useVerifyEmailButton from '@app/web/src/widgets/AccountPage/hooks/useVerifyEmailButton';
import { timeFormat } from '@front/helper';
import {
  NFTEmojiAlien as NFTEmojiAlienIcon,
  OtherResend as OtherResendIcon,
  ProfileLogin as ProfileLoginIcon,
} from '@front/icon';
import { Button, EmphasizeButton, PopupActions, PopupOverlay } from '@front/ui';
import { useAuth } from '@lib/web/apis';

import useBlockerOffset from '../hooks/useBlockerOffset';

const styles = {
  modal: (top: number, left: number) => ({
    zIndex: 1,
    top: {
      xs: top,
      md: `calc(${top}px + var(--announcement-bar-height))`,
    },
    left,
    '& .MuiBackdrop-root': {
      top: {
        xs: top,
        md: `calc(${top}px + var(--announcement-bar-height))`,
      },
      left,
    },
  }),
};

function GuestBlocker() {
  const { t } = useTranslation('profile');
  const signUpUrl = useClaimSignUpUrl();
  const { top, left } = useBlockerOffset();
  return (
    <PopupOverlay
      title={t('feature.blocker.title')}
      body={t('feature.blocker.content')}
      modalProps={{
        disablePortal: true,
        sx: styles.modal(top, left),
      }}
      open
      emojiComponent={<NFTEmojiAlienIcon />}
      actionComponent={
        <PopupActions>
          <EmphasizeButton
            prefixIcon={<ProfileLoginIcon />}
            component={Link}
            href={signUpUrl}
          >
            {t('feature.blocker.cta')}
          </EmphasizeButton>
        </PopupActions>
      }
    />
  );
}

function UnverifiedBlocker() {
  const { t } = useTranslation('profile');
  const { member } = useAuth();
  const { seconds, sent, verifyEmail } = useVerifyEmailButton({
    countdownDelay: 2000,
  });
  const { top, left } = useBlockerOffset();

  return (
    <PopupOverlay
      title={t('verify.blocker.title')}
      body={t('verify.blocker.content', {
        email: member?.email || '',
      })}
      modalProps={{
        disablePortal: true,
        sx: styles.modal(top, left),
      }}
      open
      emojiComponent={<NFTEmojiAlienIcon />}
      actionComponent={
        <Box>
          <PopupActions>
            <Button
              onClick={verifyEmail}
              disabled={sent}
              color={sent && !seconds ? 'success' : undefined}
              prefixIcon={sent ? undefined : <OtherResendIcon />}
            >
              {seconds
                ? timeFormat(seconds)
                : sent
                ? t('verify.blocker.cta.sent')
                : t('verify.blocker.cta.text')}
            </Button>
          </PopupActions>
        </Box>
      }
    />
  );
}
export default function SignupRequiredBlocker() {
  const { isGuest } = useAuth();
  if (isGuest) return <GuestBlocker />;
  return <UnverifiedBlocker />;
}
