import { useState } from 'react';
import { Box, Popover, PopoverProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BaseSnackbar, Button } from '@front/ui';

import { popup } from './popup';
import { usePopup } from './store';

const styles = {
  actions: {
    display: 'flex',
    gap: 2,
    flex: 1,

    '& .MuiButtonBase-root': {
      whiteSpace: 'nowrap',
      minWidth: 'unset',
      px: 0,
      flex: 1,
      maxWidth: 155,
    },
  },
};
export default function ConfirmPopup() {
  const [loading, setLoading] = useState(false);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { settings, open } = usePopup();
  const handleCancel = (cancelType: 'button' | 'close') => {
    popup.cancel();
    settings.onCancel?.(cancelType);
  };
  const handleConfirm = async () => {
    setLoading(true);
    await settings.onConfirm?.();
    setLoading(false);
    popup.confirm();
  };

  const toastContent = (
    <BaseSnackbar
      onClose={() => handleCancel('close')}
      title={settings.title}
      message={settings.content}
      type={settings.popupType}
      actionComponent={
        <Box sx={styles.actions}>
          {settings.cancelText && (
            <Button onClick={() => handleCancel('button')} variant="outlined">
              {settings.cancelText}
            </Button>
          )}
          <Button onClick={handleConfirm} loading={loading}>
            {settings.confirmText || 'Confirm'}
          </Button>
        </Box>
      }
    />
  );

  if (!settings.anchorEl) return null;

  if (mdUp) {
    const popupProps = {
      anchorEl: settings.anchorEl,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
      } as PopoverProps['anchorOrigin'],
      transformOrigin: {
        vertical: 'top' as const,
        horizontal: 'left' as const,
      } as PopoverProps['transformOrigin'],
    };
    if (settings.anchorType === 'button') {
      const { height } = settings.anchorEl.getBoundingClientRect();

      popupProps.anchorOrigin = {
        vertical: height + 8, // 16 is the padding of the button
        horizontal: -36,
      };
      popupProps.transformOrigin = {
        vertical: 'bottom' as const,
        horizontal: 'left' as const,
      };
    }
    return (
      <Popover
        open={open}
        {...popupProps}
        onClose={() => handleCancel('close')}
        {...settings.popupOptions}
      >
        {toastContent}
      </Popover>
    );
  }

  return (
    <Popover
      open={open}
      slotProps={{ paper: { sx: { width: '100%' } } }}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: document?.body?.offsetHeight,
        left: 0,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={() => handleCancel('close')}
    >
      {toastContent}
    </Popover>
  );
}
