import {
  useCreatorQuestionCorrectAnswerData,
  useCreatorQuestionDetailData,
  useCurrentQuestion,
} from '@lib/web/editor';

import { MrqSolutionValue } from '../types';

export const useData = () => {
  const { questionId } = useCurrentQuestion();
  const { answerSolutionComponents } = useCreatorQuestionDetailData(questionId);

  const { correctAnswerIdSet } =
    useCreatorQuestionCorrectAnswerData(questionId);

  const solutionValues: MrqSolutionValue[] = answerSolutionComponents.map(
    (answerSolutionComponent) => ({
      id: answerSolutionComponent.id,
      blocks: answerSolutionComponent.editorBlocks,
      selected: correctAnswerIdSet.has(
        answerSolutionComponent.linkedComponentId
      ),
    })
  );

  return {
    solutionValues,
    answerSolutionComponents,
  };
};
