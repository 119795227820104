import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';

import { generateInfiniteHook, SwrHelperParams } from '../query';

export function useIaDataSourceQuestions(
  query: IaSearchReq<undefined> = {},
  { enable, ...restConfig }: SwrHelperParams = {}
) {
  const limit = query.limit || 0;

  return generateInfiniteHook<GetQuizDataSourceQuestionRes>(
    (index) =>
      enable === false
        ? null
        : `v2/ia/datasource/questions?${getIaQueryString({
            ...query,
            page: index + 1,
            limit,
          })}`,
    limit,
    {
      auth: true,
      ...restConfig,
    }
  );
}
