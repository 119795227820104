const isProd =
  process.env.ENV_CODE === 'prod' || process.env.ENV_CODE === 'gamma';

const isDev = !isProd;

export const STREAM_MAX_CHANNELS_PER_REQUEST = 30; // defined in stream doc

/**
 * XXX: move this featureFlags config to somewhere we're easier to config
 * such as s3 or sanity
 * and at the same time, consider the query performance
 */

type EnvEnable = {
  prod: boolean;
  gamma: boolean;
  beta: boolean;
  dev: boolean;
};

const featureFlags: { [key: string]: EnvEnable } = {
  threadInsertAi: {
    prod: true,
    gamma: true,
    beta: true,
    dev: true,
  },
  addThreadView: {
    prod: true,
    gamma: true,
    beta: true,
    dev: true,
  },
  openChildThread: {
    prod: true,
    gamma: true,
    beta: true,
    dev: true,
  },
  tableLayoutSettings: {
    prod: false,
    gamma: false,
    beta: false,
    dev: false,
  },
  solutionIsChat: {
    prod: false,
    gamma: false,
    beta: true,
    dev: true,
  },
};

const featureEnable = {
  threadInsertAi:
    featureFlags.threadInsertAi[process.env.ENV_CODE as keyof EnvEnable] ||
    false,
  addThreadView:
    featureFlags.addThreadView[process.env.ENV_CODE as keyof EnvEnable] ||
    false,
  openChildThread:
    featureFlags.openChildThread[process.env.ENV_CODE as keyof EnvEnable] ||
    false,
  tableLayoutSettings:
    featureFlags.tableLayoutSettings[process.env.ENV_CODE as keyof EnvEnable] ||
    false,
  solutionIsChat:
    featureFlags.solutionIsChat[process.env.ENV_CODE as keyof EnvEnable] ||
    false,
};

export { featureEnable, isDev, isProd };
