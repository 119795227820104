import React, { MouseEvent } from 'react';
import Box from '@mui/material/Box';
import {
  OtherSend as OtherSendIcon,
  OtherSendActive as OtherSendActiveIcon,
} from '@front/icon';
import { IconButton, LoadingIcon } from '@front/ui';
import { useSendMessageDisabled } from '@lib/web/thread/ThreadComposer/hooks/useSendMessageDisabled';

import { AiActionButton, AiActionContent } from './AiAction/AiAction';
import {
  AddElementActionButton,
  AddElementActionContent,
} from './AddElementAction';
import { LatexActionComposer } from './LatexActionComposer';
import {
  MathKeyboardActionButton,
  MathKeyboardActionContent,
} from './MathKeyboardAction';

const styles = {
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    width: '100%',
    px: 1.5,
    pb: 2,
  },
  sendButton: {
    ml: 'auto',
  },
};

export type SupportedActions =
  | 'ai'
  | 'addElement'
  | 'latex'
  | 'mathKeyboard'
  | undefined;

export type ActionsProps = {
  onSendButtonClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
  submitting?: boolean;
  disabled?: boolean;
};

export default function Actions({
  onSendButtonClick,
  submitting,
  disabled,
}: ActionsProps) {
  const sendMessageDisabled = useSendMessageDisabled();

  return (
    <>
      <Box sx={styles.actions}>
        <AiActionButton disabled={disabled} />
        <AddElementActionButton disabled={disabled} />
        <MathKeyboardActionButton disabled={disabled} />
        {/*<IconButton customSize={24}>*/}
        {/*  <TestEmojiIcon />*/}
        {/*</IconButton>*/}
        <Box sx={styles.sendButton}>
          {submitting ? (
            <IconButton customSize={24}>
              <LoadingIcon />
            </IconButton>
          ) : !sendMessageDisabled ? (
            <IconButton customSize={24} onClick={onSendButtonClick}>
              <OtherSendActiveIcon />
            </IconButton>
          ) : (
            <IconButton customSize={24} disabled>
              <OtherSendIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <AiActionContent />
      <AddElementActionContent />
      <LatexActionComposer />
      <MathKeyboardActionContent />
    </>
  );
}
