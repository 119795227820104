import { useCallback, useContext } from 'react';
import { useCollectUniqueKeys } from '@front/helper';
import { UploadResourceType, useSignedUrlMultiple } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import { ThreadComposerCustomContext } from '@lib/web/thread/contexts/threadComposerCustomContext';
import { escape } from 'lodash';

/**
 * This hook is used for generating HTML to render thread messages in our application.
 * Because some blocks contain unsigned URLs, we need to sign them before rendering.
 * In some cases, we don't need this and can simply use composerBlocksToHtml,
 * such as when providing GPT message.
 */
export const useRenderThreadMessageToHtml = () => {
  const { composerBlocksToHtml, contentEnricher } = useContext(
    ThreadComposerCustomContext
  );
  const [imgKeys, collectImgKeys] = useCollectUniqueKeys<string>();
  const signedUrls = useSignedUrlMultiple({
    keys: imgKeys,
    resourceType: UploadResourceType.AhaImages,
  });

  const renderThreadMessageToHtml = useCallback(
    (text?: string, composerBlocks?: ComposerBlock[]): string => {
      /**
       * we always use composerBlocks (which is the original data structure of composer) to render message content,
       * but also make 'text' as the fallback value
       */
      let composerBlocksHtml = composerBlocks
        ? composerBlocksToHtml(composerBlocks)
        : '';
      composerBlocksHtml = contentEnricher
        ? contentEnricher(composerBlocksHtml)
        : composerBlocksHtml;

      const html = composerBlocksHtml || escape(text) || '';

      [...html.matchAll(/<img[^>]+key="([^">]+)"/g)].forEach((match) => {
        collectImgKeys(match[1]);
      });

      return html.replace(
        /(<img[^>]+key=")([^">]+)("[^>]+src=")([^">]+)(")/g,
        (match, p1, key, p3, src, p5) => {
          return signedUrls?.[key]
            ? p1 + key + p3 + signedUrls[key] + p5
            : match;
        }
      );
    },
    [collectImgKeys, composerBlocksToHtml, contentEnricher, signedUrls]
  );
  return {
    renderThreadMessageToHtml,
  };
};
