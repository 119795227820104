import { Box, Typography } from '@mui/material';
import {
  OtherCheckedSquareSolid as OtherCheckedSquareSolidIcon,
  OtherCheckSquareDash as OtherCheckSquareDashIcon,
  OtherCheckSquareOutline as OtherCheckSquareOutlineIcon,
} from '@front/icon';
import { Icon } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
type IaTableHeadCheckboxProps = {
  icon: string;
  text: string;
  state: 'checked' | 'partial' | 'unchecked';
  value?: string;
};
const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 32px',
    height: 32,
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 0.5,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
export default function IaTableHeadCheckbox({
  icon,
  text,
  state,
  value,
}: IaTableHeadCheckboxProps) {
  const checked = state === 'checked';
  const partial = state === 'partial';
  const unchecked = state === 'unchecked';
  const { getIaAction } = useIaAction();

  const handleSelect = () => {
    if (value) getIaAction(value)?.action?.();
  };
  return (
    <Box sx={styles.root}>
      <Box sx={styles.text}>
        <Icon name={icon} width={16} height={16} />
        <Typography variant="body2">{text}</Typography>
      </Box>
      <Box sx={styles.icon} onClick={handleSelect}>
        {checked && <OtherCheckedSquareSolidIcon width={16} height={16} />}
        {partial && <OtherCheckSquareDashIcon width={16} height={16} />}
        {unchecked && <OtherCheckSquareOutlineIcon width={16} height={16} />}
      </Box>
    </Box>
  );
}
