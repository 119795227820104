import { useCallback, useContext } from 'react';
import { useAuth } from '@lib/web/apis';
import { ThreadComposerCustomContext } from '@lib/web/thread/contexts/threadComposerCustomContext';
import { useBuildMessageData } from '@lib/web/thread/hooks/message/useBuildMessageData';
import { useSendMessageToChannel } from '@lib/web/thread/hooks/messages/useSendMessageToChannel';
import { useMaybeRedirectUserToNewDmView } from '@lib/web/thread/hooks/view/useMaybeRedirectUserToNewDmView';
import ThreadComposer, {
  ThreadComposerProps,
} from '@lib/web/thread/ThreadComposer';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';
import { v4 } from 'uuid';

import { useChannelSystemMessage } from '../../hooks/channel/useChannelSystemMessage';
import { useThread } from '../../hooks/core/useThread';
import { useThreadComposer } from '../../hooks/core/useThreadComposer';

export type ThreadMessageInputProps = {
  channel: Channel;
  onBeforeSubmit?: (message: StreamMessage<StreamChatGenerics>) => void;
};

export default function ThreadMessageInput({
  channel,
  onBeforeSubmit,
}: ThreadMessageInputProps) {
  const { member } = useAuth();
  const myMemberId = member?.memberId;
  const { buildMessageData } = useBuildMessageData();
  const { sendMessageToChannel } = useSendMessageToChannel();
  const { maybeRedirectUserToNewDmView } = useMaybeRedirectUserToNewDmView();

  const { threadMemberIds, setText, aiActionState } = useThreadComposer();
  const { getThreadComposerCustomProps } = useContext(
    ThreadComposerCustomContext
  );

  const { resetThreadMessageSortFilter } = useThread();
  const { isBlocking, blockerTip } = useAuth();
  const { maybeSendAgentStatusErrorMessage } = useChannelSystemMessage();

  const handleComposerSubmit = useCallback<
    Exclude<ThreadComposerProps['onSubmit'], undefined>
  >(
    async (params) => {
      setText('');

      const customId = v4();
      onBeforeSubmit?.({
        id: customId,
        customId,
        type: 'regular',
        user: { id: myMemberId || '' },
        created_at: new Date().toISOString(),
        ...buildMessageData({
          text: params.text,
          blocks: params.blocks,
        }),
      });

      const { viewAfterId } = await sendMessageToChannel({
        channel,
        channelMemberIds: threadMemberIds,
        text: params.text,
        blocks: params.blocks,
        agentId: aiActionState.selectedAgentId,
        customId,
      });

      maybeSendAgentStatusErrorMessage(channel);

      if (viewAfterId) {
        await maybeRedirectUserToNewDmView({
          channel,
          newViewId: viewAfterId,
        });
      }
      resetThreadMessageSortFilter.current();
    },
    [
      aiActionState.selectedAgentId,
      buildMessageData,
      channel,
      maybeRedirectUserToNewDmView,
      maybeSendAgentStatusErrorMessage,
      myMemberId,
      onBeforeSubmit,
      resetThreadMessageSortFilter,
      sendMessageToChannel,
      setText,
      threadMemberIds,
    ]
  );

  const handleChange = (value: string) => {
    setText(value);
  };
  return (
    <ThreadComposer
      autoFocus
      onChange={handleChange}
      onSubmit={handleComposerSubmit}
      disabled={isBlocking}
      disabledTip={blockerTip}
      disableSubmitWhileSubmitting={false}
      {...getThreadComposerCustomProps?.({ channel })}
    />
  );
}
