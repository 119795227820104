import { TFunction } from 'i18next';

const getPlaylistsPageConfig = (t: TFunction) => ({
  history: {
    title: t('playlists.history.title'),
    icon: t('playlists.history.icon'),
  },
  historyMistakes: {
    title: t('playlists.history_mistakes.title'),
    icon: t('playlists.history_mistakes.icon'),
  },
  historyOvertime: {
    title: t('playlists.history_overtime.title'),
    icon: t('playlists.history_overtime.icon'),
  },
  historyDetail: {
    title: t('playlists.history_detail.title'),
    icon: t('playlists.history_detail.icon'),
  },
  challenges: {
    title: t('playlists.challenges.title'),
    icon: t('playlists.challenges.icon'),
  },
  challengesDetail: {
    title: t('playlists.challenges_detail.title'),
    icon: t('playlists.challenges_detail.icon'),
  },
  savedPlaylists: {
    title: t('playlists.savedPlaylists.title'),
    icon: t('playlists.savedPlaylists.icon'),
  },
  reaction: {
    title: t('playlists.reaction.title'),
    icon: t('playlists.reaction.icon'),
  },
  following: {
    title: t('playlists.following.title'),
    icon: t('playlists.following.icon'),
  },
  hashtags: {
    title: t('playlists.hashtags.title'),
    icon: t('playlists.hashtags.icon'),
  },
  creators: {
    title: t('playlists.creators.title'),
    icon: t('playlists.creators.icon'),
  },
  myQuestions: {
    title: t('playlists.myQuestions.title'),
    icon: t('playlists.myQuestions.icon'),
  },
  reviewQuestions: {
    title: t('playlists.reviewQuestions.title'),
    icon: t('playlists.reviewQuestions.icon'),
  },
  reviewQuestionsAll: {
    title: t('playlists.reviewQuestions_all.title'),
    icon: t('playlists.reviewQuestions_all.icon'),
  },
});

export default getPlaylistsPageConfig;
