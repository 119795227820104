import React, { SetStateAction, useEffect, useMemo } from 'react';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { TableLayoutConfig } from '@lib/ia/src/layouts/TableLayout/types';

function processAllChildIds(
  parentId: string,
  groupIdToChildIds: Record<string, string[]>,
  result: string[]
) {
  const ids = groupIdToChildIds[parentId];
  if (ids) {
    result.push(...ids);
    for (const id of ids) {
      processAllChildIds(id, groupIdToChildIds, result);
    }
  }
}

function getAllChildIds(
  parentId: string,
  groupIdToChildIds: Record<string, string[]>
) {
  const result: string[] = [];
  processAllChildIds(parentId, groupIdToChildIds, result);
  return result;
}

function everyChildrenSelected(
  id: string,
  selectedIds: Set<string>,
  groupIdToChildIds: Record<string, string[]>
) {
  const childIds = groupIdToChildIds[id];
  if (!childIds || childIds.length === 0) {
    return true;
  }
  const allSelected = childIds.every((i) => selectedIds.has(i));
  if (allSelected) {
    for (const childId of childIds) {
      const childSelected = everyChildrenSelected(
        childId,
        selectedIds,
        groupIdToChildIds
      );
      if (!childSelected) {
        return false;
      }
    }
    return true;
  }
  return false;
}

function everyChildrenUnselected(
  id: string,
  selectedIds: Set<string>,
  groupIdToChildIds: Record<string, string[]>
) {
  const childIds = groupIdToChildIds[id];
  if (!childIds || childIds.length === 0) {
    return true;
  }
  const allUnselected = childIds.every((i) => !selectedIds.has(i));
  if (allUnselected) {
    for (const childId of childIds) {
      const childUnselected = everyChildrenUnselected(
        childId,
        selectedIds,
        groupIdToChildIds
      );
      if (!childUnselected) {
        return false;
      }
    }
    return true;
  }
  return false;
}

function processSelected(
  groupId: string,
  selectedIds: Set<string>,
  groupIdToChildIds: Record<string, string[]>,
  childIdToGroupId: Record<string, string>
) {
  if (
    groupId &&
    everyChildrenSelected(groupId, selectedIds, groupIdToChildIds)
  ) {
    selectedIds.add(groupId);
    const groupParentId = childIdToGroupId[groupId];
    if (groupParentId) {
      processSelected(
        groupParentId,
        selectedIds,
        groupIdToChildIds,
        childIdToGroupId
      );
    }
  }
}

function processUnselected(
  groupId: string,
  selectedIds: Set<string>,
  groupIdToChildIds: Record<string, string[]>,
  childIdToGroupId: Record<string, string>
) {
  if (
    groupId &&
    !everyChildrenSelected(groupId, selectedIds, groupIdToChildIds)
  ) {
    selectedIds.delete(groupId);
    const groupParentId = childIdToGroupId[groupId];
    if (groupParentId) {
      processUnselected(
        groupParentId,
        selectedIds,
        groupIdToChildIds,
        childIdToGroupId
      );
    }
  }
}

export const useTableCheckbox = ({
  ids,
  groupIdToChildIds = {},
  controlState,
}: {
  ids: string[];
  groupIdToChildIds?: Record<string, string[]>;
  controlState?: TableLayoutConfig['controlState'];
}) => {
  const { getIaAction } = useIaAction();
  const [rawSelectedIds, rawSetSelectedIds] = React.useState(new Set<string>());
  const selectedIds = controlState?.selectedCheckedIds ?? rawSelectedIds;
  const setSelectedIds =
    getIaAction<SetStateAction<Set<string>>>(
      controlState?.setSelectedCheckedIdsAction
    )?.action ?? rawSetSelectedIds;

  const childIdToGroupId = useMemo(() => {
    const result: Record<string, string> = {};
    for (const [groupId, childIds] of Object.entries(groupIdToChildIds)) {
      for (const childId of childIds) {
        result[childId] = groupId;
      }
    }
    return result;
  }, [groupIdToChildIds]);

  const allGroupIds = useMemo(() => {
    return new Set(Object.keys(groupIdToChildIds));
  }, [groupIdToChildIds]);
  const allChildIds = useMemo(() => {
    return new Set(Object.keys(childIdToGroupId));
  }, [childIdToGroupId]);

  const getSelectedIdsAfterSelect = (prev: Set<string>, id: string) => {
    const newSet = new Set(prev);
    const childIds: string[] | undefined = getAllChildIds(
      id,
      groupIdToChildIds
    );
    // select itself
    newSet.add(id);

    // select all children
    if (childIds) {
      childIds.forEach((i) => newSet.add(i));
    }

    // process select for parents
    const groupId: string | undefined = childIdToGroupId[id];
    processSelected(groupId, newSet, groupIdToChildIds, childIdToGroupId);
    return newSet;
  };

  const getSelectedIdsAfterUnselect = (prev: Set<string>, id: string) => {
    const newSet = new Set(prev);
    const childIds: string[] | undefined = getAllChildIds(
      id,
      groupIdToChildIds
    );
    const groupId: string | undefined = childIdToGroupId[id];
    // unselect itself
    newSet.delete(id);

    // unselect all children
    if (childIds) {
      childIds.forEach((i) => newSet.delete(i));
    }

    // process unselect for parents
    processUnselected(groupId, newSet, groupIdToChildIds, childIdToGroupId);
    return newSet;
  };

  const toggle = (id: string) => {
    const newSelectedIds = selectedIds.has(id)
      ? getSelectedIdsAfterUnselect(selectedIds, id)
      : getSelectedIdsAfterSelect(selectedIds, id);
    setSelectedIds(newSelectedIds);
    return newSelectedIds;
  };

  const toggleMultiple = (toggleIds: string[]) => {
    let newSelectedIds = selectedIds;
    toggleIds.forEach((id) => {
      if (newSelectedIds.has(id)) {
        newSelectedIds = getSelectedIdsAfterUnselect(newSelectedIds, id);
      } else {
        newSelectedIds = getSelectedIdsAfterSelect(newSelectedIds, id);
      }
    });
    setSelectedIds(newSelectedIds);
    return newSelectedIds;
  };

  const onlySelectOne = (id: string) => {
    setSelectedIds(getSelectedIdsAfterSelect(new Set(), id));
  };

  const partialSelectedIds = useMemo(() => {
    const result = new Set();

    for (const [groupId] of Object.entries(groupIdToChildIds)) {
      // some children selected (neither all are selected nor all are unselected)
      if (
        !everyChildrenSelected(groupId, selectedIds, groupIdToChildIds) &&
        !everyChildrenUnselected(groupId, selectedIds, groupIdToChildIds)
      ) {
        result.add(groupId);
      }
    }
    return result;
  }, [groupIdToChildIds, selectedIds]);

  const getSelectedState = (id: string) => {
    if (selectedIds.has(id)) {
      return 'checked';
    }
    return partialSelectedIds.has(id) ? 'partial' : 'unchecked';
  };

  const getSelectedAllState = () => {
    const idsArray = [...ids];

    const allSelected =
      selectedIds.size > 0 && idsArray.every((id) => selectedIds.has(id));

    if (allSelected) {
      return 'checked';
    }

    const partialSelected =
      selectedIds.size > 0 && idsArray.some((id) => selectedIds.has(id));

    return partialSelected ? 'partial' : 'unchecked';
  };

  const toggleAll = () => {
    const newSelectedIds =
      getSelectedAllState() === 'checked'
        ? new Set([])
        : new Set([...ids, ...allChildIds]);
    setSelectedIds(newSelectedIds);
    return newSelectedIds;
  };

  const numberOfSelected = useMemo(() => {
    const isNotGroupQuestion = (id: string) => !allGroupIds.has(id);
    return Array.from(selectedIds).filter(isNotGroupQuestion).length;
  }, [allGroupIds, selectedIds]);

  // remove outdated selectedIds
  useEffect(() => {
    const outdatedSelectedIds = Array.from(selectedIds).filter(
      (id) => !ids.includes(id) && !allChildIds.has(id)
    );

    if (outdatedSelectedIds.length > 0) {
      const validSelectedIds = Array.from(selectedIds).filter(
        (id) => ids.includes(id) || allChildIds.has(id)
      );

      setSelectedIds(new Set(validSelectedIds));
    }
  }, [allChildIds, ids, selectedIds, setSelectedIds]);

  return {
    selectedIds,
    toggle,
    toggleMultiple,
    toggleAll,
    onlySelectOne,
    getSelectedState,
    getSelectedAllState,
    numberOfSelected,
  };
};
