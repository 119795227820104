/* eslint-disable no-param-reassign */
import {
  cancelSubmit,
  clearQuizQuestionError,
  closeKeyboard,
  eliminateAnswer,
  endRedirect,
  enterProfileQuestion,
  fetchQuizBrief,
  fetchQuizFull,
  finishQuiz,
  goQuestion,
  lazySubmit,
  leaveProfileQuestion,
  openKeyboard,
  pauseQuiz,
  quitQuiz,
  resumeQuiz,
  reviewQuiz,
  setProfileQuestionDirty,
  setProfileQuestionLoading,
  setProfileQuestionTargetId,
  startQuiz,
  startRedirect,
  storeTimeDuration,
  submitAllAnswers,
  submitAnswer,
  toggleSubmitButton,
  updateAnswer,
  updateHTML,
  updateNote,
} from '@app/web/src/actions/quizActions';
import { getStorageItem, setStorageItem } from '@app/web/src/utils/storage';
import { ExamMode, QuizStatus } from '@lib/web/apis';
import { createSlice, current } from '@reduxjs/toolkit';

import {
  AHA_STORE_QUIZ_ANSWERS_KEY,
  AHA_STORE_QUIZ_DURATION_KEY,
  getMappedAnswers,
  getMappedQuestionIds,
  getMappedQuestions,
  getQuizStateInfo,
  getTimeSpent,
  getTotalTimeInfo,
  isLeaveBlankAnswer,
} from './quizReducerUtils';
import { QuizState, State } from './types';

const initialState: State = {
  state: QuizState.Init,

  error: null,
  questionError: null,
  loading: false,
  loaded: false,

  startAt: null,
  totalTimeSpent: 0,
  totalTime: 0,
  totalCount: 0,

  mode: ExamMode.Unknown,

  // 所有的問題 (展開)
  mappedQuestions: {},
  mappedAnswers: {},
  mappedNotes: {},
  mappedMaterials: {},
  highlight: {},

  // quiz data
  quiz: {
    id: '',
    sectionId: '',
    questionCount: 0,
    type: 0,
    practiceTimeSec: 0,
    latestVersionId: null,
    latestVersionShortId: null,
    status: QuizStatus.Unknown,
    isOpenTimer: false,
  },

  // used to disable sibling submit-exam-button (because we have 2 submit button rendered at the same time)
  isSubmitting: false,
  isCompleted: false,
  isTaken: false,
  isOnboarding: false,
  expiredTime: null,

  mappedQuestionIds: {},
  questionNo: 0,
  roundNo: 0,

  challenge: null,
  userId: '',

  profileQuestionNo: -1,
  profileQuestionType: null,
  profileQuestionDirty: false,
  profileQuestionLoading: false,
  profileFormTargetId: '',

  eliminatedAnswers: {},
  creatorQuestionGroups: [],

  layout: {
    showKeyboard: false,
    isOpenTimer: false,
    redirectPage: null,
  },
};

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(quitQuiz, () => ({ ...initialState }))
      .addCase(startQuiz, (state, action) => {
        if (
          state.state === QuizState.Onboarding &&
          !action.payload.skipOnboarding
        )
          return;

        const { isCompleted } = state;

        state.state = isCompleted ? QuizState.Finish : QuizState.Playing;
        state.startAt = isCompleted ? null : Date.now();
        const questionNo = state.questionNo || 1;
        state.questionNo = questionNo;

        const currentQuestion = state.mappedQuestions[questionNo];

        if (
          !isCompleted &&
          currentQuestion &&
          !currentQuestion.startAt &&
          !currentQuestion.isSubmitted
        ) {
          currentQuestion.startAt = Date.now();
        }
      })
      .addCase(pauseQuiz, (state) => {
        if (state.state === QuizState.Onboarding) return;

        const currentQuestion = state.mappedQuestions[state.questionNo];

        if (!currentQuestion.isSubmitted) {
          state.state = QuizState.Paused;

          state.totalTimeSpent += getTimeSpent(state.startAt);
          state.startAt = null;

          if (currentQuestion && currentQuestion.startAt) {
            const timeSpent =
              currentQuestion.timeSpent + getTimeSpent(currentQuestion.startAt);

            currentQuestion.startAt = null;
            currentQuestion.isOvertime = timeSpent / 1000 > currentQuestion.tpq;
            currentQuestion.timeSpent = timeSpent;
            currentQuestion.isPendingSubmit = false;
          }
        }
      })
      .addCase(resumeQuiz, (state) => {
        if (state.state === QuizState.Onboarding) return;

        const now = Date.now();
        state.state = QuizState.Playing;
        state.startAt = now;

        const currentQuestion = state.mappedQuestions[state.questionNo];
        if (currentQuestion) {
          currentQuestion.startAt = now;
        }
      })
      .addCase(clearQuizQuestionError, (state) => {
        state.questionError = null;
      })
      .addCase(reviewQuiz, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.state = QuizState.Review;

        const prevStartAt = state.startAt;
        if (prevStartAt) {
          state.totalTimeSpent += getTimeSpent(prevStartAt);
          state.startAt = null;
        }
      })
      .addCase(lazySubmit, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.totalTimeSpent += getTimeSpent(state.startAt);
        state.startAt = null;

        const currentQuestion = state.mappedQuestions[state.questionNo];
        if (currentQuestion) {
          currentQuestion.isPendingSubmit = true;

          if (currentQuestion.startAt) {
            const timeSpent =
              currentQuestion.timeSpent + getTimeSpent(currentQuestion.startAt);

            currentQuestion.startAt = null;
            currentQuestion.isOvertime = timeSpent / 1000 > currentQuestion.tpq;
            currentQuestion.timeSpent = timeSpent;
          }
        }
      })
      .addCase(toggleSubmitButton, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.isSubmitting = !state.isSubmitting;
      })
      .addCase(cancelSubmit, (state) => {
        if (state.state === QuizState.Onboarding) return;

        const now = Date.now();
        state.startAt = now;

        const currentQuestion = state.mappedQuestions[state.questionNo];
        if (currentQuestion) {
          currentQuestion.isPendingSubmit = false;
          currentQuestion.startAt = now;
        }
      })
      .addCase(finishQuiz, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.state = QuizState.Finish;

        if (!state.isCompleted) {
          state.totalTimeSpent += getTimeSpent(state.startAt);
          state.startAt = null;
          state.isCompleted = true;
        }
      })

      // step1: get quiz data
      .addCase(fetchQuizBrief.pending, (state) => ({
        ...initialState,
        questionNo: state.questionNo,
        loading: true,
      }))
      .addCase(fetchQuizBrief.fulfilled, (state, action) => {
        const {
          practiceBrief: {
            quiz,
            quizQuestions,
            creatorQuestionGroups,
            challenge,
          },
          roundNo,
          mode,
          userId,
          isOnboarding,
          sid,
          isCompleted,
          isTaken,
        } = action.payload;

        const quizKey = `${quiz.id}/${roundNo}`;
        const mappedQuestions = getMappedQuestions({
          quizKey,
          quizQuestions,
          creatorQuestionGroups,
        });
        const mappedAnswers = getMappedAnswers({
          quizKey,
          quizQuestions,
        });
        const mappedQuestionIds = getMappedQuestionIds({
          quizQuestions,
        });
        const stateInfo = getQuizStateInfo({
          sid,
          isOnboarding,
          quiz,
          isCompleted,
        });

        return {
          ...state,
          ...getTotalTimeInfo(Object.values(mappedQuestions)),
          userId,
          error: null,
          questionError: null,
          roundNo,
          mode,
          quiz,
          totalCount: quiz.questionCount,
          mappedQuestions,
          mappedAnswers,
          mappedQuestionIds,
          state: stateInfo.state,
          loading: stateInfo.loading,
          loaded: stateInfo.loaded,
          isCompleted,
          isTaken,
          isOnboarding,
          challenge: challenge,
          expiredTime: challenge?.expireAt || null,
          creatorQuestionGroups: creatorQuestionGroups || [],
          layout: {
            ...state.layout,
            isOpenTimer: quiz.isOpenTimer,
          },
        };
      })
      .addCase(fetchQuizBrief.rejected, (state, action) => {
        state.loading = false;
        state.state = QuizState.Error;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      })
      .addCase(fetchQuizFull.fulfilled, (state, action) => {
        const { quiz, roundNo, totalCount } = state;
        const {
          quizFullQuestions: { quizQuestions, creatorQuestionGroups },
          quizResults,
          profileQuestionType,
        } = action.payload;

        const quizKey = `${quiz.id}/${roundNo}`;
        const mappedQuestions = getMappedQuestions({
          currentMappedQuestions: state.mappedQuestions,
          quizKey,
          quizQuestions,
          quizResults,
          creatorQuestionGroups,
        });
        const mappedAnswers = getMappedAnswers({
          quizKey,
          quizQuestions,
          quizResults,
        });
        const mappedQuestionIds = getMappedQuestionIds({
          quizQuestions,
        });

        return {
          ...state,
          ...getTotalTimeInfo(Object.values(mappedQuestions)),
          mappedQuestions,
          mappedAnswers,
          mappedQuestionIds,
          creatorQuestionGroups: creatorQuestionGroups || [],
          profileQuestionType,
          profileQuestionNo: profileQuestionType
            ? Math.round(totalCount / 4)
            : -1,
        };
      })
      .addCase(fetchQuizFull.rejected, (state, action) => {
        state.state = QuizState.Error;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      })
      // step2: go to specific question
      .addCase(goQuestion, (state, action) => {
        if (
          state.state === QuizState.Onboarding &&
          !action.payload.skipOnboarding
        )
          return;

        const prevOrder = state.questionNo;

        if (prevOrder === action.payload.questionNo) {
          return;
        }
        const prevQuestion = state.mappedQuestions[prevOrder];
        if (
          state.state === QuizState.Playing &&
          prevQuestion &&
          prevQuestion.startAt
        ) {
          // stop the timer for previous question
          const timeSpent =
            prevQuestion.timeSpent + getTimeSpent(prevQuestion.startAt);

          prevQuestion.startAt = null;
          prevQuestion.isOvertime = timeSpent
            ? timeSpent / 1000 > prevQuestion.tpq
            : false;
          prevQuestion.timeSpent = timeSpent;
          prevQuestion.isPendingSubmit = false;
        }

        state.questionNo =
          action.payload.questionNo > state.quiz.questionCount
            ? 1
            : action.payload.questionNo || 1;

        const currentQuestion = state.mappedQuestions[state.questionNo];

        if (
          state.state === QuizState.Playing &&
          !!currentQuestion &&
          !currentQuestion.isSubmitted
        ) {
          currentQuestion.startAt = Date.now();
        }
      })
      // step3-1: answer question
      .addCase(updateAnswer, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.mappedAnswers[action.payload.questionNo] = action.payload.value;

        const storedAnsweredMap = getStorageItem(
          AHA_STORE_QUIZ_ANSWERS_KEY,
          {}
        );
        setStorageItem(AHA_STORE_QUIZ_ANSWERS_KEY, {
          ...storedAnsweredMap,
          [`${state.quiz.id}/${state.roundNo}`]: state.mappedAnswers,
        });
      })
      // step3-2: take notes
      .addCase(updateNote, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.mappedNotes[action.payload.questionNo] = action.payload.value;
      })
      // step3-3: Passage Highlight
      .addCase(updateHTML, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.highlight = {
          ...state.highlight,
          [action.payload.code]: action.payload.value,
        };
      })
      // step3-4: Go Question
      .addCase(enterProfileQuestion, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.state = QuizState.Question;

        const currentQuestion = state.mappedQuestions[state.questionNo];

        if (currentQuestion.startAt) {
          const timeSpent =
            currentQuestion.timeSpent + getTimeSpent(currentQuestion.startAt);
          currentQuestion.startAt = null;
          currentQuestion.timeSpent = timeSpent;
          currentQuestion.isOvertime =
            currentQuestion.timeSpent / 1000 > currentQuestion.tpq;
        }
      })
      .addCase(leaveProfileQuestion, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.state = QuizState.Playing;
        state.profileQuestionNo = -1;
        state.profileQuestionType = null;
      })
      .addCase(setProfileQuestionTargetId, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.profileFormTargetId = action.payload;
      })
      .addCase(setProfileQuestionDirty, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.profileQuestionDirty = action.payload;
      })
      .addCase(setProfileQuestionLoading, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.profileQuestionLoading = action.payload;
      })
      // step5-1: Submit Answer
      .addCase(submitAnswer.pending, (state) => {
        if (state.state === QuizState.Onboarding) return;

        const currentQuestion = state.mappedQuestions[state.questionNo];
        if (!currentQuestion) return;

        currentQuestion.isLoading = true;
        currentQuestion.isPendingSubmit = false;
        if (currentQuestion.startAt) {
          const timeSpent =
            currentQuestion.timeSpent + getTimeSpent(currentQuestion.startAt);
          currentQuestion.startAt = null;
          currentQuestion.timeSpent = timeSpent;
          currentQuestion.isOvertime =
            currentQuestion.timeSpent / 1000 > currentQuestion.tpq;
        }
        const currentAnswer = state.mappedAnswers[state.questionNo];
        currentQuestion.isLeaveBlank = isLeaveBlankAnswer(currentAnswer);
      })
      .addCase(submitAnswer.fulfilled, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        const {
          quizQuestionId,
          isCorrect,
          correctAnswerIds,
          correctAnswerValues,
          // isStreak, // TODO: not returned by API
        } = action.payload;
        const questionNo = state.mappedQuestionIds[quizQuestionId];
        const currentQuestion = state.mappedQuestions[questionNo];
        if (!currentQuestion) return;

        currentQuestion.isLoading = false;
        currentQuestion.isCorrect = isCorrect;
        currentQuestion.isIncorrect = !isCorrect;

        currentQuestion.isSubmitted = true;
        currentQuestion.correctAnswerIds = correctAnswerIds || [];
        currentQuestion.correctAnswerValues = correctAnswerValues || [];

        // This value is always true when submitted by single
        currentQuestion.isAnswered = true;

        if (isCorrect) {
          if (
            state.mappedQuestions[questionNo - 1]?.isCorrect ||
            state.mappedQuestions[questionNo + 1]?.isCorrect
          ) {
            currentQuestion.isStreak = true;
          }
          if (state.mappedQuestions[questionNo - 1]?.isCorrect) {
            state.mappedQuestions[questionNo - 1].isStreak = true;
          }
          if (state.mappedQuestions[questionNo + 1]?.isCorrect) {
            state.mappedQuestions[questionNo + 1].isStreak = true;
          }
        }

        const allQuestions = Object.values(current(state).mappedQuestions);
        if (
          allQuestions.filter((question) => question.isSubmitted).length ===
          allQuestions.length
        ) {
          state.isCompleted = true;
        }

        state.isTaken = true;
      })
      .addCase(submitAnswer.rejected, (state, action) => {
        if (action.payload?.targetId) {
          const questionNo = state.mappedQuestionIds[action.payload.targetId];
          const currentQuestion = state.mappedQuestions[questionNo];
          if (!currentQuestion) return;

          currentQuestion.isLoading = false;
          currentQuestion.startAt = Date.now();

          if (action.payload.error) {
            state.questionError = action.payload.error;
          }
        }
      })
      // step5-1: Submit All Answers
      .addCase(submitAllAnswers.pending, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.mappedQuestions = Object.values(state.mappedQuestions).reduce(
          (acc, cur) => {
            const timeSpent =
              cur.isLoading || cur.isSubmitted
                ? cur.timeSpent
                : cur.timeSpent + getTimeSpent(cur.startAt);
            const currentAnswer = state.mappedAnswers[cur.questionNo];
            const isLeaveBlank = isLeaveBlankAnswer(currentAnswer);

            return {
              ...acc,
              [cur.questionNo]:
                cur.isLoading || cur.isSubmitted
                  ? cur
                  : {
                      ...cur,
                      isLoading: true,
                      isPendingSubmit: false,
                      isOvertime: timeSpent / 1000 > cur.tpq,
                      timeSpent,
                      startAt: null,
                      isLeaveBlank,
                    },
            };
          },
          {}
        );
        state.loading = true;
        state.totalTimeSpent += getTimeSpent(state.startAt);
        state.startAt = null;
      })
      .addCase(submitAllAnswers.fulfilled, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.mappedQuestions = Object.values(state.mappedQuestions).reduce(
          (acc, cur) => {
            const currentResult = action.payload.find(
              (result) => result.quizQuestionId === cur.id
            );

            let isStreak = false;

            // TODO: not returned by API
            if (currentResult?.isCorrect) {
              const currentNo = cur.questionNo;
              const prevQuestionId = state.mappedQuestions[currentNo - 1]?.id;
              const nextQuestionId = state.mappedQuestions[currentNo + 1]?.id;

              if (
                state.mappedQuestions[currentNo - 1]?.isCorrect ||
                state.mappedQuestions[currentNo + 1]?.isCorrect ||
                action.payload.find(
                  (result) => result.quizQuestionId === prevQuestionId
                )?.isCorrect ||
                action.payload.find(
                  (result) => result.quizQuestionId === nextQuestionId
                )?.isCorrect
              ) {
                isStreak = true;
              }
            }

            return {
              ...acc,
              [cur.questionNo]: currentResult
                ? {
                    ...cur,
                    isLoading: false,
                    isSubmitted: true,
                    isStreak,
                    isAnswered: !!(
                      currentResult.correctAnswerIds.length ||
                      currentResult.correctAnswerValues?.length
                    ),
                    isCorrect: currentResult.isCorrect,
                    isIncorrect: !currentResult.isCorrect,
                    correctAnswerIds: currentResult.correctAnswerIds || [],
                    correctAnswerValues:
                      currentResult.correctAnswerValues || [],
                  }
                : { ...cur, isLoading: false },
            };
          },
          {}
        );
        state.loading = false;
        state.isCompleted = true;
        state.isTaken = true;
      })
      .addCase(submitAllAnswers.rejected, (state, action) => {
        const { questionNo } = state;
        state.mappedQuestions = Object.values(state.mappedQuestions).reduce(
          (acc, cur) => {
            const isFailed =
              action.payload?.targetIds?.includes(cur.id) || false;

            if (questionNo === cur.questionNo) {
              return {
                ...acc,
                [cur.questionNo]: {
                  ...cur,
                  isLoading: false,
                  startAt: cur.isSubmitted ? null : Date.now(),
                },
              };
            }

            return {
              ...acc,
              [cur.questionNo]: isFailed
                ? {
                    ...cur,
                    isLoading: false,
                  }
                : cur,
            };
          },
          {}
        );
        state.loading = false;
        state.startAt = Date.now();
        if (action.payload?.error) {
          state.questionError = action.payload.error;
        }
      })
      // step6: Save time duration
      .addCase(storeTimeDuration, (state) => {
        if (state.state === QuizState.Onboarding) return;

        const storedData = getStorageItem(AHA_STORE_QUIZ_DURATION_KEY, {});
        const quizKey = `${state.quiz.id}/${state.roundNo}`;
        if (state.isCompleted) {
          delete storedData[quizKey];
          setStorageItem(AHA_STORE_QUIZ_DURATION_KEY, storedData);
        } else {
          const allQuestions = Object.values(state.mappedQuestions).filter(
            (question) =>
              !question.isSubmitted &&
              !!(question.timeSpent || question.startAt)
          );
          const timeSpentData = allQuestions.reduce((acc, cur) => {
            const timeSpent = cur.timeSpent + getTimeSpent(cur.startAt);

            return {
              ...acc,
              [cur.questionNo]: timeSpent,
            };
          }, {});

          const valueToSave = {
            ...storedData,
            [quizKey]: timeSpentData,
          };

          setStorageItem(AHA_STORE_QUIZ_DURATION_KEY, valueToSave);
        }
      })
      .addCase(eliminateAnswer, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.eliminatedAnswers[action.payload.questionNo] =
          action.payload.value;
      })
      .addCase(openKeyboard, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.layout.showKeyboard = true;
      })
      .addCase(closeKeyboard, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.layout.showKeyboard = false;
      })
      .addCase(startRedirect, (state, action) => {
        state.layout.redirectPage = action.payload;
      })
      .addCase(endRedirect, (state) => {
        state.layout.redirectPage = null;
      });
  },
});

export default quizSlice.reducer;
