import React, { Fragment } from 'react';
import Link from 'next/link';
import { Box, ButtonBase, Typography, TypographyProps } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Mention, SimpleTooltip, SquareAvatar } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { IaAction, IaRichText } from '@lib/ia/src/core/types';
import { sanitize } from 'dompurify';

import { HtmlRenderer } from '../HtmlRenderer';
import Icon from '../Icon';

const styles = {
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  plainText: {
    display: 'inline',
    verticalAlign: 'middle',
    alignItems: 'center',
  },
  url: {
    textDecoration: 'underline',
    verticalAlign: 'middle',
    alignItems: 'center',
    color: 'white',
  },
  user: {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    mx: 0.5,
    px: '2px',
    gap: '2px',
    height: '17px',
    borderRadius: '2px',
    backgroundColor: 'alpha.primaryLightA10',
    span: {
      color: 'primary.main',
    },
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: 'alpha.primaryLightA30',
      },
    },
  },
  userSkeleton: {
    mx: 0.5,
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  highlight: {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    mx: 0.5,
    px: '2px',
    gap: '2px',
    height: '17px',
    borderRadius: '2px',
    backgroundColor: 'alpha.primaryLightA10',
    border: 'none',
    span: {
      color: 'primary.main',
    },
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: 'alpha.primaryLightA30',
      },
    },
  },
  tagButton: {
    bgcolor: 'transparent',
    border: 'none',
    p: 0,
  },
};

export type RichTextProps<M = Record<string, any>> = {
  text: IaRichText<M>;
  variant?: TypographyProps['variant'];
  gap?: number;
  className?: string;
};

function RichTextTag<M = Record<string, any>>({
  textObj,
}: {
  textObj: {
    type: 'tag';
    text: string;
    src?: string;
    prefix?: string;
    alt?: string;
    action?: IaAction;
    tooltip?: string;
    metadata?: M;
  };
}) {
  const { getIaAction } = useIaAction();

  const commonProps = {
    sx: styles.tagButton,
    rel: 'noopener noreferrer',
  };
  const isLink = textObj.action?.type === 'link';
  const actionProps = isLink
    ? {
        href: textObj.action?.value,
      }
    : {
        onClick: () =>
          getIaAction<M>(textObj.action?.value)?.action(textObj.metadata),
      };
  return textObj.tooltip ? (
    <SimpleTooltip title={textObj.tooltip}>
      <Box
        component={isLink ? Link : 'button'}
        {...commonProps}
        {...actionProps}
      >
        <Mention
          src={textObj.src}
          alt={textObj.alt}
          display={textObj.text}
          prefix={textObj.prefix}
        />
      </Box>
    </SimpleTooltip>
  ) : (
    <Box component={isLink ? Link : 'button'} {...commonProps} {...actionProps}>
      <Mention
        src={textObj.src}
        alt={textObj.alt}
        display={textObj.text}
        prefix={textObj.prefix}
      />
    </Box>
  );
}
export default function RichText<M = Record<string, any>>({
  text,
  variant = 'body1',
  gap = 0,
  className,
}: RichTextProps<M>) {
  const { getIaAction } = useIaAction();

  return (
    <Box sx={[styles.root, { gap }]} className={className}>
      {typeof text === 'string' && (
        <Typography sx={styles.plainText} component="div" variant={variant}>
          <HtmlRenderer htmlString={sanitize(text)} />
        </Typography>
      )}

      {Array.isArray(text) &&
        text.map((textObject, index) => (
          <Fragment key={index}>
            {textObject.type === 'icon' && (
              <Icon
                name={textObject.value}
                width={textObject.width || 16}
                height={textObject.height || 16}
              />
            )}
            {textObject.type === 'text' && (
              <Typography sx={styles.plainText} variant={variant}>
                {textObject.value}
              </Typography>
            )}
            {textObject.type === 'url' && (
              <Typography
                sx={styles.url}
                component="a"
                target="_blank"
                href={textObject.url}
                variant={variant}
              >
                {textObject.text}
              </Typography>
            )}
            {textObject.type === 'user' && !textObject.name && (
              <Typography sx={styles.userSkeleton} variant={variant}>
                <Skeleton width={100} />
              </Typography>
            )}
            {textObject.type === 'user' && !!textObject.name && (
              <ButtonBase
                sx={styles.user}
                onClick={() =>
                  getIaAction<{ userId: string }>(
                    textObject.action?.value
                  )?.action({ userId: textObject.id })
                }
              >
                <SquareAvatar size={16} src={textObject.src}>
                  {textObject.name}
                </SquareAvatar>
                <Typography component="span" variant={variant}>
                  {textObject.name}
                </Typography>
              </ButtonBase>
            )}
            {textObject.type === 'highlight' &&
              (textObject.tooltip ? (
                <SimpleTooltip title={textObject.tooltip}>
                  <Box sx={styles.highlight}>
                    <Typography component="span" variant={variant}>
                      {textObject.text}
                    </Typography>
                  </Box>
                </SimpleTooltip>
              ) : (
                <Box sx={styles.highlight}>
                  <Typography component="span" variant={variant}>
                    {textObject.text}
                  </Typography>
                </Box>
              ))}
            {textObject.type === 'tag' && <RichTextTag textObj={textObject} />}
          </Fragment>
        ))}
    </Box>
  );
}
