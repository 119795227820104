import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';
import { generateInfiniteHook, UserViewSlug } from '@lib/web/apis';

import { SwrHelperParams } from '../query';

export type GetIaUserResMap = {
  [UserViewSlug.TableCenterIaUserDefault]: GetIaUserTableCenterIaUserDefaultRes;
  [UserViewSlug.TableCenterIaUserInternalAccount]: GetIaUserTableCenterIaUserInternalAccountRes;
  [UserViewSlug.ApiInternalIaUserOnlineStatus]: GetIaUserApiInternalIaUserOnlineStatusRes;
};

export function useIaUsers<
  K = GetIaUserDefaultRes,
  T extends UserViewSlug | undefined = undefined
>(
  query: Omit<IaSearchReq<T>, 'page' | 'pageSize'> = {},
  config: SwrHelperParams = {}
) {
  const limit = query.limit || 0;

  return generateInfiniteHook<T extends UserViewSlug ? GetIaUserResMap[T] : K>(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/users?${getIaQueryString({
            ...query,
            page: index + 1,
            limit,
          })}`,
    limit,
    {
      auth: true,
    }
  );
}
