import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CardLayoutItemTag } from '@lib/ia/src/layouts/CardLayout/types';
import {
  ClubJoinedStatus,
  ClubPrivacy,
  ClubStatus,
  ClubVisibility,
} from '@lib/web/apis';
import { TFunction } from 'i18next';

const getStatusTag = (t: TFunction, status: ClubStatus) => {
  return {
    [ClubStatus.Beta as number]: {
      color: 'warning' as const,
      text: t('tag.beta.text', 'Beta'),
      icon: 'ChatNotificationDot',
      tip: t(
        'tag.beta.tip',
        'Get early access to a new club, which may have fewer questions.'
      ),
    },
  }[status];
};

const getJoinedTag = (t: TFunction, status: ClubJoinedStatus) => {
  return {
    [ClubJoinedStatus.Joined as number]: {
      color: 'success' as const,
      text: t('tag.joined.text', 'Joined'),
      icon: 'TextEditorCheck',
    },
    [ClubJoinedStatus.RequestedPending as number]: {
      color: 'info' as const,
      text: t('tag.pendingApproval.text', 'Pending Approval'),
      icon: 'PropertyTypeDateOrTime',
    },
  }[status];
};

const getPrivateTag = (t: TFunction, status: ClubPrivacy) => {
  return {
    [ClubPrivacy.Private as number]: {
      color: 'info' as const,
      text: t('tag.private.text', 'Privacy'),
      icon: 'PrivacyPrivate',
    },
  }[status];
};

const getVisibilityTag = (t: TFunction, status: ClubVisibility) => {
  return {
    [ClubVisibility.Unlisted as number]: {
      color: 'text' as const,
      text: t('tag.unlisted.text', 'Unlisted'),
      icon: 'LoginHide',
    },
  }[status];
};

type ClubStatusInfo = {
  clubStatus: ClubStatus;
  visibility: ClubVisibility;
  privacy: ClubPrivacy;
  joinStatus: ClubJoinedStatus;
};

export default function useIaClubTags() {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      getIaClubTags: (club: ClubStatusInfo): CardLayoutItemTag[] => {
        const tags: CardLayoutItemTag[] = [];

        const statusTag = getStatusTag(t, club.clubStatus);

        if (statusTag) tags.push(statusTag);

        const joinedTag = getJoinedTag(t, club.joinStatus);
        const privacyTag = getPrivateTag(t, club.privacy);

        if (joinedTag) {
          tags.push(joinedTag);
        } else if (privacyTag) {
          tags.push(privacyTag);
        }

        const visibilityTag = getVisibilityTag(t, club.visibility);

        if (visibilityTag) tags.push(visibilityTag);

        return tags;
      },
    }),
    [t]
  );
}
