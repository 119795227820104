import { useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Box, InputBase } from '@mui/material';
import {
  ActionCloseSmall as ActionCloseSmallIcon,
  ActionSearch as ActionSearchIcon,
} from '@front/icon';
import { IconButton, TipButton } from '@front/ui';

import { ToolbarButtonItem } from '../types';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  inputBox: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: '0.3s',
    width: 0,
    overflow: 'hidden',
  },
  inputBoxVisible: {
    width: '300px',
    marginLeft: 1,
  },
  input: {
    fontSize: 16,
    '& .MuiInputBase-input': {
      p: 0,
      pr: '28px',
      height: 24,
    },
  },
  clear: {
    position: 'absolute',
    right: 0,
    top: 0,
    visibility: 'hidden',
  },
  clearVisible: {
    visibility: 'visible',
  },
};

type SearchToolbarItemProps = ToolbarButtonItem;

export default function SearchToolbarItem({
  onClick,
  onSearch,
  keyword,
  placeholder,
}: SearchToolbarItemProps) {
  const [inputVisible, setInputVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  const handleOnClick = () => {
    inputRef.current?.focus();
    onClick?.();
  };

  const handleOnChange = (newValue?: string) => {
    onSearch?.(newValue);
  };

  const handleClearClick = () => {
    onSearch?.('');
  };

  useHotkeys('mod+f', (ev) => {
    ev.preventDefault();
    handleOnClick();
  });

  return (
    <Box sx={styles.root}>
      <TipButton title="Search" customSize={24} onClick={handleOnClick}>
        <ActionSearchIcon />
      </TipButton>
      <Box
        sx={[
          styles.inputBox,
          (inputVisible || !!keyword) && styles.inputBoxVisible,
        ]}
      >
        <InputBase
          inputRef={inputRef}
          onFocus={() => setInputVisible(true)}
          onBlur={() => setInputVisible(false)}
          className="top-search-input"
          sx={styles.input}
          placeholder={placeholder || 'Type to search...'}
          onChange={(ev) => handleOnChange(ev.target.value)}
          value={keyword}
        />
        <IconButton
          customSize={24}
          sx={[styles.clear, !!keyword && styles.clearVisible]}
          onClick={handleClearClick}
        >
          <ActionCloseSmallIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
