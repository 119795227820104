import { useCallback } from 'react';
import { useDateFormat } from '@front/helper';
import { ComposerBlock } from '@lib/web/composer';
import { useNotificationMessageContent } from '@lib/web/thread/hooks/message/useNotificationMessageContent';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { streamDateToString } from '@lib/web/thread/utils/streamUtils';
import { extractTextFromHtml } from '@lib/web/utils';
import { isNil } from 'lodash';
import { StreamMessage } from 'stream-chat-react';

import { useRenderThreadMessageToHtml } from './useRenderThreadMessageToHtml';

export const useMessageInformation = () => {
  const { displayDateDivider } = useDateFormat();
  const { renderThreadMessageToHtml } = useRenderThreadMessageToHtml();
  const { getNotificationMessageContent } = useNotificationMessageContent();

  const getMessageInformation = useCallback(
    (
      message: StreamMessage<StreamChatGenerics>,
      currentMessagePageNum?: number
    ) => {
      const customBlocks = message.customBlocks as ComposerBlock[] | undefined;

      const messageCreator = message.user?.id;
      const messageCreatedAt = message.parentMessageCreatedAt
        ? streamDateToString(message.parentMessageCreatedAt)
        : streamDateToString(message.created_at);

      const dateDividerTime = displayDateDivider(messageCreatedAt);

      const currentText =
        (!isNil(currentMessagePageNum) &&
          message.previousText?.[currentMessagePageNum]) ||
        message.text;
      const content = renderThreadMessageToHtml(currentText, customBlocks);
      const notificationMessageContent = getNotificationMessageContent({
        notificationSenderId: messageCreator,
        notificationId: message.customNotificationId,
      });
      const isAgentMessage = !!messageCreator?.startsWith('agent_');
      const agentMessage = `
        <span
          data-render-ui="agentMessageContent"
          data-metadata-message-id=${message.id}
          data-metadata-content="${content}"
          data-metadata-agent-composing-status="${message.agentComposingStatus}"
          data-metadata-agent-material-process-status="${message.agentMaterialProcessStatus}"
          data-metadata-agent-material-file-name="${message.agentMaterialFileName}"
        />`;

      return {
        content: isAgentMessage
          ? agentMessage
          : content || notificationMessageContent.text,
        messageCreator,
        messageCreatedAt,
        dateDividerTime,
        cta: notificationMessageContent.cta,
        plainText: extractTextFromHtml(content),
      };
    },
    [
      displayDateDivider,
      getNotificationMessageContent,
      renderThreadMessageToHtml,
    ]
  );

  return {
    getMessageInformation,
  };
};
