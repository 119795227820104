import { useHotkeys } from 'react-hotkeys-hook';

const useKeyboardShortcutSelect = (
  options: { id: string; key: string }[],
  onSelect: (id: string) => void
) => {
  const keys = options.map((option) => option.key);
  useHotkeys(keys, (ev) => {
    ev.preventDefault();
    const id = options.find(
      (option) => option.key.toLowerCase() === ev.key.toLowerCase()
    )?.id;
    if (id) onSelect(id);
  });
};

export default useKeyboardShortcutSelect;
