import { Box, BoxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { BreadcrumbsValue, Icon, ResponsiveTooltip } from '@front/ui';

const styles = {
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 40px',
  },
  title: {
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr',
    gap: 1,
    fontWeight: 700,
    '& > *:nth-of-type(1)': {
      // icon
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& > *:nth-of-type(2)': {
      // text
      minWidth: 0,
      flex: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};

export type BreadcrumbTitleProps = {
  sx?: BoxProps['sx'];
  breadcrumbsValues: BreadcrumbsValue[];
};
export default function BreadcrumbTitle({
  sx,
  breadcrumbsValues,
}: {
  sx?: BoxProps['sx'];
  breadcrumbsValues: BreadcrumbsValue[];
}) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const lastBreadCrumbItem = breadcrumbsValues[breadcrumbsValues.length - 1];
  if (!mdUp || !lastBreadCrumbItem) return null;

  const renderContent = lastBreadCrumbItem.customizeHeader ? (
    <Typography variant="appH1" sx={styles.title} component="div">
      {lastBreadCrumbItem.customizeHeader}
    </Typography>
  ) : (
    <Typography variant="appH1" sx={styles.title} component="div">
      {typeof lastBreadCrumbItem.icon === 'string' ? (
        <Icon name={lastBreadCrumbItem.icon} width={40} height={40} />
      ) : (
        lastBreadCrumbItem.icon
      )}
      {lastBreadCrumbItem.text}
    </Typography>
  );

  if (lastBreadCrumbItem.tooltip)
    return (
      <ResponsiveTooltip
        titleIcon={
          <Icon name={lastBreadCrumbItem.tooltip.icon} width={16} height={16} />
        }
        title={lastBreadCrumbItem.tooltip.title}
        content={lastBreadCrumbItem.tooltip.content}
        tooltipProps={{
          followCursor: true,
        }}
      >
        <Box sx={sx}>{renderContent}</Box>
      </ResponsiveTooltip>
    );

  return <Box sx={sx}>{renderContent}</Box>;
}
