import React from 'react';
import { useCurrentQuestion } from '@lib/web/editor';

import MultipleResponse from './components/MultipleResponse';
import { useData } from './hooks/useData';
import { useEventHandle } from './hooks/useEventHandle';

export default function EditorAnswerMultipleResponse() {
  const { questionId } = useCurrentQuestion();
  const { handleSelect, handleBlocksChange } = useEventHandle(questionId);
  const {
    responseValues,
    mrqMinCorrectAnswerNum,
    mrqMaxCorrectAnswerNum,
    answerComponents,
  } = useData(questionId);

  return (
    <MultipleResponse
      responseValues={responseValues}
      onSelect={handleSelect}
      handleBlocksChange={(id, blocks) => {
        const component = answerComponents.find((c) => c.id === id);
        if (component) {
          void handleBlocksChange(component, blocks);
        }
      }}
      mrqMinCorrectAnswerNum={mrqMinCorrectAnswerNum}
      mrqMaxCorrectAnswerNum={mrqMaxCorrectAnswerNum}
    />
  );
}
