import { Box } from '@mui/material';
import { AnimateBox } from '@front/ui';
import { AnswerFormatType } from '@lib/web/apis';

import usePracticeQuestion from '../../../../../hooks/usePracticeQuestion';
import AnswerHint from '../../AnswerHint';

import QuizAnswerEssay from './components/QuizAnswerEssay';
import QuizAnswerFreeResponse from './components/QuizAnswerFreeResponse';
import QuizAnswerGridIn from './components/QuizAnswerGridIn';
import QuizAnswerMultipleChoices from './components/QuizAnswerMultipleChoices';
import QuizAnswerTrueFalseNotGiven from './components/QuizAnswerTrueFalseNotGiven';
import QuizAnswerUnscramble from './components/QuizAnswerUnscramble';

const getCreatorOptions = (answerFormatType: AnswerFormatType) => {
  switch (answerFormatType) {
    case AnswerFormatType.MultipleChoice:
      return <QuizAnswerMultipleChoices selectionType="single" />;

    case AnswerFormatType.MultipleResponse:
      return <QuizAnswerMultipleChoices selectionType="multiple" />;

    case AnswerFormatType.GridIn:
      return <QuizAnswerGridIn />;

    case AnswerFormatType.TrueFalseNotGiven:
      return <QuizAnswerTrueFalseNotGiven />;

    case AnswerFormatType.FreeResponse:
      return <QuizAnswerFreeResponse />;

    case AnswerFormatType.Unscramble:
      return <QuizAnswerUnscramble />;

    case AnswerFormatType.Essay:
      return <QuizAnswerEssay />;

    default:
      console.error('not supported answer format type', answerFormatType);
      return null;
  }
};

export default function UserCreateOptions() {
  const { id, answerFormatType } = usePracticeQuestion();

  const creatorOptions = getCreatorOptions(answerFormatType);

  return (
    <AnimateBox variable={id}>
      <AnswerHint />
      <Box
        height={
          [AnswerFormatType.FreeResponse, AnswerFormatType.Essay].includes(
            answerFormatType
          )
            ? 8
            : 16
        }
      />
      {creatorOptions}
    </AnimateBox>
  );
}
