import React, { FocusEvent, ReactNode, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useForkRef } from '@mui/material/utils';
import {
  ActionCloseSmall as ActionCloseSmallIcon,
  ActionSearch as ActionSearchIcon,
} from '@front/icon';

import LoadingIcon from '../../components/LoadingIcon';
import { triggerInput } from '../../utils/dom';

export type SearchBarProps = Omit<InputBaseProps, 'sx'> & {
  sx?: BoxProps['sx'];
  inputSx?: InputBaseProps['sx'];
  loading?: boolean;
  focused?: boolean;
  helperText?: ReactNode;
  blurOnEnter?: boolean;
  suggestText?: string;
  prefixIcon?: ReactNode;
  searchIcon?: ReactNode;
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
  },
  main: {
    position: 'relative',
    borderRadius: 2,

    '& input': { p: 0 },
    '& input::placeholder': {
      opacity: 1,
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    },
    '& .search-icon, & .loading-icon': {
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
      position: 'absolute',
      top: '50%',
      left: 4,
      mt: -1,
      width: 16,
      height: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  inputWrap: {
    width: '100%',
    position: 'relative',
    pl: '32px',
    pr: '30px',
    py: '13px',
  },
  input: {
    width: '100%',
    typography: 'body1',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  suggestWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pl: '32px',
    pr: '30px',
    py: '13px',
    pointerEvents: 'none',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  suggestInner: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    opacity: 0.5,
    typography: 'body1',
    '& span': {
      whiteSpace: 'pre-wrap',
    },
  },
  suggestText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  clearButton: {
    position: 'absolute',
    top: '50%',
    right: 4,
    width: 24,
    height: 24,
    p: 0,
    transform: 'translateY(-50%)',
  },
  hover: {
    '&:not(:disabled):hover': {
      '& input::placeholder': {
        color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
      },
      '& .search-icon, & .loading-icon': {
        color: 'text.primary',
      },
    },
  },
  focused: {
    '& .search-icon, & .loading-icon': {
      color: 'text.primary',
    },
  },
  error: {
    '& .search-icon, & .loading-icon': {
      color: 'error.dark',
    },
    '&:not(:disabled):hover': {
      '& input::placeholder': {
        color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
      },
    },
    '& + .search-helper-text': {
      color: 'error.dark',
    },
  },
  disabled: {
    '& input, & input::placeholder': {
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
    },
    '& .search-icon, & .loading-icon': {
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
    },
  },
};

const getDisplaySuggestText = (suggestText: string, value: unknown) => {
  if (!value) return '';
  if (typeof value !== 'string') {
    return suggestText;
  }
  if (value.length > 17 || value.length < 2) return '';
  if (suggestText.toLowerCase().indexOf(value.toLowerCase()) === 0) {
    return suggestText.slice(value.length);
  }

  return ` — ${suggestText}`;
};
const SearchBar = React.forwardRef(
  (
    {
      sx,
      inputSx,
      disabled = false,
      error = false,
      loading = false,
      focused = false,
      value,
      inputRef: inputRefProp,
      helperText,
      onBlur,
      onFocus,
      onKeyDown,
      blurOnEnter = true, // to get better ux in mobile, this flag will make mobile keyboard disappear when press enter
      suggestText,
      prefixIcon,
      searchIcon,
      ...rest
    }: SearchBarProps,
    ref
  ) => {
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [active, setActive] = useState(false);

    const inputFocused = focused || active;

    const sxProps = Array.isArray(sx) ? sx : [sx];
    const inputSxProps = Array.isArray(inputSx) ? inputSx : [inputSx];
    const inputRef = React.useRef<HTMLInputElement>();
    const handleInputRef = useForkRef(inputRef, inputRefProp);
    const handleClear = () => triggerInput(inputRef.current, '');
    const hasValue = !!value;

    const handleBlur = (
      ev: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    ) => {
      setActive(false);
      onBlur?.(ev);
    };

    const handleFocus = (
      ev: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    ) => {
      setActive(true);
      onFocus?.(ev);
    };

    const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (blurOnEnter && ev.key === 'Enter') {
        ev.currentTarget.blur();
      }
      onKeyDown?.(ev);
    };

    const displaySuggestText = suggestText
      ? getDisplaySuggestText(suggestText, value)
      : '';
    return (
      <Box ref={ref} sx={styles.root}>
        <Box
          sx={[
            styles.main,
            !disabled && !inputFocused && !error && styles.hover,
            inputFocused && styles.focused,
            error && styles.error,
            disabled && styles.disabled,
            ...sxProps,
          ]}
        >
          <Box sx={styles.inputWrap} className="search-input-wrap">
            <InputBase
              inputRef={handleInputRef}
              className="search-input"
              sx={[styles.input, ...inputSxProps]}
              disabled={disabled}
              error={error}
              value={value}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              {...rest}
            />
            {mdUp && displaySuggestText && (
              <Box sx={styles.suggestWrap}>
                <Box sx={styles.suggestInner}>
                  <Box component="span">
                    {typeof value === 'string' && value}
                  </Box>
                  <Box sx={styles.suggestText}>{displaySuggestText}</Box>
                </Box>
              </Box>
            )}
          </Box>
          {loading ? (
            <LoadingIcon />
          ) : prefixIcon && displaySuggestText ? (
            <Box className="search-icon">{prefixIcon}</Box>
          ) : (
            <Box className="search-icon">
              {searchIcon || <ActionSearchIcon width={16} height={16} />}
            </Box>
          )}
          {!!hasValue && !disabled && (
            <IconButton
              sx={styles.clearButton}
              onClick={handleClear}
              className="clear-button"
            >
              <ActionCloseSmallIcon />
            </IconButton>
          )}
        </Box>
        {!!helperText && (
          <Typography className="search-helper-text" variant="caption">
            {helperText}
          </Typography>
        )}
      </Box>
    );
  }
);

export default SearchBar;
