export const enum ChallengeStatus {
  // invited, not accepted, not expired
  Invited = 1,
  // ongoing, accepted, not expired
  Ongoing = 2,
  // expired, invited
  Expired = -1,
  // ongoing, not invited, owned by friend
  OwnedByFriend = 3,
  // ongoing, not invited, owned by friend
  InvitedOrOngoing = 4,
  // accepted, expired or not expired
  Accepted = 5,
  // only for returning, do not use in request or query condition.
  // i.e.no specific user's record in QuizChallengeDetails
  NotInvited = -2,
}

export const enum ChallengeMemberStatus {
  Requested = 1,
  Accepted = 2,
  Practiced = 3,
  Completed = 4,
}

export const enum ChallengeCategoryStatus {
  Invited = 1,
  Ongoing = 2,
  Expired = -1,
  OpenChallenge = 3,
  Accepted = 5,
}

export enum ChallengeFilter {
  NoFilter = 0,
  Completed = 1,
  NotCompleted = -1,
}
