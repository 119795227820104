import React, { useEffect, useMemo } from 'react';
import { getAnswerOptionIdToContentHtmlMap } from '@lib/web/editor';

import AnswerChoiceItems from '../../../../../../../../AnswerChoiceItems';
import usePracticeQuestion from '../../../../../../../hooks/usePracticeQuestion';
import usePracticeQuestionAnswers from '../../../../../../../hooks/usePracticeQuestionAnswers';
import usePracticeQuestionEvent from '../../../../../../../hooks/usePracticeQuestionEvent';
import usePracticeQuestionResult from '../../../../../../../hooks/usePracticeQuestionResult';
import usePracticeQuestionStatus from '../../../../../../../hooks/usePracticeQuestionStatus';

import Unscramble from './components/Unscramble';
import { PracticeUnscrambleValue } from './types';

const useDefaultOptions = () => {
  const { details, creatorQuestionInfo, contentEnricher } =
    usePracticeQuestion();

  return useMemo(() => {
    const answerOptionIdToContentMap = getAnswerOptionIdToContentHtmlMap(
      creatorQuestionInfo?.components
    );

    return details.map((option, index) => {
      return {
        id: option.id,
        symbol: `${index + 1}`,
        text: contentEnricher(
          answerOptionIdToContentMap[option.linkedComponentId]
        ), // for user-create option, the content is the id which link to the corresponding creator detail
      };
    });
  }, [contentEnricher, creatorQuestionInfo?.components, details]);
};

const useCurrentOptions = (defaultOptions: PracticeUnscrambleValue[]) => {
  const { answers: currentAnswers } = usePracticeQuestionAnswers();

  return useMemo(() => {
    if (currentAnswers.length === 0) {
      return defaultOptions;
    }

    const optionIdToOptionMap: Record<string, PracticeUnscrambleValue> =
      defaultOptions.reduce(
        (acc, option) => ({
          ...acc,
          [option.id]: option,
        }),
        {}
      );
    return currentAnswers.map((id) => optionIdToOptionMap[id]);
  }, [currentAnswers, defaultOptions]);
};

export default function QuizAnswerUnscramble() {
  const { onChangeAnswers } = usePracticeQuestionEvent();
  const { isSubmitted } = usePracticeQuestionStatus();
  const { correctAnswerIds } = usePracticeQuestionResult();
  const { answers: currentAnswers } = usePracticeQuestionAnswers();

  const defaultOptions = useDefaultOptions();
  const currentOptions = useCurrentOptions(defaultOptions);

  const handleDragOverOtherItem = ({
    newItems,
  }: {
    newItems: PracticeUnscrambleValue[];
  }) => {
    onChangeAnswers(newItems.map((item) => item.id));
  };

  const handleMoveItem = (fromIndex: number, toIndex: number) => {
    const fromItem = currentOptions[fromIndex];
    const toItem = currentOptions[toIndex];
    const newItems = currentOptions.map((item, index) => {
      if (index === fromIndex) return toItem;
      if (index === toIndex) return fromItem;
      return item;
    });
    onChangeAnswers(newItems.map((item) => item.id));
  };

  useEffect(() => {
    // we make the currentOptions as the answer at beginning because student may want to keep the same order to submit
    if (currentAnswers.length === 0) {
      onChangeAnswers(currentOptions.map((item) => item.id));
    }
  }, [currentAnswers.length, currentOptions, onChangeAnswers]);

  if (!isSubmitted) {
    return (
      <Unscramble
        unscrambleValues={currentOptions}
        onDragOverOtherItem={handleDragOverOtherItem}
        onMoveItem={handleMoveItem}
      />
    );
  }

  return (
    <AnswerChoiceItems>
      {currentOptions.map((option, index) => (
        <AnswerChoiceItems.Item
          key={option.id}
          type={option.id === correctAnswerIds[index] ? 'correct' : 'incorrect'}
          value={option?.text || ''}
          leftComponent={
            <AnswerChoiceItems.OptionCircle symbol={`${option?.symbol}`} />
          }
        />
      ))}
    </AnswerChoiceItems>
  );
}
