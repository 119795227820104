import { RefObject, useContext, useMemo } from 'react';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import config from '../config';
import { BaseLayoutContext, BaseLayoutDispatchContext } from '../contexts';
import {
  BaseLayoutActionTypes,
  BaseLayoutRightPanelParams,
} from '../contexts/type';

export type RightPanelParamObj = Record<
  string,
  BaseLayoutRightPanelParams | undefined
>;
export type RightPanelParamState = Record<string, BaseLayoutRightPanelParams>;

type ValidParams<P> = P extends undefined ? BaseLayoutRightPanelParams : P;

export type RightPanelOptionalArgTuple<
  T extends RightPanelParamObj,
  K extends string
> = Partial<CheckPropTypes<T, K>> extends T[K]
  ? [params?: T[K] | ((st: T[K]) => T[K])]
  : [params: T[K] | ((st: T[K]) => T[K])];

function useBaseRightPanel<
  T extends RightPanelParamObj,
  K extends keyof T & string = keyof T & string,
  P extends ValueOf<T> = ValueOf<T>
>() {
  const {
    rightPanelRef,
    rightPanelParams,
    rightPanelTarget,
    rightPanelOpened,
    rightPanelWidth,
    isResizing,
    rightPanelResizable,
    rightPanelMaximizeWidth,
    rightPanelAutoClose,
    rightPanelHistory,
  } = useContext(BaseLayoutContext);
  const dispatch = useContext(BaseLayoutDispatchContext);

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const memoizedFunction = useMemo(() => {
    function openRightPanel<
      CheckKey extends keyof T & string = keyof T & string
    >(target: CheckKey, ...params: RightPanelOptionalArgTuple<T, CheckKey>) {
      dispatch({
        type: BaseLayoutActionTypes.OPEN_RIGHT_PANEL,
        payload: { target, params: params[0] || {}, isDesktop: lgUp },
      });
    }

    function closeRightPanel() {
      dispatch({
        type: BaseLayoutActionTypes.CLOSE_RIGHT_PANEL,
      });
    }

    function toggleRightPanel<
      CheckKey extends keyof T & string = keyof T & string
    >(target: CheckKey, ...params: RightPanelOptionalArgTuple<T, CheckKey>) {
      dispatch({
        type: BaseLayoutActionTypes.TOGGLE_RIGHT_PANEL,
        payload: { target, params: params[0] || {}, isDesktop: lgUp },
      });
    }

    function setRightPanel(ref: RefObject<Element | null>) {
      dispatch({
        type: BaseLayoutActionTypes.SET_RIGHT_PANEL,
        payload: { ref },
      });
    }

    function setRightParams<
      CheckKey extends keyof T & string = keyof T & string
    >(...params: RightPanelOptionalArgTuple<T, CheckKey>) {
      dispatch({
        type: BaseLayoutActionTypes.SET_RIGHT_PANEL_PARAMS,
        payload: { params: params[0] || {} },
      });
    }

    const clearRightParams = () => {
      dispatch({
        type: BaseLayoutActionTypes.CLEAR_RIGHT_PANEL_PARAMS,
      });
    };

    function setRightPanelWidth(value: number) {
      dispatch({
        type: BaseLayoutActionTypes.SET_RIGHT_PANEL_WIDTH,
        payload: { width: value },
      });
    }

    function resetRightPanelWidth() {
      dispatch({
        type: BaseLayoutActionTypes.SET_RIGHT_PANEL_WIDTH,
        payload: { width: config.rightPanelMinWidth },
      });
    }

    function setResizing(value: boolean) {
      dispatch({
        type: BaseLayoutActionTypes.SET_RESIZING,
        payload: { isResizing: value },
      });
    }

    function setRightPanelResizable(value: boolean) {
      dispatch({
        type: BaseLayoutActionTypes.SET_RIGHT_PANEL_RESIZABLE,
        payload: { rightPanelResizable: value },
      });
    }

    function setRightPanelAutoClose(value: boolean) {
      dispatch({
        type: BaseLayoutActionTypes.SET_RIGHT_PANEL_AUTOCLOSE,
        payload: { rightPanelAutoClose: value },
      });
    }

    function setRightPanelMaximizeWidth(value: boolean) {
      dispatch({
        type: BaseLayoutActionTypes.SET_RIGHT_PANEL_MAXIMIZE_WIDTH,
        payload: { rightPanelMaximizeWidth: value },
      });
    }

    return {
      openRightPanel,
      closeRightPanel,
      toggleRightPanel,
      setRightPanel,
      setRightParams,
      clearRightParams,
      setRightPanelWidth,
      resetRightPanelWidth,
      setResizing,
      setRightPanelResizable,
      setRightPanelAutoClose,
      setRightPanelMaximizeWidth,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lgUp]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function getRightParams<CheckKey extends string>(_key: CheckKey) {
    return rightPanelParams as T[CheckKey];
  }
  function isTargetPanelOpened<CheckKey extends string>(_key: CheckKey) {
    return rightPanelOpened && rightPanelTarget === _key;
  }

  const rightPanelHistoryObj = useMemo(() => {
    function back() {
      dispatch({
        type: BaseLayoutActionTypes.RIGHT_PANEL_HISTORY_BACK,
      });
    }

    function forward() {
      dispatch({
        type: BaseLayoutActionTypes.RIGHT_PANEL_HISTORY_FORWARD,
      });
    }

    function reset() {
      dispatch({
        type: BaseLayoutActionTypes.RIGHT_PANEL_HISTORY_RESET,
      });
    }

    function hasPrevious() {
      if (!rightPanelHistory || !rightPanelHistory.items) return false;
      const currentIndex = rightPanelHistory.currentIndex || 0;
      return currentIndex > 0;
    }

    function hasNext() {
      if (!rightPanelHistory || !rightPanelHistory.items) return false;
      const currentIndex = rightPanelHistory.currentIndex || 0;
      return currentIndex < rightPanelHistory.items.length - 1;
    }

    return {
      back,
      forward,
      reset,
      hasPrevious: hasPrevious(),
      hasNext: hasNext(),
    };
  }, [dispatch, rightPanelHistory]);

  return {
    rightPanelRef,
    rightPanelParams: rightPanelParams as ValidParams<P>,
    rightPanelTarget: rightPanelTarget as K,
    rightPanelOpened,
    rightPanelWidth,
    isResizing,
    rightPanelResizable,
    rightPanelMaximizeWidth,
    rightPanelAutoClose,
    rightPanelHistory: rightPanelHistoryObj,
    ...memoizedFunction,
    getRightParams,
    isTargetPanelOpened,
  };
}

export default useBaseRightPanel;
