import {
  composerHeadingBlockStyle,
  generatedHtmlHeadingBlockStyle,
} from '@lib/web/composer/TextComposer/components/blocks/HeadingBlock';
import {
  composerLegacyParagraphBlockStyle,
  generatedHtmlLegacyParagraphBlockStyle,
} from '@lib/web/composer/TextComposer/components/blocks/LegacyParagraphBlock/styles';
import {
  composerParagraphBlockStyle,
  generatedHtmlParagraphBlockStyle,
} from '@lib/web/composer/TextComposer/components/blocks/ParagraphBlock';
import {
  composerStepBlockStyle,
  generatedHtmlStepBlockStyle,
} from '@lib/web/composer/TextComposer/components/blocks/StepBlock';
import {
  composerSubtitleBlockStyle,
  generatedHtmlSubtitleBlockStyle,
} from '@lib/web/composer/TextComposer/components/blocks/SubtitleBlock';

import { generatedHtmlImageBlockStyle } from '../components/blocks/ImageBlock/styles';
import { generatedHtmlInlineHighlightAnchorStyle } from '../components/blocks/InlineAnchor/InlineHighlightAnchor';
import { generatedHtmlInlineHighlightStyle } from '../components/blocks/InlineHighlight';

export const editorBlockEditingStyles = [
  composerHeadingBlockStyle,
  composerSubtitleBlockStyle(),
  composerParagraphBlockStyle(),
  composerLegacyParagraphBlockStyle,
  composerStepBlockStyle(),
];

export const editorBlockHtmlStyles = [
  generatedHtmlHeadingBlockStyle,
  generatedHtmlSubtitleBlockStyle(),
  generatedHtmlParagraphBlockStyle(),
  generatedHtmlLegacyParagraphBlockStyle,
  generatedHtmlStepBlockStyle(),
  generatedHtmlImageBlockStyle(),
  generatedHtmlInlineHighlightAnchorStyle(),
  generatedHtmlInlineHighlightStyle(),
];
