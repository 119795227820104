import nextId from 'react-id-generator';
import { Box } from '@mui/material';

export type StreakProps = {
  count: number;
};
const styles = {
  root: {
    svg: {
      overflow: 'visible',
    },
  },
};
export default function Streak({ count }: StreakProps) {
  const fireId = nextId('streak-gradient-fire');
  const textId = nextId('streak-gradient-text');
  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  });

  const displayCount = count > 999 ? formatter.format(count) : count;

  return (
    <Box sx={styles.root}>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6667 20.7166C37.6667 22.7042 37.386 24.5262 36.6842 26.3481C35.9825 28.1701 35 29.6608 33.7368 31.1515C32.4737 32.4766 31.0702 33.636 29.386 34.2985C26.1299 35.5413 22.5404 35.7465 19.2807 34.4642C17.5965 33.8016 16.193 32.6422 14.9298 31.3171C13.6667 29.9921 12.6842 28.3357 11.9825 26.5138C11.2807 24.6918 11 22.8698 11 20.8822C11 14.4225 17.4561 8.12844 17.4561 8.12844C17.4561 8.12844 18.0175 13.0974 21.2456 13.0974C27.1404 13.0974 25.0351 2 25.0351 2C25.0351 2 30.3684 5.64394 31.9123 15.5819C34.8596 15.085 34.5789 10.6129 34.5789 10.6129C34.5789 10.6129 37.6667 15.4163 37.6667 20.7166Z"
          fill={`url(#${fireId})`}
        />

        <defs>
          <linearGradient id={fireId} gradientUnits="userSpaceOnUse">
            <stop stopColor="#F90000" />
            <stop offset="1" stopColor="#FFCC44" />
          </linearGradient>
          <linearGradient id={textId} gradientUnits="userSpaceOnUse">
            <stop stopColor="#AF0502" />
            <stop offset="1" stopColor="#B48D30" />
          </linearGradient>
        </defs>

        <text
          x="50%"
          y="40"
          dominantBaseline="auto"
          textAnchor="middle"
          fontFamily="Ubuntu"
          fontSize="24"
          fontWeight="700"
          strokeWidth="10"
          stroke={`url(#${textId})`}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          {displayCount}
        </text>
        <text
          x="50%"
          y="40"
          dominantBaseline="auto"
          textAnchor="middle"
          fontFamily="Ubuntu"
          fontSize="24"
          fontWeight="700"
          fill="white"
        >
          {displayCount}
        </text>
      </svg>
    </Box>
  );
}
