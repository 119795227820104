import { alpha, Palette } from '@mui/material/styles';

import { ButtonColorType, ButtonSizeType, ButtonVariantType } from './types';

type ButtonTheme = {
  variant: ButtonVariantType;
  borderColor: string;
  bgcolor: string;
  color: string;
  hoverBorderColor: string;
  hoverBgcolor: string;
  hoverColor: string;
  activeBorderColor: string;
  activeBgcolor: string;
  activeColor: string;
  loadingBorderColor?: string;
  loadingBgcolor?: string;
  loadingColor?: string;
  disabledBorderColor?: string;
  disabledBgcolor?: string;
  disabledColor?: string;
};

export const getSize = (size?: ButtonSizeType) => {
  if (size === 'md') return { minWidth: 225 };
  if (size === 'lg') return { minWidth: 335 };
  return { minWidth: 155 };
};

export const getIconSize = () => {
  return { minWidth: 36, minHeight: 36, px: 0 };
};

export const getDefaultStyle = (theme: ButtonTheme) => ({
  bgcolor: theme.bgcolor,
  color: theme.color,
  borderColor: theme.borderColor,
  '&.is-disabled': {
    bgcolor: theme.disabledBgcolor,
    color: theme.disabledColor,
    borderColor: theme.disabledBorderColor,
  },
});

export const getHoverStyle = (theme: ButtonTheme) => ({
  '@media (hover: hover)': {
    '&:hover': {
      color: theme.hoverColor,
      background: theme.hoverBgcolor,
      borderColor: theme.hoverBorderColor,
    },
  },
  '&:active': {
    color: theme.activeColor,
    background: theme.activeBgcolor,
    borderColor: theme.activeBorderColor,
  },
});

export const getLoadingStyle = (theme: ButtonTheme) => ({
  color: theme.loadingColor || theme.activeColor,
  background: theme.loadingBgcolor || theme.activeBgcolor,
  borderColor: theme.loadingBorderColor || theme.activeBorderColor,
});

export const getTheme = (
  palette: Palette,
  color: ButtonColorType,
  variant: ButtonVariantType
) => {
  const isDark = palette.mode === 'dark';

  if (variant === 'outlined') {
    switch (color) {
      case 'success': {
        const mainColor = isDark ? palette.success.light : palette.success.dark;
        return {
          variant,
          borderColor: alpha(mainColor, 0.3),
          bgcolor: palette.background.darker,
          color: palette.text.primary,

          hoverBorderColor: mainColor,
          hoverBgcolor: alpha(palette.text.primary, 0.1),
          hoverColor: palette.text.primary,

          activeBorderColor: isDark
            ? alpha(mainColor, 0.3)
            : alpha(mainColor, 0.5),
          activeBgcolor: isDark
            ? alpha(palette.text.primary, 0.05)
            : palette.background.darker,
          activeColor: palette.text.primary,

          disabledColor: alpha(palette.text.primary, 0.5),
        };
      }
      case 'error': {
        const mainColor = palette.error.dark;

        return {
          variant,
          borderColor: alpha(mainColor, 0.3),
          bgcolor: palette.background.darker,
          color: palette.text.primary,

          hoverBorderColor: mainColor,
          hoverBgcolor: alpha(palette.text.primary, 0.1),
          hoverColor: palette.text.primary,

          activeBorderColor: isDark
            ? alpha(mainColor, 0.3)
            : alpha(mainColor, 0.5),
          activeBgcolor: isDark
            ? alpha(palette.text.primary, 0.05)
            : palette.background.darker,
          activeColor: palette.text.primary,

          disabledColor: alpha(palette.text.primary, 0.5),
        };
      }
      default: {
        return {
          variant,
          borderColor: palette.text.primary,
          bgcolor: isDark
            ? alpha(palette.text.primary, 0.1)
            : palette.background.darker,
          color: palette.text.primary,

          hoverBorderColor: alpha(palette.background.darker, 0.3),
          hoverBgcolor: palette.text.primary,
          hoverColor: palette.background.darker,

          activeBorderColor: isDark
            ? 'transparent'
            : alpha(palette.text.primary, 0.1),
          activeBgcolor: alpha(palette.text.primary, 0.5),
          activeColor: palette.background.darker,

          ...(isDark
            ? {
                disabledColor: alpha(palette.text.primary, 0.5),
              }
            : {
                disabledBorderColor: alpha(palette.text.primary, 0.3),
                disabledColor: alpha(palette.text.primary, 0.5),
              }),
        };
      }
    }
  } else {
    switch (color) {
      case 'success': {
        const mainColor = isDark ? palette.success.light : palette.success.dark;
        return {
          variant,
          borderColor: alpha(palette.background.darker, 0.3),
          bgcolor: mainColor,
          color: palette.background.darker,

          hoverBorderColor: alpha(mainColor, 0.15),
          hoverBgcolor: alpha(mainColor, 0.5),
          hoverColor: palette.text.primary,

          activeBorderColor: alpha(palette.background.darker, 0.15),
          activeBgcolor: alpha(mainColor, 0.5),
          activeColor: palette.text.primary,

          loadingColor: palette.background.darker,
          loadingBorderColor: alpha(palette.background.darker, 0.3),
          loadingBgcolor: alpha(mainColor, 0.5),

          disabledColor: palette.background.darker,
          disabledBorderColor: alpha(palette.background.darker, 0.3),
          disabledBgcolor: alpha(mainColor, 0.3),
        };
      }
      case 'error': {
        const mainColor = palette.error.dark;

        return {
          variant,
          borderColor: alpha(palette.text.primary, 0.1),
          bgcolor: mainColor,
          color: palette.background.darker,

          hoverBorderColor: alpha(mainColor, 0.15),
          hoverBgcolor: alpha(mainColor, 0.5),
          hoverColor: palette.text.primary,

          activeBorderColor: alpha(palette.text.primary, 0.1),
          activeBgcolor: alpha(mainColor, 0.5),
          activeColor: palette.text.primary,

          loadingBorderColor: alpha(palette.text.primary, 0.1),
          loadingBgcolor: alpha(mainColor, 0.5),
          loadingColor: palette.background.darker,

          disabledColor: palette.background.darker,
          disabledBorderColor: alpha(palette.text.primary, 0.1),
          disabledBgcolor: alpha(mainColor, 0.3),
        };
      }
      default: {
        return {
          variant,
          borderColor: palette.text.primary,
          bgcolor: palette.text.primary,
          color: palette.background.darker,

          hoverBorderColor: alpha(palette.background.darker, 0.3),
          hoverBgcolor: palette.text.primary,
          hoverColor: palette.background.darker,

          activeBorderColor: alpha(palette.text.primary, 0.1),
          activeBgcolor: isDark
            ? alpha(palette.text.primary, 0.64)
            : alpha(palette.text.primary, 0.5),
          activeColor: palette.background.darker,

          ...(isDark
            ? {
                disabledColor: alpha(palette.background.darker, 0.5),
              }
            : {
                disabledBorderColor: 'transparent',
                disabledBgcolor: alpha(palette.text.primary, 0.1),
                disabledColor: palette.text.primary,
              }),
        };
      }
    }
  }
};
