import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { PlaylistBreadcrumb } from '@app/web/src/layouts/breadcrumbs/BreadcrumbItems';
import getPlaylistsPageConfig from '@app/web/src/layouts/pageConfig/playlists';
import { EditorAddBot as EditorAddBotIcon } from '@front/icon';
import { Icon } from '@front/ui';
import { ParsedUrlQuery } from 'querystring';

const styles = {
  prompts: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 1,
  },
};

function PromptsBreadcrumb() {
  const { t } = useTranslation(['profile', 'agent']);

  return (
    <>
      <Box>
        <EditorAddBotIcon />
      </Box>
      <Box sx={styles.prompts}>
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {t('breadcrumb::agent.create.Shortcuts')}
        </Typography>
      </Box>
    </>
  );
}

export const getAllBreadcrumbsValues = (
  _pathname: string,
  query: ParsedUrlQuery,
  t: any
) => {
  const clubSlug = query.slug;
  const prefix = query.agentId
    ? `/club/${clubSlug}/agent/form/${query.agentId}`
    : `/club/${clubSlug}/agent/form`;
  const playlistsConfig = getPlaylistsPageConfig(t);

  return {
    settings: {
      text: t('breadcrumb::agent.create.Settings'),
      icon: 'ProfileSetting',
      href: `${prefix}/settings`,
    },
    aiModel: {
      text: t('breadcrumb::agent.create.AiModel'),
      icon: 'AIEditorLLM',
      href: `${prefix}/ai-model`,
    },
    prompts: {
      customizeComponent: <PromptsBreadcrumb />,
      href: `${prefix}/prompts`,
    },
    dataSourcePlaylists: {
      text: t('breadcrumb::agent.create.Playlists', 'Playlists'),
      icon: 'AIEditorPlaylist',
      href: `${prefix}/data-source/playlists`,
    },
    dataSourceQuestions: {
      text: t('breadcrumb::agent.create.Questions', 'Questions'),
      icon: 'AIEditorQuestion',
      href: `${prefix}/data-source/questions`,
    },
    dataSourcePlaylistsSource: {
      customizeHeader: (
        <PlaylistBreadcrumb
          icon={
            <Icon
              name={playlistsConfig.historyDetail.icon}
              width={40}
              height={40}
            />
          }
          quizId={(query.sourceId || '') as string}
        />
      ),
      customizeComponent: (
        <PlaylistBreadcrumb
          icon={<Icon name={playlistsConfig.historyDetail.icon} />}
          quizId={(query.sourceId || '') as string}
        />
      ),
    },
  };
};
