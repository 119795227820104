import api from './client';

const threadApi = {
  getToken: () =>
    api.post<Response<{ token: string }>>(`/api/thread/token`, undefined, {
      baseURL: '',
    }),
  setThreadView: (params: SetThreadViewReq) =>
    api.post<Response<GetThreadViewRes>>(
      '/notification/v1/thread-view',
      params
    ),
  deleteThreadView: (params: DeleteThreadViewReq) =>
    api.delete<Response<null>>('/notification/v1/thread-view', {
      data: params,
    }),
  duplicateThreadView: (params: DuplicateThreadViewReq) =>
    api.post<Response<GetThreadViewRes>>(
      '/notification/v1/thread-view/duplicate',
      params
    ),
  requireUpgrade: (params: RequireUpgradeReq) =>
    api.post<Response<unknown>>(`/api/thread/cta/require-upgrade`, params, {
      baseURL: '',
    }),
  completeUpgrade: () =>
    api.post<Response<unknown>>(`/api/thread/cta/complete-upgrade`, null, {
      baseURL: '',
    }),
  inviteMember: (params: InvitedMemberReq) =>
    api.post<Response<InviteMemberRes>>(
      `/api/thread/action/invite-member`,
      params,
      {
        baseURL: '',
      }
    ),
  removeMember: (params: RemoveMemberReq) =>
    api.post<Response<RemoveMemberRes>>(
      `/api/thread/action/remove-member`,
      params,
      {
        baseURL: '',
      }
    ),
  mentionAi: (params: MentionAiReq) =>
    api.post<Response<null>>(`/api/thread/action/mention-ai`, params, {
      baseURL: '',
    }),
  mentionUsers: (params: MentionUserReq) =>
    api.post<Response<null>>(`/api/thread/action/mention-user`, params, {
      baseURL: '',
    }),
  openChildChannel: (params: OpenChildChannelReq) =>
    api.post<Response<OpenChildChannelRes>>(
      `/api/thread/action/open-child-channel`,
      params,
      {
        baseURL: '',
      }
    ),
  systemMessageMemberInvited: (params: MemberInvitedReq) =>
    api.post<Response<null>>(
      `/api/thread/system-message/member-invited`,
      params,
      {
        baseURL: '',
      }
    ),
  systemMessageAgentStatusError: (params: AgentStatusErrorReq) =>
    api.post<Response<null>>(
      '/api/thread/system-message/agent-status-error',
      params,
      {
        baseURL: '',
      }
    ),
  maybeUpdateDmThreadView: (params: MaybeUpdateDmThreadViewReq) =>
    api.post<Response<MaybeUpdateDmThreadViewRes>>(
      `/api/thread/action/maybe-update-dm-thread-view`,
      params,
      {
        baseURL: '',
      }
    ),
};

export default threadApi;
