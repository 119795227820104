import React, { useEffect, useRef } from 'react';
import { isBrowser } from 'react-device-detect';
import { useTranslation } from 'next-i18next';
import SpaceBarRoundedIcon from '@mui/icons-material/SpaceBarRounded';
import { Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { timeFormat, useTimer } from '@front/helper';
import {
  OtherPause as OtherPauseIcon,
  OtherPlay as OtherPlayIcon,
} from '@front/icon';
import { IconButton } from '@front/ui';

import usePracticeFlowEvent from '../../../hooks/usePracticeFlowEvent';
import usePracticeQuestion from '../../../hooks/usePracticeQuestion';
import usePracticeQuestionStatus from '../../../hooks/usePracticeQuestionStatus';

const mobileStyles = {
  timer: {
    px: 2.5,
    display: 'flex',
    alignItems: 'center',
  },
  timerText: {
    height: 36,
    display: 'flex',
    alignItems: 'center',
  },
  bar: {
    height: 4,
    position: 'relative',
    overflow: 'hidden',
  },
  barInner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'background.default',
    transform: 'translateX(100%)',
  },
};

const desktopStyles = {
  root: {
    position: 'relative',
    background: (theme: Theme) => theme.palette.background.body,
    backgroundSize: '100vw',
    backgroundAttachment: 'fixed',
    minHeight: 60,
  },
  inner: {
    px: 2.5,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  bar: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 2,
    overflow: 'hidden',
  },
  barInner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'background.default',
    transform: 'translateX(100%)',
  },
  timerAction: {
    ml: 'auto',
    display: 'flex',
    alignItems: 'inherit',
  },
  iconButton: {
    border: '3px solid',
    borderColor: 'primary.light',
  },
  pressText: {
    display: 'flex',
    alignItems: 'center',
    color: 'text.secondary',
    px: 1,
  },
  spaceText: {
    color: 'text.primary',
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      mt: 0.2,
    },
  },
};

type TimerProps = {
  startAt: number | null;
  isSubmitted: boolean;
  isLoading: boolean;
  isPendingSubmit: boolean;
  timeSpent: number;
  tpq: number;
  pauseQuiz: () => void;
  resumeQuiz: () => void;
};

function MobileTimer({
  startAt,
  isSubmitted,
  pauseQuiz,
  resumeQuiz,
  isLoading,
  isPendingSubmit,
  timeSpent,
  tpq,
}: TimerProps) {
  const theme = useTheme();
  const { t } = useTranslation('quiz');

  const maskRef = useRef<HTMLDivElement>(null);
  const specialSize = useMediaQuery(theme.breakpoints.down(375));

  const updateProgressBar = (milliseconds = 0) => {
    if (!maskRef.current) return;

    const elapsed = timeSpent + milliseconds;

    const total = tpq * 1000;

    if (elapsed < total * 2) {
      const pct =
        elapsed <= total ? 1 - elapsed / total : (elapsed - total) / total;
      maskRef.current.style.transform = `translateX(${pct * 100}%)`;
    } else {
      maskRef.current.style.transform = 'translateX(100%)';
    }
  };

  const passSeconds = useTimer(startAt, (milliseconds: number) => {
    if (startAt) updateProgressBar(milliseconds);
  });

  useEffect(() => {
    if (startAt === null) {
      updateProgressBar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAt]);

  const passTime = passSeconds + timeSpent / 1000;
  const isOvertime = passTime >= tpq;
  const background = isOvertime
    ? theme.palette.gradient.warning
    : theme.palette.gradient.primary;

  const restTime = tpq - passTime;

  return (
    <Box>
      <Box sx={mobileStyles.timer}>
        {!isSubmitted && (
          <IconButton
            onClick={startAt ? pauseQuiz : resumeQuiz}
            disabled={isLoading || isPendingSubmit || !tpq}
            customSize={24}
          >
            {startAt ? <OtherPauseIcon /> : <OtherPlayIcon />}
          </IconButton>
        )}
        <Typography
          variant="caption"
          sx={mobileStyles.timerText}
          color={isOvertime ? 'warning.main' : 'text.primary'}
        >
          {timeFormat(restTime)}
          <Typography variant="caption" color="grey.400">
            &nbsp;/&nbsp;{timeFormat(tpq)}
          </Typography>

          {!isSubmitted && restTime <= 10 && restTime > 0 && (
            <Typography
              variant="caption"
              display="inline-block"
              ml="14px"
              color="warning.main"
            >
              {specialSize
                ? t('Hurry up')
                : t('Hurry up You are running out of time')}
            </Typography>
          )}
        </Typography>
      </Box>
      <Box sx={[mobileStyles.bar, { background }]}>
        <Box ref={maskRef} sx={mobileStyles.barInner} />
      </Box>
    </Box>
  );
}

interface KeyboardHintProps {
  startAt: number | null;
}

const KeyboardHint = ({ startAt }: KeyboardHintProps) => {
  if (!isBrowser) return null;

  return (
    <Typography variant="caption" sx={desktopStyles.pressText} component="div">
      Press&nbsp;
      <Typography
        variant="caption"
        sx={desktopStyles.spaceText}
        component="span"
      >
        <SpaceBarRoundedIcon /> space
      </Typography>
      &nbsp;{`to ${startAt ? 'pause' : 'resume'}`}
    </Typography>
  );
};

function DesktopTimer({
  startAt,
  isSubmitted,
  pauseQuiz,
  resumeQuiz,
  isLoading,
  isPendingSubmit,
  timeSpent,
  tpq,
}: TimerProps) {
  const { t } = useTranslation('quiz');

  const theme = useTheme();

  const maskRef = useRef<HTMLDivElement>(null);

  const handleToggleTimer = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.currentTarget.blur();

    if (startAt === null) {
      resumeQuiz();
    } else {
      pauseQuiz();
    }
  };

  const updateProgressBar = (milliseconds = 0) => {
    if (!maskRef.current) return;

    const elapsed = timeSpent + milliseconds;

    const total = tpq * 1000;

    if (elapsed < total * 2) {
      const pct =
        elapsed <= total ? 1 - elapsed / total : (elapsed - total) / total;
      maskRef.current.style.transform = `translateX(${pct * 100}%)`;
    } else {
      maskRef.current.style.transform = 'translateX(100%)';
    }
  };

  const passSeconds = useTimer(startAt, (milliseconds: number) => {
    if (startAt) updateProgressBar(milliseconds);
  });

  const passTime = passSeconds + timeSpent / 1000;

  const restTime = tpq - passTime;

  const isOvertime = passTime >= tpq;

  const background = isOvertime
    ? theme.palette.gradient.warning
    : theme.palette.gradient.primary;

  useEffect(() => {
    if (startAt === null) {
      updateProgressBar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAt]);

  return (
    <Box sx={desktopStyles.root}>
      <Box sx={[desktopStyles.bar, { background }]}>
        <Box ref={maskRef} sx={desktopStyles.barInner} />
      </Box>
      <Box sx={desktopStyles.inner}>
        <Typography
          variant="caption"
          color={isOvertime ? 'warning.main' : 'text.primary'}
        >
          {timeFormat(restTime)}
          <Typography variant="caption" color="grey.400">
            &nbsp;/&nbsp;{timeFormat(tpq)}
          </Typography>
          {!isSubmitted && restTime <= 10 && restTime > 0 && (
            <Typography
              variant="caption"
              display="inline-block"
              ml={2}
              color="warning.main"
            >
              {t('Hurry up You are running out of time')}
            </Typography>
          )}
        </Typography>

        {!isSubmitted && (
          <Box sx={desktopStyles.timerAction}>
            <KeyboardHint startAt={startAt} />
            <IconButton
              sx={desktopStyles.iconButton}
              onClick={handleToggleTimer}
              disabled={isLoading || isPendingSubmit || !tpq}
            >
              {startAt ? <OtherPauseIcon /> : <OtherPlayIcon />}
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default function Timer() {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { onPauseQuestion, onResumeQuestion } = usePracticeFlowEvent();
  const { tpq } = usePracticeQuestion();
  const { startAt, timeSpent, isLoading, isSubmitted, isPendingSubmit } =
    usePracticeQuestionStatus();

  const props = {
    startAt,
    isSubmitted,
    pauseQuiz: () => {
      onPauseQuestion();
    },
    resumeQuiz: () => {
      onResumeQuestion();
    },
    isLoading,
    isPendingSubmit,
    timeSpent,
    tpq,
  };

  if (mdUp) return <DesktopTimer {...props} />;
  return <MobileTimer {...props} />;
}
