import React from 'react';
import { Box } from '@mui/material';
import {
  OtherCheckedSquareSolid as OtherCheckedSquareSolidIcon,
  OtherCheckSquareDash as OtherCheckSquareDashIcon,
  OtherCheckSquareOutline as OtherCheckSquareOutlineIcon,
} from '@front/icon';
import { SideFloatingCheckboxProps } from '@lib/ia/src/components/SideFloatingCheckbox';

import { TableLayoutCheckboxCell, TableLayoutRow } from '../../types';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
};
export type IaCheckboxCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutCheckboxCell;
  columnKey: string;
  checkbox: {
    state: SideFloatingCheckboxProps['state'];
    onSelect: () => void;
  };
};

export default function IaCheckboxCell({
  checkbox: { state, onSelect },
}: IaCheckboxCellProps) {
  const handleOnClick = () => {
    onSelect();
  };

  const checked = state === 'checked';
  const partial = state === 'partial';
  const unchecked = state === 'unchecked';

  return (
    <Box sx={styles.root} onClick={handleOnClick}>
      {checked && <OtherCheckedSquareSolidIcon width={16} height={16} />}
      {partial && <OtherCheckSquareDashIcon width={16} height={16} />}
      {unchecked && <OtherCheckSquareOutlineIcon width={16} height={16} />}
    </Box>
  );
}
