import MultipleResponse from './components/MultipleResponse';
import { useData } from './hooks/useData';
import { useEventHandle } from './hooks/useEventHandle';

export default function EditorSolutionMultipleResponse() {
  const { solutionValues, answerSolutionComponents } = useData();
  const { handleBlocksChange } = useEventHandle();

  return (
    <MultipleResponse
      solutionValues={solutionValues}
      handleBlocksChange={(id, blocks) => {
        const component = answerSolutionComponents.find((c) => c.id === id);
        if (component) {
          void handleBlocksChange(component, blocks);
        }
      }}
    />
  );
}
