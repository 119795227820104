import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';

import { MarkViewSlug } from '../../types/enums';

import generateHook, {
  generateInfiniteHook,
  SwrHelperParams,
} from './swr.helper';

export type GetIaMarkResMap = {
  [MarkViewSlug.IconRhsIAMarkDefault]: GetIaMarkRhsDefaultViewRes;
  [MarkViewSlug.IconRhsIAMarkRecently]: GetIaMarkRhsRecentlyViewRes;
};

export const useMarkEmojis = () =>
  generateHook<GetMarkEmojiRes>('v2/mark/emoji-static?page=1&limit=1000', {
    immutable: true,
    paginate: true,
  });

export function useIaMarkList<
  K = GetIaMarkDefaultRes,
  T extends MarkViewSlug | undefined = undefined
>(
  query: Omit<IaSearchReq<T>, 'page' | 'pageSize'> = {},
  config: SwrHelperParams = {}
) {
  const limit = query.limit || 0;

  return generateInfiniteHook<T extends MarkViewSlug ? GetIaMarkResMap[T] : K>(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/mark?${getIaQueryString({
            ...query,
            page: index + 1,
            limit,
          })}`,
    limit,
    { auth: true }
  );
}
