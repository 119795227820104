import {
  Fragment,
  MouseEvent,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';
import { Box, ListItemIcon, Typography } from '@mui/material';
import {
  ActionChevronLeftSmall as ActionChevronLeftSmallIcon,
  ActionChevronRightSmall as ActionChevronRightSmallIcon,
} from '@front/icon';
import { IconButton, ResponsiveDropdown, SimpleTooltip } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { MessageItemEvent } from '@lib/ia/src/layouts/ChannelLayout/types';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Ubuntu',
    fontSize: 12,
  },
  actionList: {
    display: 'flex',
  },
  icon: {
    minWidth: 'unset',
  },
  popper: {
    zIndex: 1300,
  },
  optionContainer: {
    display: 'grid',
    gridTemplateColumns: '16px 1fr',
    gap: 1,
    alignItems: 'center',
    width: 'max-content',
  },
  optionIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'start',
    py: 0.5,
  },
  optionTitle: {
    fontSize: 14,
  },
  optionDescription: {
    fontSize: 12,
    color: 'alpha.lightA50',
  },
};

type RenderOptionProps = {
  display: ReactNode;
  iconName: string;
};

const RenderOption = ({
  display,
  iconName,
}: RenderOptionProps): JSX.Element => {
  return (
    <Box sx={styles.optionContainer}>
      <Typography component="span" sx={styles.optionIcon}>
        <Icon name={iconName} width={16} height={16} />
      </Typography>
      <Typography component="div">{display}</Typography>
    </Box>
  );
};

export type ButtonDropdownOption = {
  display: ReactNode;
  iconName: string;
  value: string;
};

type DropdownOption = {
  title: string;
  description: string;
  value: string;
  iconName: string;
};

type ActionEventsMetadata = {
  dropdownOptions?: DropdownOption[];
};

export type MessageItemActionBarProps = {
  id?: string;
  events: MessageItemEvent<ActionEventsMetadata>[];
  paginationInfo?: {
    messageList: string[];
    currentMessageIndex?: number;
  };
};

export default function ActionBar({
  id,
  events = [],
  paginationInfo,
}: MessageItemActionBarProps) {
  const { getIaAction, iaRoutingAction } = useIaAction();

  const dropdownButtonRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(
    null
  );

  const handleEventActionClick = useCallback(
    (event: MessageItemEvent, index: number): void => {
      if (event.type === 'link') {
        iaRoutingAction(event.value);
      } else {
        if (!event.metadata?.dropdownOptions?.length) {
          const onClickAction = getIaAction<string>(event.value);
          onClickAction?.action?.(id, event.metadata);
        } else {
          setOpenDropdownIndex(index);
        }
      }
    },
    [getIaAction, iaRoutingAction, id]
  );

  const prevPageEvent = events.find((item) => item.value === 'prevMessagePage');
  const nextPageEvent = events.find((item) => item.value === 'nextMessagePage');

  const showPagination =
    events.length > 0 &&
    !!paginationInfo?.messageList &&
    paginationInfo.messageList.length > 1;

  const onClickPagination = (e: MouseEvent, action: 'prev' | 'next'): void => {
    e.stopPropagation();
    e.preventDefault();

    if (action === 'prev') {
      const onClickAction = getIaAction<string>(prevPageEvent?.value);
      onClickAction?.action?.(id, prevPageEvent?.metadata);
    }
    if (action === 'next') {
      const onClickAction = getIaAction<string>(nextPageEvent?.value);
      onClickAction?.action?.(id, nextPageEvent?.metadata);
    }
  };

  const totalPageNum = paginationInfo?.messageList.length;
  const currentPageIndex = (paginationInfo?.currentMessageIndex ?? 0) + 1;

  const showActionBar = events && events.length > 0;

  const handleDropdownClick = useCallback(
    (option: ButtonDropdownOption, e: MouseEvent): void => {
      const optionIaAction = getIaAction<MouseEvent<Element>>(option.value);
      optionIaAction?.action(e);
      setOpenDropdownIndex(null);
    },
    [getIaAction]
  );

  const handleDropdownClose = useCallback((): void => {
    setOpenDropdownIndex(null);
  }, []);

  const combineDropdownOptions = useCallback(
    (actionEvents: DropdownOption[]): ButtonDropdownOption[] => {
      return actionEvents.map((event) => ({
        display: (
          <>
            <Typography component="div" sx={styles.optionTitle}>
              {event.title}
            </Typography>
            <Typography component="div" sx={styles.optionDescription}>
              {event.description}
            </Typography>
          </>
        ),
        iconName: event.iconName,
        value: event.value,
      }));
    },
    []
  );

  return (
    <Box sx={styles.root}>
      {showPagination && (
        <Box sx={styles.pagination}>
          <IconButton
            customSize={24}
            onClick={(e) => onClickPagination(e, 'prev')}
            disabled={paginationInfo?.currentMessageIndex === 0}
          >
            <ActionChevronLeftSmallIcon />
          </IconButton>
          {`${currentPageIndex} / ${totalPageNum}`}
          <IconButton
            customSize={24}
            onClick={(e) => onClickPagination(e, 'next')}
            disabled={
              paginationInfo?.currentMessageIndex ===
              paginationInfo?.messageList.length - 1
            }
          >
            <ActionChevronRightSmallIcon />
          </IconButton>
        </Box>
      )}

      {showActionBar && (
        <Box sx={styles.actionList}>
          {events.map(
            (event, index) =>
              event.icon && (
                <Fragment key={`${event.type}-${event.value}`}>
                  <ListItemIcon
                    ref={(el: HTMLDivElement) => {
                      dropdownButtonRefs.current[index] = el;
                    }}
                    sx={styles.icon}
                  >
                    <SimpleTooltip title={event.text} placement="top">
                      <IconButton
                        customSize={24}
                        disabled={event.disabled}
                        onClick={() => handleEventActionClick(event, index)}
                      >
                        <Icon name={event.icon} />
                      </IconButton>
                    </SimpleTooltip>
                  </ListItemIcon>

                  {event.metadata?.dropdownOptions?.length && (
                    <ResponsiveDropdown
                      open={openDropdownIndex === index}
                      options={combineDropdownOptions(
                        event.metadata.dropdownOptions
                      )}
                      onClick={handleDropdownClick}
                      onClose={handleDropdownClose}
                      menuDropdownProps={{
                        anchorEl: dropdownButtonRefs.current[index],
                        popperProps: { sx: styles.popper },
                      }}
                      renderOption={(ev) => (
                        <RenderOption
                          display={ev.display}
                          iconName={ev.iconName}
                        />
                      )}
                    />
                  )}
                </Fragment>
              )
          )}
        </Box>
      )}
    </Box>
  );
}
