import filterReducer from '@lib/ia/src/filter/reducers/filterReducer';
import sortReducer from '@lib/ia/src/sort/reducers/sortReducer';
import { combineReducers } from 'redux';

import blockerReducer from './blockerReducer';
import iaLayoutReducer from './iaLayoutReducer';
import iaQueryHelperReducer from './iaQueryHelperReducer';
import layoutReducer from './layoutReducer';
import quizReducer from './quizReducer';
import searchReducer from './searchReducer';

const rootReducer = combineReducers({
  layout: layoutReducer,
  blocker: blockerReducer,
  iaLayout: iaLayoutReducer,
  quiz: quizReducer,
  sort: sortReducer,
  filter: filterReducer,
  search: searchReducer,
  iaQueryHelper: iaQueryHelperReducer,
});

export default rootReducer;
