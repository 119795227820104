import { useCallback, useMemo } from 'react';
import useMeasure from 'react-use-measure';
import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { ResizeObserver } from '@juggle/resize-observer';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';
import * as d3Selection from 'd3-selection';

const shortName = (name: string, maxLength = 8) => {
  if (name.length <= maxLength) return name;
  return `${name.slice(0, maxLength)}...`;
};

const styles = {
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',

    '.x-axis': {
      typography: 'caption',
      color: 'grey.300',
      path: {
        color: 'alpha.lightA10',
      },
      line: {
        color: 'alpha.lightA10',
      },
    },
    '.y-axis': {
      typography: 'body1',
    },
    '.bars': {
      typography: 'caption',
      fontWeight: 700,
    },
  },
  svg: {
    width: '100%',
    height: '100%',
  },
};

export type BarChartDataItem = {
  label: string;
  value: number;
};

export type BarChartProps = Omit<BoxProps, 'children'> & {
  data: BarChartDataItem[];
  color?: string;
};

const BAR_HEIGHT = 24;
const BAR_PADDING = 16;

const Y_AXIS_WIDTH = 100;
const X_AXIS_HEIGHT = 30;
const BUFFER_FOR_X_AXIS_LABEL = 16;

const PADDING = {
  top: 0,
  right: BUFFER_FOR_X_AXIS_LABEL,
  bottom: X_AXIS_HEIGHT,
  left: Y_AXIS_WIDTH,
};

export default function BarChart({
  sx,
  data,
  color = '#FF2951',
  ...rest
}: BarChartProps) {
  const { palette } = useTheme();
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const [ref, { width }] = useMeasure({ polyfill: ResizeObserver });

  const barsHeight = useMemo(() => {
    return BAR_HEIGHT * data.length + BAR_PADDING * (data.length - 1);
  }, [data]);

  const yScale = useMemo(() => {
    return d3Scale
      .scaleBand()
      .domain(data.map((d) => shortName(d.label)))
      .range([PADDING.top, PADDING.top + barsHeight])
      .paddingInner(0.4)
      .paddingOuter(0);
  }, [data, barsHeight]);

  const xScale = useMemo(() => {
    const maxValue = d3Array.max(data, (d) => d.value) || 0;
    return d3Scale
      .scaleLinear()
      .domain([0, maxValue])
      .nice()
      .range([PADDING.left, width - PADDING.right]);
  }, [data, width]);

  const chartData = useMemo(() => {
    return data.map((d) => ({
      ...d,
      y: yScale(shortName(d.label)) || 0,
      x: xScale(0),
      width: xScale(d.value) - xScale(0),
      height: yScale.bandwidth(),
    }));
  }, [data, yScale, xScale]);

  const xAxisTickValues = useMemo(() => {
    const maxValue = xScale.domain()[1];
    if (maxValue < 4) return xScale.ticks(maxValue);
    return [0, maxValue / 3, (2 * maxValue) / 3, maxValue];
  }, [xScale]);

  const xAxis = useMemo(() => {
    return d3Axis
      .axisBottom(xScale)
      .tickValues(xAxisTickValues)
      .tickFormat(d3Format.format('.2~s'))
      .tickPadding(16);
  }, [xScale, xAxisTickValues]);

  const yAxis = useMemo(
    () => d3Axis.axisLeft(yScale).tickSize(0).tickPadding(8),
    [yScale]
  );

  const xAxisRef = useCallback(
    (node: SVGGElement | null) => {
      if (node) d3Selection.select(node).attr('class', 'x-axis').call(xAxis);
    },
    [xAxis]
  );

  const yAxisRef = useCallback(
    (node: SVGGElement | null) => {
      if (node)
        d3Selection
          .select(node)
          .attr('class', 'y-axis')
          .call(yAxis)
          .selectAll('path')
          .remove();
    },
    [yAxis]
  );

  return (
    <Box ref={ref} sx={[styles.root, ...sxProps]} {...rest}>
      <svg style={styles.svg}>
        {xAxisTickValues.map((tick, i) => (
          <line
            key={i}
            x1={xScale(tick)}
            y1={PADDING.top}
            x2={xScale(tick)}
            y2={PADDING.top + barsHeight + 8}
            stroke={palette.alpha.lightA10}
            strokeDasharray={i !== 0 ? '4,2' : undefined}
          />
        ))}
        <g
          transform={`translate(0,${PADDING.top + barsHeight + 8})`}
          ref={xAxisRef}
        />
        <g transform={`translate(${PADDING.left},0)`} ref={yAxisRef} />

        <g className="bars">
          {chartData.map((d, i) => (
            <g key={i}>
              <rect
                x={d.x}
                y={d.y}
                rx={4}
                width={d.width}
                height={d.height}
                fill={color}
              />
              <text x={d.x + 8} y={d.y + d.height / 2} dy=".35em" fill="white">
                {d.value}
              </text>
            </g>
          ))}
        </g>
      </svg>
    </Box>
  );
}
