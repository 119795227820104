import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';
import {
  AgentPromptViewSlug,
  AgentUserViewSlug,
  AgentViewSlug,
  AiModelViewSlug,
  generateInfiniteHook,
  generateMockInfiniteHook,
  mockAgentPrompts,
  mockAgents,
  SwrHelperParams,
} from '@lib/web/apis';

export type GetIaAgentResMap = {
  [AgentViewSlug.ProfileRhsAgentDefault]: GetIaAgentProfileRhsAgentDefaultRes;
  [AgentViewSlug.ProfileCenterAgentDefault]: GetIaAgentProfileCenterAgentDefaultRes;
  [AgentViewSlug.CardCenterAgentDefault]: GetIaAgentCardCenterAgentDefaultRes;
  [AgentViewSlug.SettingAgentDefault]: GetIaAgentSettingAgentDefaultRes;
  [AgentViewSlug.ThreadViewAgentDefault]: GetIaAgentThreadViewAgentDefaultRes;
};

type GetIaAgentUserResMap = {
  [AgentUserViewSlug.ListRhsAgentUserDefault]: GetIaAgentUserIconRhsAgentUserDefaultRes;
  [AgentUserViewSlug.SearchAgentUserDefault]: GetIaAgentUserSearchAgentUserDefaultRes;
  [AgentUserViewSlug.ThreadComposerUserDefault]: GetIaAgentUserThreadComposerDefaultRes;
  [AgentUserViewSlug.ThreadMentionAgentDefault]: GetIaAgentUserThreadMentionAgentDefaultRes;
};

export type GetIaAgentPromptResMap = {
  [AgentPromptViewSlug.TableCenterPromptDefault]: GetIaAgentPromptTableCenterPromptDefaultRes;
};

export type GetIaAiModelResMap = {
  [AiModelViewSlug.CardCenterLlmDefault]: GetIaAiModelCardCenterLlmDefaultRes;
};

export function patchIaAgentQuery<T>(query: IaSearchReq<T>): IaSearchReq<T> {
  let display: string | undefined = query.display;
  let filter: string | undefined = query.filter;
  let expand: string | undefined = query.expand;

  // XXX: some fields should be in view, but backend doesn't return them, so we add it by ourselves
  if (query.viewSlug === AgentViewSlug.ProfileRhsAgentDefault) {
    display = 'agentId;visibility';
  }

  if (query.viewSlug === AgentViewSlug.SettingAgentDefault) {
    filter =
      'agentId;agentClub;agentCreator;agentName;agentUserName;agentPhoto;shortDesc;longDesc;instruction;threadCount;userCount;permission;status;aiModel';
    expand = 'agentClub:clubId,clubName,clubLogo';
    delete query.viewSlug;
  }

  if (query.viewSlug === AgentViewSlug.CardCenterAgentDefault) {
    filter = 'permission;agentCreator';
  }

  if (query.viewSlug === AgentViewSlug.ThreadViewAgentDefault) {
    filter = 'agentId;agentName;agentUserName;agentPhoto;agentClub';
    delete query.viewSlug;
  }

  return {
    ...query,
    display,
    filter,
    expand,
  };
}

export function useAgents<
  K = GetIaAgentDefaultRes,
  T extends AgentViewSlug | undefined = undefined
>(
  query: Omit<IaSearchReq<T>, 'page' | 'pageSize'> = {},
  config: SwrHelperParams = {}
) {
  const limit = query.limit || 0;

  return generateInfiniteHook<
    T extends AgentViewSlug ? GetIaAgentResMap[T] : K
  >(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/agents?${getIaQueryString({
            ...patchIaAgentQuery(query),
            page: index + 1,
            limit,
          })}`,
    limit,
    {
      auth: true,
    }
  );
}

export function useAgentUsers<
  K = GetIaAgentUserDefaultRes,
  T extends AgentUserViewSlug | undefined = undefined
>(
  query: Omit<IaSearchReq<T>, 'page' | 'pageSize'> = {},
  config: SwrHelperParams = {}
) {
  const limit = query.limit || 0;
  let expand: string | undefined = query.expand;
  let filter: string | undefined = query.filter;

  if (query.viewSlug === AgentUserViewSlug.ListRhsAgentUserDefault) {
    expand =
      'agent:agentId,agentName,shortDesc,agentCreator,permission,agentPhoto;agentClub:clubSlug';
    filter = 'agentClub';
  }

  if (query.viewSlug === AgentUserViewSlug.SearchAgentUserDefault) {
    expand = 'agent:agentId,agentName,shortDesc,agentPhoto';
    filter = 'agentUserId;agent';
    delete query.viewSlug;
  }

  if (query.viewSlug === AgentUserViewSlug.ThreadComposerUserDefault) {
    expand = 'agent:agentId,agentName,shortDesc,agentPhoto';
    filter = 'agent';
    delete query.viewSlug;
  }

  if (query.viewSlug === AgentUserViewSlug.ThreadMentionAgentDefault) {
    expand = 'agent:agentId,agentName,agentUserName,agentPhoto,agentClub';
    filter = 'agent';
    delete query.viewSlug;
  }

  return generateInfiniteHook<
    T extends AgentUserViewSlug ? GetIaAgentUserResMap[T] : K
  >(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/agent/users?${getIaQueryString({
            ...query,
            page: index + 1,
            limit,
            expand,
            filter,
          })}`,
    limit,
    {
      auth: true,
      ...config,
    }
  );
}

export function useAgentPrompts<
  K = GetIaAgentPromptDefaultRes,
  T extends AgentPromptViewSlug | undefined = undefined
>(
  query: Omit<IaSearchReq<T>, 'page' | 'pageSize'> = {},
  config: SwrHelperParams = {}
) {
  const limit = query.limit || 0;

  return generateInfiniteHook<
    T extends AgentPromptViewSlug ? GetIaAgentPromptResMap[T] : K
  >(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/agent-prompts?${getIaQueryString({
            ...query,
            page: index + 1,
            limit,
          })}`,
    limit,
    {
      auth: true,
    }
  );
}

export function patchIaAiModelQuery<T>(query: IaSearchReq<T>): IaSearchReq<T> {
  let filter: string | undefined = query.filter;

  if (query.viewSlug === AiModelViewSlug.CardCenterLlmDefault) {
    filter = 'modelId;charCount';
  }

  return {
    ...query,
    filter,
  };
}

export function useAiModels<
  K = GetIaAiModelDefaultRes,
  T extends AiModelViewSlug | undefined = undefined
>(query: Omit<IaSearchReq<T>, 'page' | 'pageSize'> = {}) {
  const limit = query.limit || 0;

  return generateInfiniteHook<
    T extends AiModelViewSlug ? GetIaAiModelResMap[T] : K
  >(
    (index) =>
      `v2/ia/ai-models?${getIaQueryString({
        ...patchIaAiModelQuery(query),
        page: index + 1,
        limit,
      })}`,
    limit,
    {
      auth: true,
    }
  );
}

export const useMockAgents = () => generateMockInfiniteHook(mockAgents);

export const useMockAgentPrompts = () =>
  generateMockInfiniteHook(mockAgentPrompts);
