import React, { ReactNode } from 'react';
import { alpha, Box, Theme, Typography } from '@mui/material';
import { InfoTooltip, SimpleTooltip } from '@front/ui';
import TooltipList from '@lib/ia/src/components/TooltipList';
import TooltipListItem from '@lib/ia/src/components/TooltipListItem';
import { TableTooltip } from '@lib/ia/src/layouts/TableLayout/types';

const styles = {
  root: {
    height: '100%',
  },
  popper: {
    '& .MuiTooltip-tooltip': {
      p: '8px !important',
      maxWidth: '200px',
    },
  },
  tooltipTitle: {
    display: 'flex',
    gap: 1,
  },
  tooltipTitleTag: {
    fontSize: 16,
    px: 1,
    py: '1px',
    background: (theme: Theme) => alpha(theme.palette.background.darker, 0.1),
    color: (theme: Theme) => alpha(theme.palette.background.darker, 0.5),
    borderRadius: 1,
  },
};

export type IaTableTooltipProps = {
  tooltip?: TableTooltip;
  children: ReactNode;
};
export default function IaTableTooltip({
  tooltip,
  children,
}: IaTableTooltipProps) {
  if (!tooltip) {
    return children;
  }

  const childrenContent = <Box sx={styles.root}>{children}</Box>;

  const { type } = tooltip;

  if (type === 'simple') {
    return (
      <SimpleTooltip title={tooltip.content}>{childrenContent}</SimpleTooltip>
    );
  }

  if (type === 'info') {
    let title;
    if (tooltip.titleTag) {
      title = (
        <Box sx={styles.tooltipTitle}>
          <Typography variant="subtitle2" fontWeight={500}>
            {tooltip.title}
          </Typography>
          <Typography sx={styles.tooltipTitleTag} variant="body1">
            {tooltip.titleTag}
          </Typography>
        </Box>
      );
    } else {
      title = tooltip.title;
    }
    return (
      <InfoTooltip
        title={title}
        content={tooltip.content}
        placement={tooltip.placement || 'bottom'}
        slotProps={{
          popper: {
            sx: styles.popper,
          },
        }}
      >
        {childrenContent}
      </InfoTooltip>
    );
  }

  if (type === 'list') {
    return (
      <InfoTooltip
        title={tooltip.title}
        content={
          <TooltipList content={tooltip.content}>
            {tooltip.properties.map((property, i) => (
              <TooltipListItem key={i} {...property} />
            ))}
          </TooltipList>
        }
        placement={tooltip.placement || 'bottom'}
        slotProps={{
          popper: {
            sx: styles.popper,
          },
        }}
      >
        {childrenContent}
      </InfoTooltip>
    );
  }

  console.warn(`tooltip type ${type} is not supported`);
  return children;
}
