import { TFunction } from 'i18next';

const getAnalyticsPageConfig = (t: TFunction) => ({
  summary: {
    title: t('analytics.summary.title'),
    icon: t('analytics.summary.icon'),
    tooltip: {
      icon: t('analytics.summary.icon'),
      title: t('analytics.summary.tooltip_title'),
      content: t('analytics.summary.tooltip_body'),
    },
  },
  summaryWallet: {
    title: t('analytics.summary_wallet.title'),
    icon: t('analytics.summary_wallet.icon'),
  },
  summaryChallenges: {
    title: t('analytics.summary_challenges.title'),
    icon: t('analytics.summary_challenges.icon'),
  },
  summaryPlaylists: {
    title: t('analytics.summary_playlists.title'),
    icon: t('analytics.summary_playlists.icon'),
  },
  dailyGoals: {
    title: t('analytics.goals.title'),
    icon: t('analytics.goals.icon'),
    tooltip: {
      icon: t('analytics.goals.icon'),
      title: t('analytics.goals.tooltip_title'),
      content: t('analytics.goals.tooltip_body'),
    },
  },
  comparePath: {
    title: t('analytics.path.title'),
    icon: t('analytics.path.icon'),
  },
  weaknessMistakes: {
    title: t('analytics.weakness_mistakes.title'),
    icon: t('analytics.weakness_mistakes.icon'),
    tooltip: {
      icon: t('analytics.weakness_mistakes.icon'),
      title: t('analytics.weakness_mistakes.tooltip_title'),
      content: t('analytics.weakness_mistakes.tooltip_body'),
    },
  },
  weaknessOvertime: {
    title: t('analytics.weakness_overtime.title'),
    icon: t('analytics.weakness_overtime.icon'),
    tooltip: {
      icon: t('analytics.weakness_overtime.icon'),
      title: t('analytics.weakness_overtime.tooltip_title'),
      content: t('analytics.weakness_overtime.tooltip_body'),
    },
  },
  weaknessNotPracticed: {
    title: t('analytics.weakness_notPracticed.title'),
    icon: t('analytics.weakness_notPracticed.icon'),
    tooltip: {
      icon: t('analytics.weakness_notPracticed.icon'),
      title: t('analytics.weakness_notPracticed.tooltip_title'),
      content: t('analytics.weakness_notPracticed.tooltip_body'),
    },
  },
  overallMistakes: {
    title: t('analytics.overall_mistakes.title'),
    icon: t('analytics.overall_mistakes.icon'),
    tooltip: {
      icon: t('analytics.overall_mistakes.icon'),
      title: t('analytics.overall_mistakes.tooltip_title'),
      content: t('analytics.overall_mistakes.tooltip_body'),
    },
  },
  overallOvertime: {
    title: t('analytics.overall_overtime.title'),
    icon: t('analytics.overall_overtime.icon'),
    tooltip: {
      icon: t('analytics.overall_overtime.icon'),
      title: t('analytics.overall_overtime.tooltip_title'),
      content: t('analytics.overall_overtime.tooltip_body'),
    },
  },
});

export default getAnalyticsPageConfig;
