import { useContext, useEffect } from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import { OtherKeyboard as OtherKeyboardIcon } from '@front/icon';
import { IconButton, MathKeyboardBottom } from '@front/ui';
import { TextComposerContext } from '@lib/web/composer/TextComposer/context/TextComposerContext';

import { useThreadComposer } from '../../hooks/core/useThreadComposer';

const styles = {
  divider: {
    backgroundColor: 'alpha.lightA10',
  },
  root: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
  },
};

export type MathKeyboardActionButtonProps = {
  disabled?: boolean;
};

export function MathKeyboardActionButton({
  disabled,
}: MathKeyboardActionButtonProps) {
  const { selectedAction, setSelectedAction } = useThreadComposer();

  const selected = selectedAction === 'mathKeyboard';
  const handleClick = () => {
    setSelectedAction(selected ? undefined : 'mathKeyboard');
  };

  return (
    <IconButton
      customSize={24}
      selected={selected}
      onClick={handleClick}
      disabled={disabled}
    >
      <OtherKeyboardIcon />
    </IconButton>
  );
}

export function MathKeyboardActionContent() {
  const { selectedAction } = useThreadComposer();
  const { commands } = useContext(TextComposerContext);

  useEffect(() => {
    if (selectedAction === 'mathKeyboard') {
      commands.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAction]);
  if (selectedAction !== 'mathKeyboard') return;

  const handleKeyDown = (key: string) => {
    commands.insertContent(key);
  };
  return (
    <>
      <Divider sx={styles.divider} />

      <Box sx={styles.root}>
        <MathKeyboardBottom onKeyDown={handleKeyDown} />
      </Box>
    </>
  );
}
