import { ChipProps, TooltipProps, TypographyProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { ButtonProps } from '@front/ui';
import { IaSortableConfig } from '@lib/ia/src/components/IaSortable';
import { UploadImageConfig } from '@lib/ia/src/components/ImageUploader/types';
import {
  IaAction,
  IaColorVariant,
  IaHintKeyAction,
  IaPropertyEmoji,
  IaRichText,
} from '@lib/ia/src/core/types';

import { TooltipListItemType } from '../../components/TooltipListItem/types';

export type TableLayoutHeadDefaultCell = {
  type: 'default';
  label?: string;
  icon?: string;
};

export type TableLayoutHeadIconTextCell = {
  type: 'iconText';
  leftIcon: string;
  textIcon: string;
  text: string;
};

export type TableLayoutHeadCell =
  | TableLayoutHeadDefaultCell
  | TableLayoutHeadIconTextCell;

export type TableTooltip =
  | {
      type: 'simple';
      content: string;
    }
  | {
      type: 'info';
      title: string;
      titleTag?: string;
      content: string;
      placement?: TooltipProps['placement'];
    }
  | {
      type: 'list';
      title: string;
      content?: string;
      placement?: TooltipProps['placement'];
      properties: TooltipListItemType[];
    };

type TableLayoutCellBase = {
  tooltip?: TableTooltip;
  className?: string;
  focusable?: boolean;
};

export type TableLayoutEmptyCell = TableLayoutCellBase & {
  type: 'empty';
};

export type TableLayoutBlurCell = TableLayoutCellBase & {
  type: 'blur';
};

export type TableLayoutTextBasedEditableCell = {
  editable?: boolean;
  actionMap?: {
    valueChange?: string;
    inputBlur?: string;
  };
  placeholder?: string;
  helperText?: string;

  // display when the cell have no content
  emptyText?: string;
};

export type TableLayoutIconTextEditableCell = Omit<
  TableLayoutTextBasedEditableCell,
  'actionMap'
> & {
  actionMap?: {
    valueChange?: string;
    pickEmoji?: string;
  };
};

export type TableLayoutNumberCell = TableLayoutCellBase & {
  type: 'number';
  value: number | null;
  fractionDigits?: number;
  keepFractionDigitsWhenZero?: boolean;
  asSequence?: boolean;
  actionMap?: TableLayoutTextBasedEditableCell['actionMap'] & {
    click?: string;
  };
} & Omit<TableLayoutTextBasedEditableCell, 'actionMap'>;

export type TableLayoutTextCell = TableLayoutCellBase & {
  type: 'text';
  text?: string;
  html?: string;
  sx?: SxProps;
} & TableLayoutTextBasedEditableCell;

export type TableLayoutChipCell = TableLayoutCellBase & {
  type: 'chip';
  text?: string;
  color?: ChipProps['color'];
  variant?: ChipProps['variant'];
};

export type TableLayoutProgressCell = TableLayoutCellBase & {
  type: 'progress';
  color?: 'default' | 'primary' | 'error' | 'success' | 'warning';
  value: number;
  total: number;
};

export type TableLayoutCheckboxCell = TableLayoutCellBase & {
  type: 'checkbox';
};

export type TableLayoutPasswordCell = TableLayoutCellBase & {
  type: 'password';
  sx?: SxProps;
  actionMap?: TableLayoutTextBasedEditableCell['actionMap'] & {
    revealPassword: string;
  };
} & Omit<TableLayoutTextBasedEditableCell, 'actionMap'>;

export type TableLayoutURLCell = TableLayoutCellBase & {
  type: 'url';
  url?: string;
} & TableLayoutTextBasedEditableCell;

export type TableLayoutDateCell = TableLayoutCellBase & {
  type: 'date';
  value: string;
  sx?: SxProps;
  editable?: boolean;
  dateFormat?: string;
  maxDate?: Date | string | number | null;
  minDate?: Date | string | number | null;
  disabled?: boolean;
  placeholder?: string;
  actionMap?: {
    valueChange?: string;
  };
};

export type TableLayoutAutoCompleteOption<T = string | number | unknown> = {
  label: string;
  value: T;
  icon?: string;
  iconType?: 'image' | 'icon' | 'capital-avatar';
};

export type TableLayoutAutoCompleteCell<T = string | number | unknown> =
  TableLayoutCellBase & {
    type: 'autoComplete';
    options: TableLayoutAutoCompleteOption<T>[];
    isOptionsAsync?: boolean;
    value: TableLayoutAutoCompleteOption<T> | null;
    sx?: SxProps;
    disabled?: boolean;
    placeholder?: string;
    actionMap?: {
      valueChange?: string;
    };
  };

export type TableLayoutIconCell = TableLayoutCellBase & {
  type: 'icon';
  icon?: string;
  emoji?: string;
  color?: IaColorVariant | string;
  clickAction?: string;
  disabled?: boolean;
};

export type TableLayoutReactionCell = TableLayoutCellBase & {
  type: TableLayoutPlugin.Reaction;
  value?: IaPropertyEmoji | null;
  changeAction?: string;
  disabled?: boolean;
};

export type TableLayoutStatusCell = TableLayoutCellBase & {
  type: 'status';
  variant: IaColorVariant;
  text?: string;
  display?: 'tag' | 'default';
  actionMenuWidth?: number;
  actionSelectedValue?: string;
  actions?: IaAction[];

  // editable properties
  actionMap?: {
    valueChange?: string;
  };
  value?: string;
  placeholder?: string;
  helperText?: string;
};

export type TableLayoutSelectOption<T = string | number> = {
  label: string;
  value: T;
  icon?: {
    type: 'icon' | 'circle' | 'square';
    value: string;
  };
  subOptions?: TableLayoutSelectOption<T>[];
  selectable?: boolean;
};

export type TableLayoutSelectCellStatus<T = string | number> = {
  option: TableLayoutSelectOption<T>;
  row: TableLayoutRow;
  cell: TableLayoutSelectCell;
  columnKey: string;
};

export type TableLayoutSelectCell = TableLayoutCellBase & {
  type: 'select';
  options: TableLayoutSelectOption[];
  optionClickAction?: string;
  subOptionClickAction?: string;
  disabled?: boolean;
};

export type TableLayoutIconTextCell = TableLayoutCellBase & {
  type: 'iconText';
  icon: {
    type: 'icon' | 'emoji';
    value: string;
  } | null;
  text?: string;
  html?: string;
  isChild?: boolean;
} & TableLayoutIconTextEditableCell;

export type TableLayoutAvatarTextCell = TableLayoutCellBase & {
  type: 'avatarText';
  avatar?: string | null;
  avatarCount?: number;
  avatarBadge?: 'private';
  avatarName?: string;
  text?: IaRichText;
  textVariant?: TypographyProps['variant'];
  html?: string;
  textSuffix?: string;
  userId?: string;
  memberId?: string;
  avatarUploadConfig?: UploadImageConfig;
  actionMap?: TableLayoutTextBasedEditableCell['actionMap'] & {
    avatarUploadPanelOpen?: string;
    avatarKeyChange?: string;
  };
} & Omit<TableLayoutTextBasedEditableCell, 'actionMap'>;

export type TableLayoutAvatarGroupCell = TableLayoutCellBase & {
  type: 'avatarGroup';
  avatars: {
    name: string;
    avatarUrl: string;
  }[];
  maxDisplayNumber: number;
};

export type TableLayoutIndicatorCell = TableLayoutCellBase & {
  type: 'indicator';
  value: IaColorVariant | string;
};

export type TableLayoutSwitchCell = TableLayoutCellBase & {
  type: 'switch';
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  changeAction: string;
};

export type TableLayoutButtonCell = TableLayoutCellBase &
  (Omit<IaAction, 'type'> & {
    type: 'button';
    buttonVariant: ButtonProps['variant'];
    buttonColor: ButtonProps['color'];
    disabled?: boolean;
    fullWidth?: boolean;
    disabledText?: string;
  });

export type TableLayoutCell =
  | TableLayoutEmptyCell
  | TableLayoutBlurCell
  | TableLayoutNumberCell
  | TableLayoutTextCell
  | TableLayoutChipCell
  | TableLayoutProgressCell
  | TableLayoutCheckboxCell
  | TableLayoutPasswordCell
  | TableLayoutIconCell
  | TableLayoutReactionCell
  | TableLayoutStatusCell
  | TableLayoutSelectCell
  | TableLayoutIconTextCell
  | TableLayoutAvatarTextCell
  | TableLayoutAvatarGroupCell
  | TableLayoutIndicatorCell
  | TableLayoutSwitchCell
  | TableLayoutButtonCell
  | TableLayoutURLCell
  | TableLayoutDateCell
  | TableLayoutAutoCompleteCell;

export type TableLayoutRowMoreAction = WithRequired<IaAction, 'text'> & {
  hintInline?: boolean;
  subActions?: TableLayoutRowMoreAction[];
  plugin?: TableLayoutPlugin;
};

export type TableLayoutRow<M = Record<string, any>> = {
  id: string;
  draggable?: boolean;
  clickAction?: IaAction;
  mouseEnterAction?: IaAction;
  mouseLeaveAction?: IaAction;
  moreActions?: TableLayoutRowMoreAction[];
  hintInline?: boolean;
  cells: Record<string, TableLayoutCell>;
  selected?: boolean;
  sx?: SxProps;
  expanded?: boolean;
  metadata?: M;
};

export type IaCellValueChangedEvent<T, M = Record<string, any>> = {
  value: T;
  row: TableLayoutRow<M>;
  columnKey: string;
};

export type IaSelectCellValueChangedEvent<
  T = string | number,
  M = Record<string, any>
> = {
  option: TableLayoutSelectOption<T>;
  row: TableLayoutRow<M>;
  cell: TableLayoutSelectCell;
  columnKey: string;
};

export type IaRowExpandedEvent = {
  value: boolean;
  row: TableLayoutRow;
};

export type IaTableSidePeekSettings = {
  enabled: boolean;
  clickAction?: IaAction;
};

export type IaTableExpandableSettings = {
  expandAction: string;
};

export type RowNestedLevel = `nestedLevel${number}`;

export enum TableLayoutPlugin {
  Reaction = 'plugin-reaction',
}
export interface TableLayoutConfig {
  layout: 'table-layout';
  plugins?: TableLayoutPlugin[];
  table: {
    columnOrder: string[];
    gridTemplateColumns: string;
    head: {
      cells: Record<string, TableLayoutHeadCell>;
      hideCheckbox?: boolean;
      checkbox?: {
        icon: string;
        text: string;
        value: string;
        state: 'checked' | 'partial' | 'unchecked';
      };
    };
    rows: TableLayoutRow[];
    childRows?: Record<string, TableLayoutRow[]>;
    columnsTooltip?: Record<string, TableTooltip>;
    emptyStateToolbarActions?: TableLayoutRowMoreAction[];
  };
  data: {
    pageSize: number;
    defaultPages?: number;
    state: 'notReachedEnd' | 'reachedEnd' | 'empty' | 'loading';
    totalCount: number;
    emptyRow: {
      text: string;
    };
    loadMoreRow?: {
      text: string;
    };
    loadMorePagesAction?: string;
    showLoadingRow?: 'top' | false;
  };
  settings: {
    bottomButtons?: {
      addNewRow?: {
        text: string;
        action: string;
        icon?: string;
      };
    };
    sortableConfig?:
      | IaSortableConfig // config sortable for the first level only
      /**
       * config sortable for each nested rows level (in case of the table has nested rows)
       * key is nested level: nestedLevel0, nestedLevel1, ...
       */
      | Record<RowNestedLevel, IaSortableConfig>;
    sideFloatingMenuAddActions?: IaHintKeyAction[];
    disableCellFocus?: boolean;
    layoutSetting: {
      position: 'centre' | 'rhs';
      showRowBorder: boolean;
      showColumnBorder: boolean;
      minWidth?: number;
      maxWidth?: number;
    };
    sidePeek?: IaTableSidePeekSettings;
    expandable?: IaTableExpandableSettings;
    toolbar?: {
      /**
       * exclude actions that can not be used for all selected rows in bulk.
       * => these actions will not show on the toolbar
       */
      excludeActions?: string[];
    };
  };
  events?: {
    onCheckboxToggledAction?: string;
    cellActiveChangeAction?: string;
    onCheckboxChange?: {
      selectedIds: string[];
      changeAction: string;
    };
  };
  controlState?: {
    selectedCheckedIds?: Set<string>;
    setSelectedCheckedIdsAction?: string;
  };
}
