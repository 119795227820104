import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import ButtonBase, {
  ButtonBaseProps,
  ButtonBaseTypeMap,
} from '@mui/material/ButtonBase';
import { alpha, Theme } from '@mui/material/styles';

import LoadingIcon from '../../../components/LoadingIcon';

export type TextButtonColorType = 'inherit' | 'primary';
export type TextButtonSizeType = 'sm' | 'md' | 'lg';

export type TextButtonProps<
  D extends React.ElementType = ButtonBaseTypeMap['defaultComponent'],
  P = {
    suffixIcon?: ReactNode;
    prefixIcon?: ReactNode;
    loading?: boolean;
    loadingText?: string;
    color?: TextButtonColorType;
    size?: TextButtonSizeType;
    component?: D;
  }
> = ButtonBaseProps<D, P>;

const styles = {
  root: {
    typography: 'button',
    fontWeight: 700,
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 0.5,
    transitionDuration: '0.2s',
    transitionProperty: 'transform, background, color',
    whiteSpace: 'nowrap',
    '& svg': {
      display: 'block',
      width: 16,
      height: 16,
    },
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        textDecoration: 'underline',
        textUnderlinePosition: 'under',
        textDecorationThickness: 2,
      },
    },
  },
  withIcon: {
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        textDecoration: 'none',
        '& .button-text-underline': {
          display: 'block',
        },
      },
    },
  },
  loading: {
    background: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.text.primary, 0.05)
        : alpha(theme.palette.text.primary, 0.1),
  },
  disabled: {
    opacity: 0.5,
  },
  sm: {
    height: 21,
    px: 1,
  },
  md: {
    height: 29,
    px: 1,
  },
  lg: {
    height: 34,
    px: 1,
  },
  underline: {
    display: 'none',
    position: 'absolute',
    padding: 'inherit',
    height: '2px',
    width: '100%',
    bottom: 0,
    left: 0,

    '&:after': {
      content: '""',
      position: 'relative',
      display: 'block',
      width: '100%',
      height: '2px',
      background: (theme: Theme) => theme.palette.text.primary,
    },
  },
};

function Underline() {
  return <Box sx={styles.underline} className="button-text-underline" />;
}

function TextButtonInner<D extends React.ElementType>(
  {
    sx,
    children,
    size = 'sm',
    disabled = false,
    loading = false,
    loadingText,
    prefixIcon,
    suffixIcon,
    ...rest
  }: TextButtonProps<D>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const withIcon = !!prefixIcon || !!suffixIcon;
  return (
    <ButtonBase
      ref={ref}
      sx={[
        styles.root,
        loading && styles.loading,
        disabled && !loading && styles.disabled,
        styles[size],
        withIcon && styles.withIcon,
        ...sxProps,
      ]}
      disableTouchRipple
      disableRipple
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? (
        <>
          <LoadingIcon />
          {loadingText}
        </>
      ) : (
        <>
          {!!prefixIcon && prefixIcon}
          {children}
          {!!suffixIcon && suffixIcon}
          {withIcon && <Underline />}
        </>
      )}
    </ButtonBase>
  );
}

const TextButton = React.forwardRef(TextButtonInner) as <
  D extends React.ElementType = 'button'
>(
  props: TextButtonProps<D> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  }
) => ReturnType<typeof TextButtonInner<D>>;

export default TextButton;
