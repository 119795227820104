import React from 'react';
import { alpha, Theme } from '@mui/material';
import { Icon, PopupActions, PopupOverlay, PopupOverlayProps } from '@front/ui';

import CtaButtonSection from './components/CtaButtonSection';
import { usePaidFeatureBlockConfig } from './hooks/usePaidFeatureBlockConfig';
import { PaidFeatureBlockerPlacement, PaidFeatureBlockerType } from './types';

const styles = {
  modal: {
    position: 'absolute',
    zIndex: 1,
    top: { xs: 48, md: 48 }, // 48px for top bar
    bottom: 0,
    '& .MuiBackdrop-root': {
      position: 'absolute',
      background: (theme: Theme) =>
        alpha(theme.palette.background.darker, 0.01),
    },
  },
  overlay: (placement: PaidFeatureBlockerPlacement | null) => ({
    width: '100%',
    height: { xs: 'auto', md: 'auto' }, // this is to clear the PopupOverlay default behavior
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    maxWidth: 796,

    '& .popup-overlay-title': {
      typography: 'appH1',
    },
    '& .popup-overlay-body': {
      span: {
        color: 'text.primary',
      },
    },
    '& .popup-overlay-emoji': {
      width: 40,
      height: 40,
      '& svg': {
        width: 40,
        height: 40,
      },
    },
    '& .popup-overlay-content': {
      gap: 1,
      py: 0,
    },
    '& .popup-overlay-close': {
      top: placement === 'rhs' ? 16 : 40,
      right: placement === 'rhs' ? 16 : 50,
    },
  }),
  actions: {
    gap: '30px',
  },
};

export type PaidFeatureBlockerProps = {
  type: PaidFeatureBlockerType;
  placement: PaidFeatureBlockerPlacement;
  onClose?: PopupOverlayProps['onClose'];
  showCloseButton?: PopupOverlayProps['showCloseButton'];
};

export default function PaidFeatureBlocker({
  type,
  placement,
  onClose,
  showCloseButton = true,
}: PaidFeatureBlockerProps) {
  const config = usePaidFeatureBlockConfig({ type });

  if (!config) return null;

  return (
    <PopupOverlay
      open
      title={config.title}
      body={config.description}
      emojiComponent={<Icon name={config.icon} />}
      modalProps={{
        sx: styles.modal,
        container: document.querySelector(
          placement === 'rhs'
            ? '[data-testid="base-layout-right-panel"].is-active'
            : '[data-testid="base-layout-main"]'
        ) as Element,
      }}
      sx={styles.overlay(placement)}
      showCloseButton={showCloseButton}
      onClose={onClose}
      actionComponent={
        <PopupActions sx={styles.actions}>
          {config.actions.map((action) => (
            <CtaButtonSection
              key={action.title}
              icon={action.icon}
              title={action.title}
              description={action.description}
              button={action.button}
            />
          ))}
        </PopupActions>
      }
    />
  );
}
