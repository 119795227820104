import { useEffect, useRef } from 'react';
import {
  getLeftQuestionComponents,
  getLeftQuestionContentHtml,
} from '@lib/web/editor';
import EditorComposerRenderer from '@lib/web/editor/EditorTextComposer/EditorComposerRenderer';
import { usePassageEl } from '@lib/web/ui';

import QuizAudioRender from '../../../../../../QuizAudioRender';
import usePracticeQuestion from '../../../../../hooks/usePracticeQuestion';
import Highlighter from '../Highlighter';

import useRenderPassageWrap from './useRenderPassageWrap';

export default function UserCreatePassage() {
  const { creatorQuestionInfo, creatorQuestionGroups, id, contentEnricher } =
    usePracticeQuestion();

  const passageRef = useRef<HTMLDivElement | null>(null);
  const { setPassageEl } = usePassageEl();

  useEffect(() => {
    setPassageEl(passageRef.current);
  }, [setPassageEl]);

  const content = getLeftQuestionContentHtml(
    getLeftQuestionComponents(creatorQuestionInfo, creatorQuestionGroups)
  );

  const passageWrap = useRenderPassageWrap(content || '', creatorQuestionInfo);

  const renderContent = (
    <Highlighter key={creatorQuestionInfo?.id} examQuestionId={id}>
      <EditorComposerRenderer
        html={contentEnricher(content)}
        audioRender={QuizAudioRender}
      />
    </Highlighter>
  );
  return (
    <div ref={passageRef}>{passageWrap?.(renderContent) || renderContent}</div>
  );
}
