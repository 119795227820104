/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  ReactNode,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PopoverVirtualElement } from '@mui/material';
import Box from '@mui/material/Box';
import { MenuProps as MuiMenuProps } from '@mui/material/Menu';
import { useDimension } from '@front/helper';
import { ActionChevronDown as ActionChevronDownIcon } from '@front/icon';

import { Scrollbar } from '../../atoms';
import { PropertyType } from '../../types/enums';
import ResponsivePopper from '../ResponsivePopper';
import TextField, { TextFieldProps } from '../TextField';

export type SelectProps = TextFieldProps & {
  MenuProps?: Omit<MuiMenuProps, 'open' | 'onClose' | 'anchorEl'>;
  renderDisplay?: (ev: any) => void;
  children: ReactNode;
};

const styles = {
  menu: {
    mx: 0,
    my: 0.5,
  },
  field: {
    '& .MuiInputBase-input': {
      pr: '44px',
    },
  },
  arrowIcon: {
    pr: 2,
    pl: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
  },
};

function areEqualValues(a: unknown, b: unknown) {
  if (typeof b === 'object' && b !== null) {
    return a === b;
  }

  // The value could be a number, the DOM will stringify it anyway.
  return String(a) === String(b);
}

const Select = React.forwardRef(
  (
    {
      sx,
      MenuProps = {},
      onFocus,
      onChange,
      children,
      disabled,
      value,
      renderDisplay,
      labelIcon,
      ...rest
    }: SelectProps,
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const innerRef = useRef<Element>(null);
    const inputRef = useRef<Element>(null);
    const { width: innerWidth } = useDimension(innerRef);

    let display = '';
    useImperativeHandle(ref, () => innerRef.current);

    const handleClick = () => {
      if (disabled) return; // although we set disabled in TextField, which does not work on endAdornment, so we need to handle it here
      setOpen(true);
    };

    const handleItemClick = (child: ReactElement) => (event: any) => {
      if (!event.currentTarget.hasAttribute('tabindex')) {
        return;
      }

      const newValue = child.props.value;

      if (child.props.onClick) {
        child.props.onClick(event);
      }

      if (value !== newValue && onChange) {
        const nativeEvent = event.nativeEvent || event;
        const clonedEvent = new nativeEvent.constructor(
          nativeEvent.type,
          nativeEvent
        );

        Object.defineProperty(clonedEvent, 'target', {
          writable: true,
          value: { value: newValue },
        });
        onChange(clonedEvent);
      }

      setOpen(false);
    };
    const childrenArray = React.Children.toArray(children);

    const items = childrenArray.map((child) => {
      if (!React.isValidElement(child)) {
        return null;
      }

      const selected = areEqualValues(value, child.props.value);

      if (selected) {
        display = renderDisplay
          ? renderDisplay(child.props.value)
          : typeof child.props.children === 'string' ||
            typeof child.props.children === 'number'
          ? child.props.children
          : child.props.value;
      }

      return React.cloneElement<{
        onClick: (ev: MouseEvent) => void;
        onKeyUp: (ev: KeyboardEvent) => void;
        selected: boolean;
        value: any;
        props: any;
      }>(child as any, {
        onClick: handleItemClick(child),
        onKeyUp: (event: KeyboardEvent) => {
          if (event.key === ' ') {
            event.preventDefault();
          }

          if (child.props.onKeyUp) {
            child.props.onKeyUp(event);
          }
        },
        selected,
        value: undefined,
      });
    });

    const sxProps = Array.isArray(sx) ? sx : [sx];

    /**
     * This is the select box:
     *
     *    ++++++++++++++++++++++++++++++
     *    + # label here               +
     *    +  ------------------------  +
     *    + |{#}[       Input    \/ ]| +
     *    +  ------------------------  +
     *    + ~~ help text ~~            +
     *    ++++++++++++++++++++++++++++++
     *
     * +++++                : Select box container <=> innerRef
     * {#}                  : Prefix
     * [       Input    \/ ]: Input inner <=> inputRef
     *
     * in case of without prefix the input will fit with the container box => use inputRef.current as anchor is ok
     * but in case of the prefix is provided, the input will offset to the right by the prefix width >>{#}<<
     * then the popper will offset to the right as well.
     * => Need to create VirtualElement:
     * - top/bottom/height is from the input (inputRef)
     * - left/right/width is from the container (innerRef)
     */
    const popperAnchor: PopoverVirtualElement = useMemo(
      () => ({
        getBoundingClientRect: () =>
          ({
            width: innerRef.current?.getBoundingClientRect().width,
            height: inputRef.current?.getBoundingClientRect().height,
            top: inputRef.current?.getBoundingClientRect().top,
            right: innerRef.current?.getBoundingClientRect().right,
            bottom: inputRef.current?.getBoundingClientRect().bottom,
            left: innerRef.current?.getBoundingClientRect().left,
          } as DOMRect),
        nodeType: 1,
      }),
      []
    );

    return (
      <>
        <TextField
          sx={[styles.field, ...sxProps]}
          ref={innerRef}
          value={display}
          disabled={disabled}
          onClick={handleClick}
          inputRef={inputRef}
          focused={open}
          endAdornment={
            <Box sx={styles.arrowIcon} component="span">
              <ActionChevronDownIcon width={16} height={16} />
            </Box>
          }
          readOnly
          {...rest}
          labelIcon={labelIcon !== undefined ? labelIcon : PropertyType.Select}
        />
        <ResponsivePopper
          open={!!innerRef.current && open}
          onClose={() => setOpen(false)}
          sheetProps={{
            fixedHeight: true,
            disableDrag: true,
          }}
          menuProps={{
            ...MenuProps,
            sx: [
              styles.menu,
              {
                '& .MuiList-root': {
                  minWidth: innerWidth,
                },
              },
            ],
            disablePortal: true,
            anchorEl: popperAnchor,
          }}
          {...MenuProps}
        >
          <Scrollbar sx={{ maxHeight: 400 }}>{items}</Scrollbar>
        </ResponsivePopper>
      </>
    );
  }
);

export default Select;
