import React, { memo } from 'react';
import { SideFloatingCheckboxProps } from '@lib/ia/src/components/SideFloatingCheckbox';

import {
  TableLayoutCell,
  TableLayoutPlugin,
  TableLayoutRow,
} from '../../types';

import IaAvatarTextCell from './IaAvatarTextCell';
import IaButtonCell from './IaButtonCell';
import IaCheckboxCell from './IaCheckboxCell';
import IaProgressCell from './IaProgressCell';
import IaSwitchCell from './IaSwitchCell';
import IaURLCell from './IaURLCell';
import {
  IaAutoCompleteCell,
  IaAvatarGroupCell,
  IaBlurCell,
  IaChipCell,
  IaDateCell,
  IaEmptyCell,
  IaIconCell,
  IaIconTextCell,
  IaIndicatorCell,
  IaNumberCell,
  IaPasswordCell,
  IaReactionCell,
  IaSelectCell,
  IaStatusCell,
  IaTextCell,
} from './index';

export type IaTableCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutCell;
  columnKey: string;
  checkbox: {
    state: SideFloatingCheckboxProps['state'];
    onSelect: () => void;
  };
};

const IaTableCell = memo(function IaTableCell({
  row,
  columnKey,
  cell,
  checkbox,
}: IaTableCellProps) {
  switch (cell.type) {
    case 'empty':
      return <IaEmptyCell />;
    case 'indicator':
      return <IaIndicatorCell {...cell} />;
    case 'text':
      return <IaTextCell row={row} cell={cell} columnKey={columnKey} />;
    case 'password':
      return <IaPasswordCell row={row} cell={cell} columnKey={columnKey} />;
    case 'icon':
      return <IaIconCell row={row} cell={cell} columnKey={columnKey} />;
    case 'number':
      return <IaNumberCell row={row} cell={cell} columnKey={columnKey} />;
    case 'status':
      return <IaStatusCell row={row} cell={cell} columnKey={columnKey} />;
    case 'select':
      return <IaSelectCell row={row} cell={cell} columnKey={columnKey} />;
    case 'iconText':
      return <IaIconTextCell row={row} cell={cell} columnKey={columnKey} />;
    case 'avatarText':
      return <IaAvatarTextCell row={row} cell={cell} columnKey={columnKey} />;
    case 'avatarGroup':
      return <IaAvatarGroupCell {...cell} />;
    case 'blur':
      return <IaBlurCell />;
    case 'switch':
      return <IaSwitchCell row={row} cell={cell} columnKey={columnKey} />;
    case 'button':
      return <IaButtonCell row={row} cell={cell} columnKey={columnKey} />;
    case 'url':
      return <IaURLCell row={row} cell={cell} columnKey={columnKey} />;
    case 'date':
      return <IaDateCell row={row} cell={cell} columnKey={columnKey} />;
    case 'autoComplete':
      return <IaAutoCompleteCell row={row} cell={cell} columnKey={columnKey} />;
    case 'chip':
      return <IaChipCell row={row} cell={cell} columnKey={columnKey} />;
    case 'progress':
      return <IaProgressCell row={row} cell={cell} columnKey={columnKey} />;
    case 'checkbox':
      return (
        <IaCheckboxCell
          row={row}
          cell={cell}
          columnKey={columnKey}
          checkbox={checkbox}
        />
      );
    case TableLayoutPlugin.Reaction:
      return <IaReactionCell row={row} cell={cell} columnKey={columnKey} />;
    default:
      console.warn('Unknown body cell type:', cell);
      return null;
  }
});

export default IaTableCell;
