import { Icon } from '@front/ui';
import { ParsedUrlQuery } from 'querystring';

import getAnalyticsPageConfig from '../pageConfig/analytics';
import getPlaylistsPageConfig from '../pageConfig/playlists';

import PlaylistHeader from './components/PlaylistHeader';
import {
  AgentBreadcrumb,
  AnalyticsSummaryCustomDateBreadcrumb,
  AwardCategoryBreadcrumb,
  ChallengeBreadcrumb,
  ClubBreadcrumb,
  ClubGroupBreadcrumb,
  CreatorPlaylistBreadcrumb,
  InvitePhoneNumberBreadcrumb,
  PlaylistBreadcrumb,
  ReviewQuestionDetailBreadcrumb,
  ThreadViewBreadcrumb,
} from './BreadcrumbItems';

export const getAllBreadcrumbsValues = (
  pathname: string,
  query: ParsedUrlQuery,
  t: any
) => {
  const analyticsConfig = getAnalyticsPageConfig(t);
  const playlistsConfig = getPlaylistsPageConfig(t);
  return {
    home: {
      text: t('breadcrumb::Clubs'),
      icon: 'MainApps',
      href: '/',
    },
    agents: {
      text: t('breadcrumb::Agents'),
      icon: 'ProfileClubAgent',
      href: `/agents`,
    },
    agentDetail: {
      customizeComponent: (
        <AgentBreadcrumb agentId={(query.agentId || '') as string} />
      ),
    },
    clubAgents: {
      text: t('breadcrumb::Club Agents'),
      icon: 'ProfileClubAgent',
      href: `/club/${query.slug}/agents`,
    },
    clubAgentDetail: {
      customizeComponent: (
        <AgentBreadcrumb agentId={(query.agentId || '') as string} />
      ),
    },
    myAgents: {
      text: t('breadcrumb::My Agents'),
      icon: 'ProfileMyAgent',
      href: `/club/${query.slug}/my-agents`,
    },
    club: {
      customizeComponent: (
        <ClubBreadcrumb slug={(query.slug || '') as string} />
      ),
    },
    myCreatedClubs: {
      text: t('breadcrumb::My Created Clubs'),
      icon: 'PropertyTypePerson',
      href: '/club/my-created',
    },
    joinedClubs: {
      text: t('breadcrumb::Joined Clubs'),
      icon: 'TextEditorCheck',
      href: '/club/joined',
    },
    clubGroup: {
      text: t('breadcrumb::Club Groups'),
      icon: 'MainClubGroup',
      href: '/club-group/',
    },
    clubGroupDetail: {
      customizeComponent: (
        <ClubGroupBreadcrumb groupId={(query.groupId || '') as string} />
      ),
      href: `/club-group/${query.groupId}`,
    },
    clubNotifications: {
      text: t('breadcrumb::Notifications'),
      icon: 'MainNotificationOn',
      href: `/club/${query.slug}/notifications`,
    },
    notifications: {
      text: t('breadcrumb::Notifications'),
      icon: 'MainNotificationOn',
      href: `/notifications`,
    },
    directMessages: {
      text: t('breadcrumb::DirectMessages.Entry'),
      icon: 'ThreadsDirectMessages',
      href: `/direct-messages`,
    },
    directMessagesNewThread: {
      text: t('breadcrumb::DirectMessages.New Thread'),
      icon: 'TestAdd',
      href: `/direct-messages/new-thread`,
    },
    directMessagesView: {
      customizeComponent: (
        <ThreadViewBreadcrumb viewId={(query.viewId || '') as string} />
      ),
    },
    start: {
      text: t('breadcrumb::Start', 'Start Practice'),
      icon: 'OtherPlay',
      href: `/club/${query.slug}/start`,
    },
    rankings: {
      text: t('breadcrumb::Rankings'),
      icon: 'TestTrophy',
      href: `/club/${query.slug}/rankings`,
    },

    qBankReporter: {
      text: t('breadcrumb::QBank Reporter'),
      icon: 'TestReport',
      href: `/club/${query.slug}/qbank-reporter`,
    },

    marketplaceAvailable: {
      text: t('breadcrumb::Available'),
      icon: 'TextEditorCheck',
      href: '/marketplace/available',
    },

    marketplaceUnavailable: {
      text: t('breadcrumb::Unavailable'),
      icon: 'ActionCloseSmall',
      href: '/marketplace/unavailable',
    },

    marketplaceComingSoon: {
      text: t('breadcrumb::Coming Soon'),
      icon: 'TestClockSolid',
      href: '/marketplace/coming-soon',
    },

    marketplaceMyFavorite: {
      text: t('breadcrumb::My Favorite'),
      icon: 'NFTFavoriteSolid',
      href: '/marketplace/my-favorite',
    },

    marketplaceMyAvatars: {
      text: t('breadcrumb::My Avatars'),
      icon: 'ProfileNFTs',
      href: '/marketplace/my-avatars',
    },

    account: {
      text: t('breadcrumb::My Profile'),
      icon: 'MainProfileSolid',
      href: '/profile/account',
    },

    accountEdit: {
      text: t('breadcrumb::Edit Profile'),
      icon: 'OtherEdit',
      href: '/profile/account/edit',
    },

    discountCode: {
      text: t('breadcrumb::Discount Code'),
      icon: 'OtherDiscountNoOuter',
      href: '/profile/discount-code',
    },

    discountCodeManagement: {
      text: t('breadcrumb::Manage Discount Codes'),
      icon: 'PropertyTypeTag',
      href: '/profile/discount-code',
    },

    profileAwards: {
      text: t('breadcrumb::Awards'),
      icon: 'ProfileAwards',
      href: '/profile/awards',
    },

    profileAwardsAlmostThere: {
      text: t('breadcrumb::Almost There'),
      icon: 'ProfileAwards',
      href: '/profile/awards/almost-there',
    },

    profileAwardsByCategory: {
      icon: 'ProfileAwards',
      customizeComponent: (
        <AwardCategoryBreadcrumb
          categoryPath={(query.categoryPath || '') as string}
        />
      ),
    },

    myPlan: {
      text: t('breadcrumb::My Plan'),
      icon: 'ProfileMyPlan',
      href: '/profile/my-plan',
    },

    parentPhoneNumber: {
      customizeComponent: <InvitePhoneNumberBreadcrumb />,
      href: '/profile/parent-phone-number',
    },

    inviteFriends: {
      text: t('breadcrumb::Invite Friends'),
      icon: 'OtherAddFriend',
      href: '/profile/invite-friends',
    },

    myAvatars: {
      text: t('breadcrumb::My Avatars'),
      icon: 'ProfileNFTs',
      href: '/profile/my-avatars',
    },

    settings: {
      text: t('breadcrumb::Settings'),
      icon: 'ProfileSetting',
      href: '/profile/settings',
    },

    wallet: {
      text: t('breadcrumb::Wallet'),
      icon: 'MainWallet',
      href: '/profile/wallet',
    },

    walletAhaPoints: {
      text: t('breadcrumb::Aha Points'),
      icon: 'OtherAhaPoints',
      href: '/profile/wallet/aha-points',
    },
    extendTrial: {
      text: t(
        'profile::invite.friends.paywall.title',
        'Invite a Friend, Extend Your Free Trial and Get Rewards'
      ),
      icon: 'OtherAddFriend',
      href: '/extend-trial',
    },

    practiceResult: {
      text: t('breadcrumb::Results'),
      icon: 'TestScoreTally',
      href: `/club/${query.slug}/practice/${query.quizId}/${query.roundNo}/result`,
    },
    practiceResultAwards: {
      text: t('breadcrumb::Awards'),
      icon: 'ProfileAwards',
      href: `/club/${query.slug}/practice/${query.quizId}/${query.roundNo}/result/awards`,
    },
    practiceResultAwardsByCategory: {
      icon: 'ProfileAwards',
      customizeComponent: (
        <AwardCategoryBreadcrumb
          categoryPath={(query.categoryPath || '') as string}
        />
      ),
    },
    examResult: {
      text: t('breadcrumb::Results'),
      icon: 'TestScoreTally',
      href: `/club/${query.slug}/exam/${query.quizId}/${query.roundNo}/result`,
    },
    examResultAwards: {
      text: t('breadcrumb::Awards'),
      icon: 'ProfileAwards',
      href: `/club/${query.slug}/exam/${query.quizId}/${query.roundNo}/result/awards`,
    },
    examResultAwardsByCategory: {
      icon: 'ProfileAwards',
      customizeComponent: (
        <AwardCategoryBreadcrumb
          categoryPath={(query.categoryPath || '') as string}
        />
      ),
    },

    // Playlists
    playlistsHistory: {
      text: playlistsConfig.history.title,
      icon: playlistsConfig.history.icon,
      href: `/club/${query.slug}/playlists/history`,
    },
    playlistsHistoryMistakes: {
      text: playlistsConfig.historyMistakes.title,
      icon: playlistsConfig.historyMistakes.icon,
      href: `/club/${query.slug}/playlists/mistakes/${query.markId}`,
    },
    playlistsHistoryOvertime: {
      text: playlistsConfig.historyOvertime.title,
      icon: playlistsConfig.historyOvertime.icon,
      href: `/club/${query.slug}/playlists/overtime/${query.markId}`,
    },
    playlistsHistoryDetail: {
      text: playlistsConfig.historyDetail.title,
      icon: playlistsConfig.historyDetail.icon,
      customizeHeader: (
        <PlaylistHeader quizId={(query.quizId || '') as string} />
      ),
      customizeComponent: (
        <PlaylistBreadcrumb
          icon={<Icon name={playlistsConfig.historyDetail.icon} />}
          quizId={(query.quizId || '') as string}
        />
      ),
      // XXX: use a correct way to build query string,
      // (currently we don't need this href because the opened page doesn't need to be clicked)
      // href: `/club/${query.slug}/playlist/${query.quizId}?variant=${query.variant}`
    },
    playlistsChallenges: {
      text: playlistsConfig.challenges.title,
      icon: playlistsConfig.challenges.icon,
      href: `/club/${query.slug}/challenges`,
    },
    playlistsChallengesDetail: {
      text: playlistsConfig.challenges.title,
      icon: playlistsConfig.challenges.icon,
      customizeHeader: (
        <PlaylistHeader quizId={(query.quizId || '') as string} />
      ),
      customizeComponent: (
        <ChallengeBreadcrumb quizId={(query.quizId || '') as string} />
      ),
      // XXX: use a correct way to build query string,
      // (currently we don't need this href because the opened page doesn't need to be clicked)
      // href: `/club/${query.slug}/challenge/${query.quizId}?status=${query.status}`
    },
    playlistsSavedPlaylists: {
      text: playlistsConfig.savedPlaylists.title,
      icon: playlistsConfig.savedPlaylists.icon,
      href: `/club/${query.slug}/playlists/saved`,
    },
    playlistsReaction: {
      text: playlistsConfig.reaction.title,
      icon: playlistsConfig.reaction.icon,
      href: `/club/${query.slug}/playlists/emoji`,
    },
    playlistsReactionDetail: {
      text: (query.title as string) || playlistsConfig.reaction.title, // if query has title, only use icon to display this emoji
      href: `/club/${query.slug}/playlists/emoji/${query.markId}`,
    },
    playlistsFollowing: {
      text: playlistsConfig.following.title,
      icon: playlistsConfig.following.icon,
      href: `/club/${query.slug}/playlists/friends-playlists`,
    },
    playlistsFollowingDetail: {
      text: (query.title as string) || playlistsConfig.following.title,
      icon: playlistsConfig.historyDetail.icon,
      customizeHeader: (
        <PlaylistBreadcrumb
          icon={
            <Icon
              name={playlistsConfig.historyDetail.icon}
              width={40}
              height={40}
            />
          }
          quizId={(query.quizId || '') as string}
        />
      ),
      customizeComponent: (
        <PlaylistBreadcrumb
          icon={<Icon name={playlistsConfig.historyDetail.icon} />}
          quizId={(query.quizId || '') as string}
        />
      ),
    },
    playlistsHashtags: {
      text: playlistsConfig.hashtags.title,
      icon: playlistsConfig.hashtags.icon,
      href: `/club/${query.slug}/playlists/hashtags`,
    },

    playlistsHashtagsDetail: {
      text: (query.title as string) || playlistsConfig.hashtags.title,
      icon: playlistsConfig.hashtags.icon,
      href: `/club/${query.slug}/playlists/hashtags/${query.markId}`,
    },
    playlistsCreators: {
      text: playlistsConfig.creators.title,
      icon: playlistsConfig.creators.icon,
      href: `/club/${query.slug}/playlists/creators`,
    },
    playlistsCreatorsDetail: {
      customizeHeader: (
        <CreatorPlaylistBreadcrumb
          quizId={(query.quizId || '') as string}
          iconSize={40}
        />
      ),
      customizeComponent: (
        <CreatorPlaylistBreadcrumb quizId={(query.quizId || '') as string} />
      ),
    },
    playlistsMyQuestions: {
      text: playlistsConfig.myQuestions.title,
      icon: playlistsConfig.myQuestions.icon,
      href: `/club/${query.slug}/create-questions`,
    },
    playlistsReviewQuestions: {
      text: playlistsConfig.reviewQuestions.title,
      icon: playlistsConfig.reviewQuestions.icon,
      href: `/club/${query.slug}/review-questions`,
    },
    playlistsReviewQuestionsAll: {
      text: playlistsConfig.reviewQuestionsAll.title,
      icon: playlistsConfig.reviewQuestionsAll.icon,
      href: `/club/${query.slug}/review-questions/all`,
    },
    playlistsReviewQuestionsDetail: {
      customizeHeader: (
        <ReviewQuestionDetailBreadcrumb
          iconSize={40}
          userId={query.userId as string}
        />
      ),
      customizeComponent: (
        <ReviewQuestionDetailBreadcrumb userId={query.userId as string} />
      ),
    },

    // Analytics
    analyticsSummary: {
      text: analyticsConfig.summary.title,
      icon: analyticsConfig.summary.icon,
      tooltip: analyticsConfig.summary.tooltip,
      href: `/club/${query.slug}/summary`,
    },
    analyticsSummaryWallet: {
      text: analyticsConfig.summaryWallet.title,
      icon: analyticsConfig.summaryWallet.icon,
      href: `/club/${query.slug}/summary/points-activity`,
    },
    analyticsSummaryChallenges: {
      text: analyticsConfig.summaryChallenges.title,
      icon: analyticsConfig.summaryChallenges.icon,
      href: `/club/${query.slug}/summary/challenges`,
    },
    analyticsSummaryPlaylist: {
      text: analyticsConfig.summaryPlaylists.title,
      icon: analyticsConfig.summaryPlaylists.icon,
      href: `/club/${query.slug}/summary/playlists`,
    },
    analyticsSummaryCustomDate: {
      href: `/club/${query.slug}/summary?customDate=${query.customDate}`,
      customizeHeader: (
        <AnalyticsSummaryCustomDateBreadcrumb
          date={query.customDate as string}
          iconSize={40}
        />
      ),
      customizeComponent: (
        <AnalyticsSummaryCustomDateBreadcrumb
          date={query.customDate as string}
        />
      ),
    },
    analyticsDailyGoals: {
      text: analyticsConfig.dailyGoals.title,
      icon: analyticsConfig.dailyGoals.icon,
      tooltip: analyticsConfig.dailyGoals.tooltip,
      href: `/club/${query.slug}/analytics/goals`,
    },
    analyticsMyPath: {
      text: analyticsConfig.comparePath.title,
      icon: analyticsConfig.comparePath.icon,
      href: `/club/${query.slug}/analytics/my-path`,
    },
    analyticsWeaknessMistakes: {
      text: analyticsConfig.weaknessMistakes.title,
      icon: analyticsConfig.weaknessMistakes.icon,
      tooltip: analyticsConfig.weaknessMistakes.tooltip,
      href: `/club/${query.slug}/analytics/weakness/${query.category}`,
    },
    analyticsWeaknessOvertime: {
      text: analyticsConfig.weaknessOvertime.title,
      icon: analyticsConfig.weaknessOvertime.icon,
      tooltip: analyticsConfig.weaknessOvertime.tooltip,
      href: `/club/${query.slug}/analytics/weakness/${query.category}`,
    },
    analyticsWeaknessNotPracticed: {
      text: analyticsConfig.weaknessNotPracticed.title,
      icon: analyticsConfig.weaknessNotPracticed.icon,
      tooltip: analyticsConfig.weaknessNotPracticed.tooltip,
      href: `/club/${query.slug}/analytics/weakness/${query.category}`,
    },
    analyticsOverallMistakes: {
      text: analyticsConfig.overallMistakes.title,
      icon: analyticsConfig.overallMistakes.icon,
      tooltip: analyticsConfig.overallMistakes.tooltip,
      href: `/club/${query.slug}/analytics/mistakes/${query.category}`,
    },
    analyticsOverallOvertime: {
      text: analyticsConfig.overallOvertime.title,
      icon: analyticsConfig.overallOvertime.icon,
      tooltip: analyticsConfig.overallOvertime.tooltip,
      href: `/club/${query.slug}/analytics/mistakes/${query.category}`,
    },
  };
};
