import { ReactNode, useMemo } from 'react';

import RenderLineMarker from './RenderLineMarker';

export default function useRenderPassageWrap(
  content: string,
  creatorQuestionInfo?: CreatorQuestionInfo
) {
  return useMemo(() => {
    if (!content) return null;

    const wrapFuncArr: ((children: ReactNode) => ReactNode)[] = [];

    if (creatorQuestionInfo?.isShowPassageLineNum) {
      wrapFuncArr.push((children: ReactNode) => (
        <RenderLineMarker
          passageLineNumType={creatorQuestionInfo?.passageLineNumType}
          isShowPassageLineNum={creatorQuestionInfo?.isShowPassageLineNum}
        >
          {children}
        </RenderLineMarker>
      ));
    }

    return (children: ReactNode) =>
      wrapFuncArr.reduce((acc, cur) => {
        return cur(acc);
      }, children);
  }, [content, creatorQuestionInfo]);
}
