import { Box, Typography } from '@mui/material';
import { Icon, SquareAvatar } from '@front/ui';

import {
  TooltipListItemType,
  TooltipListNameType,
  TooltipListValueType,
} from './types';

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 1fr) auto',
    gap: 1,
  },
  propertyWithoutName: {
    gridTemplateColumns: 'auto',
  },
  name: {
    display: 'flex',
    gap: 0.5,
    whiteSpace: 'nowrap',
  },
  value: {
    display: 'grid',
    gridTemplateColumns: 'minmax(max-content, 0) auto',
    gap: 0.5,
    alignItems: 'center',
  },
  valueContent: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, max-content) 16px',
    gap: 0.5,
    alignItems: 'center',
  },
  valueContentWithoutSuffix: {
    gridTemplateColumns: 'auto',
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  avatarText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

type PropertyNameProps = TooltipListNameType;

function PropertyName({ icon, text, sxProps }: PropertyNameProps) {
  const sx = Array.isArray(sxProps) ? sxProps : [sxProps];
  return (
    <Box sx={[styles.name, ...sx]}>
      {icon && <Icon name={icon} width={16} height={16} />}
      <Typography variant="caption">{text}</Typography>
    </Box>
  );
}

type PropertyValueProps = TooltipListValueType;

function PropertyValue(props: PropertyValueProps) {
  const sxProps = Array.isArray(props.sxProps)
    ? props.sxProps
    : [props.sxProps];

  if (props.type === 'icon') {
    return (
      <Box sx={[...sxProps]}>
        <Icon name={props.icon} width={16} height={16} />
      </Box>
    );
  }

  if (props.type === 'iconText') {
    return (
      <Box sx={[styles.value, ...sxProps]}>
        <Icon name={props.icon} width={16} height={16} />
        <Box
          sx={[
            styles.valueContent,
            !props.suffixIcon && styles.valueContentWithoutSuffix,
          ]}
        >
          <Typography variant="caption" sx={styles.text}>
            {props.text}
          </Typography>
          {props.suffixIcon && (
            <Icon name={props.suffixIcon} width={16} height={16} />
          )}
        </Box>
      </Box>
    );
  }
  if (props.type === 'avatarText') {
    return (
      <Box sx={[styles.value, ...sxProps]}>
        <SquareAvatar src={props.avatarUrl} size={16}>
          {props.avatarText}
        </SquareAvatar>
        <Box
          sx={[
            styles.valueContent,
            !props.suffixIcon && styles.valueContentWithoutSuffix,
          ]}
        >
          <Typography variant="caption" sx={styles.avatarText}>
            {props.avatarText}
          </Typography>
          {props.suffixIcon && (
            <Icon name={props.suffixIcon} width={16} height={16} />
          )}
        </Box>
      </Box>
    );
  }
  return (
    <Typography variant="caption" sx={[styles.name, ...sxProps]}>
      {props.text}
    </Typography>
  );
}

type TooltipListItemProps = TooltipListItemType;

export default function TooltipListItem({ name, value }: TooltipListItemProps) {
  return (
    <Box sx={[styles.root, !name && styles.propertyWithoutName]}>
      {name && <PropertyName {...name} />}
      {value && <PropertyValue {...value} />}
    </Box>
  );
}
