import Link from 'next/link';
import { Box } from '@mui/material';
import { Button, Icon } from '@front/ui';
import EmojiText from '@lib/ia/src/components/EmojiMessage';

import { useIaAction } from '../../core/IaAction/useIaAction';

import IaAlbumGroup from './components/IaAlbumGroup';
import { AlbumLayoutConfig } from './types';

const styles = {
  root: {
    display: 'grid',
    gap: 3,
  },
  empty: {
    display: 'grid',
    gap: 3,
  },
};

type EmptyStateProps = {
  settings: AlbumLayoutConfig['settings'];
};

function EmptyState({ settings }: EmptyStateProps) {
  const { getIaAction } = useIaAction();

  const action = settings.emptyAction;
  const linkProps =
    action?.type === 'link' ? { LinkComponent: Link, href: action.value } : {};

  const handleClick = () => {
    if (action?.type !== 'event') return;
    const clickAction = getIaAction(action.value);
    clickAction?.action();
  };

  if (!settings?.emptyText) return null;

  return (
    <Box sx={styles.empty}>
      <EmojiText text={settings.emptyText} icon={settings.emptyIcon} />
      {action && (
        <Box>
          <Button
            prefixIcon={action.icon && <Icon name={action.icon} />}
            {...linkProps}
            onClick={handleClick}
          >
            {action.text}
          </Button>
        </Box>
      )}
    </Box>
  );
}

type AlbumLayoutProps = AlbumLayoutConfig;

export default function AlbumLayout({
  items = [],
  groups = [],
  settings,
}: AlbumLayoutProps) {
  // filter out 'false' items
  items = items.filter((item) => !!item);
  return (
    <Box sx={styles.root}>
      <IaAlbumGroup
        items={items}
        gridTemplateColumns={settings.gridTemplateColumns}
      />
      {groups?.map((group, index) => (
        <IaAlbumGroup
          key={index}
          gridTemplateColumns={settings.gridTemplateColumns}
          {...group}
        />
      ))}
      {items.length === 0 && groups.length === 0 && (
        <EmptyState settings={settings} />
      )}
    </Box>
  );
}
