import axios from 'axios';

const funcApi = {
  updateFuncHighlights: (params: UpdateFuncHighlight) => {
    return axios.patch('/api/func/highlight', params);
  },
  threadRecompletion: (params: ThreadRecompletion) => {
    return axios.post(
      `${process.env.API_URL}/func/thread/recompletion`,
      params
    );
  },
};

export default funcApi;
