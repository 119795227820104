import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { TypographyProps } from '@mui/material/Typography';
import { SystemStyleObject } from '@mui/system';
import { getComposerRenderOptions } from '@lib/web/composer/utils';
import { sanitize } from 'dompurify';
import parse, { HTMLReactParserOptions } from 'html-react-parser';

import { basicBlockHtmlStyles } from './config/basicComposerBlockStyles';

const styles = {
  styleProvider: {
    width: '100%',
    height: '100%',
  },
  root: {
    '&.render-one-line, & .render-one-line': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      alignItems: 'center',

      '& *:not(.markdown-message)': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '[data-content-type="inline-latex"], [data-content-type="inline-latex"] *':
          {
            overflow: 'unset',
          },
      },

      '& .html-renderer .markdown-message': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',

        '& *': {
          display: 'inline',
          verticalAlign: 'middle',
        },

        '& pre': {
          display: 'none',
        },
      },

      '& br': {
        // make html element render on one line
        content: '" "',
        width: '4px',
        display: 'inline-block',
      },
    },
  },
};

export type ComposerRendererProps = Omit<TypographyProps, 'children'> & {
  html?: string;
  oneLine?: boolean;
  styleProvider?: boolean;
  htmlStyles?: (
    | SystemStyleObject<Theme>
    | ((theme: Theme) => SystemStyleObject<Theme>)
  )[];
  children?:
    | ((ev: { renderOptions: HTMLReactParserOptions }) => ReactNode)
    | ReactNode;
  mentionRender?: (metadata: { userId: string; memberId: string }) => ReactNode;
  audioRender?: (metadata: { filename: string; fileKey: string }) => ReactNode;
};

/**
 * There are 2 ways to use this component
 *
 * 1. render a html string
 * <ComposerRenderer html={renderHtml} />
 *
 * 2. style provider (useful when the html itself is in very deep nesting, such as ia layout)
 * <ComposerRenderer styleProvider>
 *   ...
 *    ...
 *      this will render as one line
 *      <div dangerouslySetInnerHTML={{ __html: renderHtml }} className='render-one-line' />
 *      this will render normal
 *      <div dangerouslySetInnerHTML={{ __html: renderHtml }} />
 * </ComposerRenderer>
 */
export default function ComposerRenderer({
  html,
  oneLine = false,
  styleProvider = false,
  htmlStyles = basicBlockHtmlStyles,
  mentionRender,
  audioRender,
  sx,
  className = '',
  children,
  ...rest
}: ComposerRendererProps) {
  const renderOptions = getComposerRenderOptions({
    mentionRender,
    audioRender,
  });

  if (styleProvider) {
    return (
      <Box
        className={className}
        sx={[styles.styleProvider, styles.root, ...htmlStyles]}
        {...rest}
      >
        {typeof children === 'function'
          ? children({ renderOptions })
          : children}
      </Box>
    );
  }

  const sxProps = Array.isArray(sx) ? sx : [sx];

  if (html) {
    return (
      <Box
        className={`${oneLine ? 'render-one-line ' : ''}${className}`}
        sx={[styles.root, ...sxProps, ...htmlStyles]}
        {...rest}
      >
        {parse(sanitize(html), renderOptions)}
      </Box>
    );
  }

  return null;
}
