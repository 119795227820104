import { ExamQuestionMaterialType } from '@lib/web/apis';
import {
  getAnswerOptionIdToContentHtmlMap,
  getAnswerOptionIdToSolutionHtmlMap,
  getGeneralSolutionContentHtml,
  getLeftQuestionComponents,
  getLeftQuestionContentHtml,
  getRightQuestionContentHtml,
} from '@lib/web/editor';
import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';

import { Question, QuizState, State } from './types';

const selectMappedQuestions = (state: State) => state.mappedQuestions;
const selectMappedAnswers = (state: State) => state.mappedAnswers;
const selectMappedNotes = (state: State) => state.mappedNotes;
const selectMappedMaterials = (state: State) => state.mappedMaterials;
const selectHighlight = (state: State) => state.highlight;
const selectEliminatedAnswers = (state: State) => state.eliminatedAnswers;
const selectQuestionNo = (state: State) => state.questionNo;

const selectQuizType = (state: State) => state.quiz.type;

const selectQuizStatus = (state: State) => ({
  isPaused: state.state === QuizState.Paused,
  isFinished: state.state === QuizState.Finish,
  isQuestion: state.state === QuizState.Question,
  questionCount: state.totalCount,
  questionIndex: state.questionNo - 1,
});

const selectIsTesting = (state: State) =>
  state.state === QuizState.Playing ||
  state.state === QuizState.Paused ||
  state.state === QuizState.Question;

const selectCreatorQuestionGroups = (state: State) =>
  state.creatorQuestionGroups;

const selectQuestions = createSelector(selectMappedQuestions, (questions) =>
  Object.values(questions)
);

const selectAtLeastOneSubmitted = createSelector(
  selectMappedQuestions,
  (questions) =>
    Object.values(questions).some((question) => question.isSubmitted)
);

const selectSummary = createSelector(
  selectMappedQuestions,
  selectMappedAnswers,
  (questions, mappedAnswers) => {
    const allQuestions = Object.values(questions);
    let correct = 0;
    let incorrect = 0;
    let skipped = 0;
    let dirtied = 0;
    let submitted = 0;
    let overtime = 0;
    let leaveBlank = 0;
    let unanswered = 0;
    allQuestions.forEach((question) => {
      if (question.isCorrect) correct += 1;
      if (
        question.isIncorrect &&
        !question.isLeaveBlank &&
        mappedAnswers[question.questionNo]?.[0]
      )
        incorrect += 1;
      if (question.isLeaveBlank) leaveBlank += 1;
      if (
        (question.timeSpent > 0 &&
          !question.isCorrect &&
          !question.isIncorrect) ||
        question.isSkipped
      )
        skipped += 1;

      if (mappedAnswers[question.questionNo].length || question.timeSpent > 0)
        dirtied += 1;
      if (question.isSubmitted) submitted += 1;

      if (question.timeSpent && question.timeSpent / 1000 > question.tpq) {
        overtime += 1;
      }

      if (!mappedAnswers[question.questionNo]?.[0]) {
        unanswered += 1;
      }
    });
    return {
      correct,
      incorrect,
      skipped,
      dirtied,
      submitted,
      overtime,
      leaveBlank,
      unanswered,
      total: allQuestions.length,
    };
  }
);

const selectCurrentStreak = createSelector(
  selectMappedQuestions,
  selectQuestionNo,
  (questions, order) => {
    if (!questions[order]) return null;

    const allQuestions = Object.values(questions);
    let currentIndex = allQuestions.findIndex(
      (q) => q.id === questions[order].id
    );
    let streakCount = 0;
    while (allQuestions[currentIndex]?.isStreak) {
      streakCount += 1;
      currentIndex -= 1;
    }

    return streakCount;
  }
);

const selectMaxStreak = createSelector(selectMappedQuestions, (questions) => {
  const allQuestions = Object.values(questions);
  let maxCount = 0;
  let currentCount = 0;
  for (let i = 0; i < allQuestions.length; i += 1) {
    const currentQuestion = allQuestions[i];

    if (currentQuestion.isStreak) {
      currentCount += 1;
    } else {
      currentCount = 0;
    }

    if (currentCount > maxCount) {
      maxCount = currentCount;
    }
  }

  return maxCount;
});

const selectIsAllAnswered = createSelector(selectSummary, (summary) => {
  return summary.dirtied === summary.total;
});

const selectIsAllSubmitted = createSelector(selectSummary, (summary) => {
  return summary.submitted === summary.total;
});

const selectCurrentQuestion = createSelector(
  selectMappedQuestions,
  selectQuestionNo,
  (questions, order) => questions[order]
);

const selectCurrentQuestionStatus = createSelector(
  selectCurrentQuestion,
  (question) => ({
    isPendingSubmit: question.isPendingSubmit,
    isLoading: question.isLoading,
    isSubmitted: question.isSubmitted,
    isSkipped: question.isSkipped,
    startAt: question.startAt,
    timeSpent: question.timeSpent,
  })
);

const selectCurrentQuestionResult = createSelector(
  selectCurrentQuestion,
  (question) => ({
    isCorrect: question.isCorrect,
    isOvertime: question.isOvertime,
    isStreak: question.isStreak,
    correctAnswerValues: question.correctAnswerValues,
    correctAnswerIds: question.correctAnswerIds,
  })
);

const selectCurrentQuestionCreatorQuestionInfo = createSelector(
  selectCurrentQuestion,
  (question) => question?.creatorQuestionInfo
);

const selectCurrentQuestionLeftQuestionContent = createSelector(
  selectCurrentQuestionCreatorQuestionInfo,
  selectCreatorQuestionGroups,
  (creatorQuestionInfo, creatorQuestionGroups): string =>
    getLeftQuestionContentHtml(
      getLeftQuestionComponents(creatorQuestionInfo, creatorQuestionGroups)
    )
);

const selectCurrentQuestionRightQuestionContent = createSelector(
  selectCurrentQuestionCreatorQuestionInfo,
  (creatorQuestionInfo): string =>
    getRightQuestionContentHtml(creatorQuestionInfo?.components)
);

const selectCurrentQuestionAnswerOptionIdToContentMap = createSelector(
  selectCurrentQuestionCreatorQuestionInfo,
  (creatorQuestionInfo): Record<string, string> =>
    getAnswerOptionIdToContentHtmlMap(creatorQuestionInfo?.components)
);

const selectCurrentQuestionGeneralSolution = createSelector(
  selectCurrentQuestionCreatorQuestionInfo,
  (creatorQuestionInfo): string =>
    getGeneralSolutionContentHtml(creatorQuestionInfo?.components)
);

const selectCurrentQuestionAnswerOptionIdToSolutionMap = createSelector(
  selectCurrentQuestionCreatorQuestionInfo,
  (creatorQuestionInfo): Record<string, string> =>
    getAnswerOptionIdToSolutionHtmlMap(creatorQuestionInfo?.components)
);

const selectCurrentQuestionHasUserCreateSolution = createSelector(
  selectCurrentQuestionGeneralSolution,
  selectCurrentQuestionAnswerOptionIdToSolutionMap,
  (solution, answerOptionIdToSolutionMap) =>
    Boolean(solution) ||
    Object.values(answerOptionIdToSolutionMap).some(Boolean)
);

const selectHasQuestion = createSelector(
  selectMappedQuestions,
  selectQuestionNo,
  (questions, order) => !!questions[order]
);

const selectCurrentAnswer = createSelector(
  selectMappedAnswers,
  selectQuestionNo,
  (answers, order) => answers[order]
);

const selectCurrentNote = createSelector(
  selectMappedNotes,
  selectQuestionNo,
  (notes, order) => notes[order]
);

const selectCurrentIsSubmitted = createSelector(
  selectMappedQuestions,
  selectQuestionNo,
  (questions, order) => questions[order]?.isSubmitted
);

const selectQuestionByOrder = createSelector(
  selectMappedQuestions,
  (questions) =>
    memoize((order: number) => questions[order] as Question | undefined)
);

const selectActiveByOrder = createSelector(
  selectQuestionNo,
  selectMappedQuestions,
  (no, questions) =>
    memoize((order: number) => questions[order].questionNo === no)
);

const selectAnswerByOrder = createSelector(selectMappedAnswers, (questions) =>
  memoize((order: number) => questions[order])
);

const selectIsAnsweredByOrder = createSelector(
  selectMappedAnswers,
  (questions) => memoize((order: number) => !!questions[order]?.[0])
);

const selectHighlightByCode = createSelector(selectHighlight, (highlight) =>
  memoize((code: string) => highlight[code])
);

const selectEliminatedAnswersByOrder = createSelector(
  selectEliminatedAnswers,
  (eliminatedAnswers) => memoize((order: number) => eliminatedAnswers[order])
);

const selectMaterialByCode = createSelector(
  selectMappedMaterials,
  (materials) => memoize((code: string) => materials[code])
);

const selectMaterialPassageByCodes = createSelector(
  selectMappedMaterials,
  (materials) =>
    memoize((codes: string[]) => {
      const result: Record<string, GetQuizMaterialRes[]> = {
        instruction: [],
        header: [],
        passage: [],
      };

      codes.forEach((code: string) => {
        const currentMaterial = materials[code];
        if (currentMaterial) {
          switch (currentMaterial.type) {
            case ExamQuestionMaterialType.Instruction: {
              result.instruction.push(currentMaterial);
              break;
            }
            case ExamQuestionMaterialType.Header: {
              result.header.push(currentMaterial);
              break;
            }
            case ExamQuestionMaterialType.Passage: {
              result.passage.push(currentMaterial);
              break;
            }
            default:
              break;
          }
        }
      });

      return result;
    })
);

const selectCreatorQuestionAnswerFormatTypeByOrder = createSelector(
  selectMappedQuestions,
  (questions) =>
    memoize(
      (order: number) => questions[order].creatorQuestionInfo?.answerFormatType
    )
);

export {
  selectActiveByOrder,
  selectAnswerByOrder,
  selectAtLeastOneSubmitted,
  selectCreatorQuestionAnswerFormatTypeByOrder,
  selectCurrentAnswer,
  selectCurrentIsSubmitted,
  selectCurrentNote,
  selectCurrentQuestion,
  selectCurrentQuestionAnswerOptionIdToContentMap,
  selectCurrentQuestionAnswerOptionIdToSolutionMap,
  selectCurrentQuestionCreatorQuestionInfo,
  selectCurrentQuestionGeneralSolution,
  selectCurrentQuestionHasUserCreateSolution,
  selectCurrentQuestionLeftQuestionContent,
  selectCurrentQuestionResult,
  selectCurrentQuestionRightQuestionContent,
  selectCurrentQuestionStatus,
  selectCurrentStreak,
  selectEliminatedAnswersByOrder,
  selectHasQuestion,
  selectHighlightByCode,
  selectIsAllAnswered,
  selectIsAllSubmitted,
  selectIsAnsweredByOrder,
  selectIsTesting,
  selectMappedQuestions,
  selectMaterialByCode,
  selectMaterialPassageByCodes,
  selectMaxStreak,
  selectQuestionByOrder,
  selectQuestionNo,
  selectQuestions,
  selectQuizStatus,
  selectQuizType,
  selectSummary,
};
