import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { SortableActivator } from '@front/helper';
import {
  OtherDelete as OtherDeleteIcon,
  OtherDrag as OtherDragIcon,
} from '@front/icon';
import { IconButton, Scrollbar } from '@front/ui';
import { ComposerBlock } from '@lib/web/composer';
import EditorComposer from '@lib/web/editor/EditorComposer';

import { UnscrambleValue } from '../types';

const styles = {
  root: (theme: Theme) => ({
    background: alpha(theme.palette.text.primary, 0.05),
    borderRadius: '12px',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    justifyContent: 'flex-start',
    boxShadow: `inset 0 0 0 1px ${alpha(theme.palette.text.primary, 0.1)}`,
  }),
  innerContent: {
    display: 'grid',
    gridTemplateColumns: '52px minmax(0, 1fr) min-content',
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    userSelect: 'none',
  },
  scroll: {
    maxHeight: 236, // as spec define, max 8 rows
    maxWidth: '100%',
  },
  symbolContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  symbol: (theme: Theme) => ({
    mt: '12px',
    ml: 1.5,
    height: 32,
    width: 32,
    border: `1px solid ${theme.palette.text.primary}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
  }),
  hover: (theme: Theme) => ({
    '@media (hover: hover)': {
      '&:hover': {
        boxShadow: `inset 0 0 0 1px ${alpha(
          theme.palette.text.primary,
          0.3
        )}, 0px 8px 16px ${alpha(theme.palette.text.primary, 0.15)}`,
        backgroundColor: alpha(theme.palette.text.primary, 0.1),
      },
    },
  }),

  textComposer: {
    minHeight: 56,
    textAlign: 'left',
    typography: 'body1',

    paddingTop: '12px',
    paddingBottom: '12px',

    whiteSpace: 'nowrap', // unlike other text-composer, in Unscramble, we don't want it break
    cursor: 'text',
  },

  iconButtons: {
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    pr: 1.5,
  },
  dragIcon: {
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
  },
};

export type ChoiceProps = {
  value: UnscrambleValue;
  onBlocksChange: (id: string, blocks: ComposerBlock[]) => void;
  symbol: number;
  disableSort?: boolean;
  hideRemove?: boolean;
  onRemoveChoice?: (id: string) => void;
};
export default function Choice({
  value,
  onBlocksChange,
  symbol,
  disableSort,
  hideRemove,
  onRemoveChoice,
}: ChoiceProps) {
  const { t } = useTranslation('editor');

  return (
    <ButtonBase disableRipple sx={[styles.root, styles.hover]} component="div">
      <Box sx={styles.innerContent}>
        <Box sx={styles.symbolContainer}>
          <Box sx={styles.symbol}>{symbol}</Box>
        </Box>
        <Scrollbar sx={styles.scroll}>
          <EditorComposer
            key={value.id}
            sx={styles.textComposer}
            defaultBlocks={value.blocks}
            onBlocksChange={(blocks) => onBlocksChange(value.id, blocks)}
            placeholder={t('Write an answer option here')}
          />
        </Scrollbar>
        <Box sx={styles.iconButtons}>
          {!hideRemove && (
            <IconButton
              customSize={24}
              onClick={() => onRemoveChoice?.(value.id)}
            >
              <OtherDeleteIcon />
            </IconButton>
          )}
          {disableSort ? ( // if we don't skip ghost element, it will break the drag behavior after dragged one time
            <Box sx={styles.dragIcon}>
              <OtherDragIcon width={16} height={16} />
            </Box>
          ) : (
            <SortableActivator id={value.id}>
              {(listeners, setActivatorNodeRef) => (
                <Box
                  ref={setActivatorNodeRef}
                  {...listeners}
                  sx={styles.dragIcon}
                >
                  <OtherDragIcon width={16} height={16} />
                </Box>
              )}
            </SortableActivator>
          )}
        </Box>
      </Box>
    </ButtonBase>
  );
}
