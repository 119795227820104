import generateHook, {
  generateInfiniteHook,
  SwrHelperParams,
} from './swr.helper';

const getMemberInfoKey = (
  userId: NullableString = '',
  memberId: NullableString = ''
) => {
  if (userId) return `v2/member/info?userId=${userId}&isGetNft=true`;
  if (memberId) return `v2/member/info?memberId=${memberId}&isGetNft=true`;
  return null;
};

export const useMemberInfo = (
  userId: NullableString = '',
  memberId: NullableString = ''
) => {
  return generateHook<GetMemberInfoRes>(getMemberInfoKey(userId, memberId), {
    auth: true,
  });
};

export const useSharedMemberInfo = (sid: string) => {
  return generateHook<GetMemberInfoRes>(
    sid ? `v2/shared/member/info?sid=${sid}&isGetNft=true` : null
  );
};

export const useMemberInfos = (params: GetMultipleMemberInfoReq) => {
  const enable = !!params.memberIds?.length || !!params.userIds?.length;

  const key = JSON.stringify(params);

  return generateHook<GetMemberInfoRes[]>(
    enable ? `/v2/member/info?key=${key}` : null,
    {
      method: 'POST',
      data: params,
    }
  );
};

const getMemberAchievementKey = ({
  userId,
  examId,
  sectionId,
}: {
  userId: NullableString;
  examId?: NullableString;
  sectionId?: NullableString;
}) => {
  const params = new URLSearchParams();

  if (examId) params.set('examId', examId);
  if (sectionId) params.set('sectionId', sectionId);

  return `v2/member/${userId}/achievement?${params.toString()}`;
};
export const useMemberAchievement = (
  {
    userId,
    examId,
    sectionId,
  }: {
    userId?: NullableString;
    examId?: NullableString;
    sectionId?: NullableString;
  },
  { immutable = true }: SwrHelperParams = {}
) =>
  generateHook<GetMemberAchievementRes>(
    userId && (examId || sectionId)
      ? getMemberAchievementKey({ userId, examId, sectionId })
      : null,
    {
      auth: true,
      immutable,
    }
  );

export const useNonFriendUsers = (
  keyword: NullableString,
  page = 1,
  pageSize = 10
) =>
  generateHook<GetFriendUserRes>(
    keyword
      ? `v2/member/non-friend/me?keyword=${encodeURIComponent(
          keyword
        )}&page=${page}&limit=${pageSize}`
      : null,
    {
      auth: true,
      immutable: true,
      paginate: true,
    }
  );

export const useNonFriendUsersList = (keyword: NullableString, pageSize = 10) =>
  generateInfiniteHook<GetFriendUserRes>(
    (index) =>
      keyword
        ? `v2/member/non-friend/me?keyword=${encodeURIComponent(
            keyword
          )}&page=${index + 1}&limit=${pageSize}`
        : null,
    pageSize,
    {
      auth: true,
      immutable: true,
    }
  );

const getSearchFriendKey = (keyword = '', page = 1, pageSize = 10) =>
  `v2/member/friend/me?keyword=${encodeURIComponent(
    keyword
  )}&page=${page}&limit=${pageSize}`;

export const useSearchFriendList = (keyword: NullableString, pageSize = 10) =>
  generateInfiniteHook<GetFriendUserRes>(
    (index) =>
      keyword ? getSearchFriendKey(keyword, index + 1, pageSize) : null,
    pageSize,
    {
      auth: true,
      immutable: true,
    }
  );

const getSearchMemberKey = (keyword = '', page = 1, pageSize = 10) =>
  `v2/member?keywordFuzzy=${encodeURIComponent(
    keyword
  )}&page=${page}&limit=${pageSize}`;

export const useSearchMemberList = (keyword: NullableString, pageSize = 10) =>
  generateInfiniteHook<GetFriendUserRes>(
    (index) =>
      keyword ? getSearchMemberKey(keyword, index + 1, pageSize) : null,
    pageSize,
    {
      auth: true,
    }
  );

export const useMyProfile = () =>
  generateHook<Member>('/v2/member/profile/me', { auth: true });

const getUserFollowKey = ({
  type,
  userId,
  keyword,
  isGetStatus,
  isGetLeagueInfo,
  clubSlug,
  pageSize,
  page,
}: {
  type?: 'follower' | 'following';
  userId?: string;
  keyword?: string;
  isGetStatus?: boolean;
  isGetLeagueInfo?: boolean;
  clubSlug?: string;
  pageSize?: number;
  page?: number;
}) => {
  const params = new URLSearchParams();

  if (isGetStatus) params.set('isGetStatus', 'true');
  if (keyword) params.set('keyword', encodeURIComponent(keyword));
  if (userId) params.set('userId', userId);
  if (isGetLeagueInfo) params.set('isGetLeagueInfo', 'true');
  if (clubSlug) params.set('clubSlug', clubSlug);
  params.set('page', `${page}`);
  params.set('limit', `${pageSize}`);

  return `/v2/member/follow/${userId}/${type}?${params.toString()}`;
};

export const useUserFollow = ({
  type = 'follower',
  userId = '',
  keyword = '',
  isGetStatus,
  isGetLeagueInfo,
  clubSlug,
  pageSize = 10,
}: {
  type?: 'follower' | 'following';
  userId?: string;
  keyword?: string;
  isGetStatus?: boolean;
  isGetLeagueInfo?: boolean;
  clubSlug?: string;
  pageSize?: number;
}) =>
  generateInfiniteHook<GetFollowerRes>(
    (index) => {
      return userId
        ? getUserFollowKey({
            type,
            userId,
            keyword,
            isGetStatus,
            isGetLeagueInfo,
            clubSlug,
            pageSize,
            page: index + 1,
          })
        : null;
    },
    pageSize,
    {
      auth: true,
    }
  );

export const useUserFollowing = (userId = '', keyword = '', pageSize = 10) =>
  generateInfiniteHook<GetFollowerRes>(
    (index) =>
      userId
        ? `/v2/member/follow/${userId}/following?keyword=${encodeURIComponent(
            keyword
          )}&page=${index + 1}&limit=${pageSize}`
        : null,
    pageSize,
    {
      auth: true,
    }
  );

export const useProfileSocial = (userId?: NullableString) => {
  const params = new URLSearchParams();

  // userId === undefined will be obtained from token
  if (userId) params.set('userId', userId);
  return generateHook<GerProfileSocialRes>(
    userId === undefined
      ? null
      : `v2/member/profile/social?${params.toString()}`,
    {
      auth: true,
    }
  );
};

export const getUserProfileSocialKey = (userId?: string) => {
  return `v2/member/profile/social?userId=${userId}`;
};

export const useUserProfileSocial = (
  userId?: string,
  { disabled }: { disabled?: boolean } = {}
) =>
  generateHook<GerProfileSocialRes>(
    userId && !disabled ? getUserProfileSocialKey(userId) : undefined,
    {
      auth: true,
    }
  );

export const useMyMembership = () =>
  generateHook<GetMembershipRes>('/v2/member/membership/me', {
    auth: true,
  });
export const useMemberLocation = (keyword: string) =>
  generateHook<{ name: string; country: string }>(
    `v2/member/location?keyword=${keyword}&page=1&limit=10`,
    {
      auth: true,
      paginate: true,
      immutable: true,
    }
  );
